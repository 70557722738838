import { EVENT } from '@/shared/constants/analytics'
import {
  UpdateTaskInput,
  type UpdateTaskInputType,
} from '@/shared/constants/task'
import { Form, FormField, FormMessage } from '@/web/components/ui/form'
import { Textarea } from '@/web/components/ui/textarea'
import { DemoContext } from '@/web/contexts/demo'
import { TaskContext } from '@/web/contexts/task'
import { cn } from '@/web/libs/utils'
import { track } from '@/web/utils/analytics'
import { api } from '@/web/utils/api'
import { zodResolver } from '@hookform/resolvers/zod'
import { useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'
import { useDebouncedCallback } from 'use-debounce'

export const TaskDescriptionForm = () => {
  const task = useContext(TaskContext)
  const isDemo = useContext(DemoContext)

  const form = useForm<UpdateTaskInputType>({
    defaultValues: {
      description: task?.description ?? '',
    },
    resolver: zodResolver(UpdateTaskInput),
  })
  const updateTaskMutation = api.task.update.useMutation({
    onMutate(variables) {
      const updatedTask = {
        ...task,
        ...variables,
      }
      track(EVENT.UPDATE_TASK, variables)

      return {
        optimisticData: updatedTask,
        rollbackData: task,
      }
    },
    onError() {
      toast.error(`Failed to update description of task “${task?.description}”`)
    },
  })

  const description = form.watch('description')

  const debouncedUpdateTask = useDebouncedCallback(
    (variables: UpdateTaskInputType) => {
      if (isDemo) {
        return
      }

      track(EVENT.UPDATE_TASK, variables)
      updateTaskMutation.mutate(variables)
    },
    500
  )
  useEffect(() => {
    if (
      !task ||
      description?.trim() === task.description?.trim() ||
      updateTaskMutation.isLoading
    ) {
      return
    }

    console.log('Description updated to', description)
    debouncedUpdateTask.cancel()
    debouncedUpdateTask({ id: task.id, description: description?.trim() })
  }, [description, task, updateTaskMutation])

  return (
    <Form {...form}>
      <form>
        <FormMessage />
        <FormField
          control={form.control}
          name='description'
          render={({ field }) => (
            <Textarea
              {...field}
              value={field.value ?? undefined}
              size='none'
              rows={1}
              placeholder='Write a description…'
              variant='ghost'
              autoComplete='off'
              autoCapitalize='on'
              autoCorrect='on'
              spellCheck='true'
              inputMode='text'
              className={cn(
                'text-muted-foreground text-sm',
                !field.value?.trim() && 'opacity-50'
              )}
              onKeyUpCapture={e => {
                e.preventDefault()
              }}
              onClick={e => e.stopPropagation()}
            />
          )}
        />
      </form>
    </Form>
  )
}
