import { cn } from '@/web/libs/utils'
import { Score } from '@prisma/client'
import _ from 'lodash'

const PRIORITY_COLOR_MAP: Record<Score, string> = {
  [Score.LOW]: 'green-500',
  [Score.MEDIUM]: 'yellow-500',
  [Score.HIGH]: 'red-500',
}
const PRIORITIES = Object.keys(PRIORITY_COLOR_MAP) as Score[]

export const ProgressButton = ({
  selected,
  onClick,
}: {
  selected?: Score | null
  onClick: (priority: Score) => void
}) => {
  return (
    <div className='group flex h-2 items-center space-x-1 rounded-full'>
      {PRIORITIES.map(priority => (
        <div
          key={priority}
          className={cn(
            // bg-green-500 ring-green-500 hover:bg-green-500 group-hover:bg-green-500
            // bg-yellow-500 ring-yellow-500 hover:bg-yellow-500 group-hover:bg-yellow-500
            // bg-red-500 ring-red-500 hover:bg-red-500 group-hover:bg-red-500
            `h-full w-full flex-1 bg-gray-200 ring-offset-1 hover:ring-1 ring-${PRIORITY_COLOR_MAP[priority]} [data-state=hover]:bg-gray-200 hover:bg-${PRIORITY_COLOR_MAP[priority]}`,
            selected &&
              PRIORITIES.indexOf(priority) <= PRIORITIES.indexOf(selected) &&
              `bg-${PRIORITY_COLOR_MAP[selected]}`
          )}
          onMouseEnter={e =>
            e.currentTarget.setAttribute('data-hover', priority)
          }
          onMouseLeave={e => e.currentTarget.removeAttribute('data-hover')}
          role='button'
          title={_.startCase(priority)}
          onClick={() => onClick(priority)}
        />
      ))}
    </div>
  )
}
