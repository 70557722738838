import { DemoContext } from '@/web/contexts/demo'
import {
  api,
  type ReactQueryOptions,
  type RouterInputs,
  type RouterOutputs,
} from '@/web/utils/api'
import { getDenormalizedObject } from '@/web/utils/normy'
import { useQueryNormalizer } from '@normy/react-query'
import { useWhatChanged } from '@simbathesailor/use-what-changed'
import { useContext } from 'react'

export const useUserQueryResult = (
  id: RouterInputs['users']['get'],
  options: ReactQueryOptions['users']['get'] = {}
) => {
  const utils = api.useUtils()
  const { getNormalizedData } = useQueryNormalizer()
  const cachedUser = getDenormalizedObject(getNormalizedData(), id)
  if (!!cachedUser && !utils.users.get.getData(id)) {
    console.log('Setting cached user', cachedUser)
    utils.users.get.setData(id, cachedUser as RouterOutputs['users']['get'])
  }

  const isDemo = useContext(DemoContext)
  const getUserQueryResult = api.users.get.useQuery(id, {
    enabled: !isDemo && id != '-1',
    ...options,
  })

  useWhatChanged(
    [id, getUserQueryResult.isFetching, getUserQueryResult.data],
    'id, getUserQueryResult.isFetching, getUserQueryResult.data'
  )

  return getUserQueryResult as ReturnType<
    (typeof api)['users']['get']['useQuery']
  > & {
    data: RouterOutputs['users']['get']
  }
}
