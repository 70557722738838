import { TaskAssignedButton } from '@/web/components/TaskAssignedButton'
import { TaskDeleteButton } from '@/web/components/TaskDeleteButton'
import { TaskImportanceButton } from '@/web/components/TaskImportanceButton'
import { TaskPriorityButton } from '@/web/components/TaskPriorityButton'
import { TaskTimeButton } from '@/web/components/TaskTimeButton'
import { TaskUrgencyButton } from '@/web/components/TaskUrgencyButton'
import { type Task } from '@prisma/client'

export const TaskHeaderControls = ({ task }: { task: Task }) => {
  return (
    <div className='relative flex w-full flex-row items-center justify-between sm:w-auto sm:justify-normal'>
      <div className='flex sm:invisible sm:group-hover:visible'>
        <TaskDeleteButton />
        <TaskTimeButton
          taskId={task.id}
          time={task.time}
          hours={task.hours}
          minutes={task.minutes}
          timeReason={task.timeReason}
          isAutomated={!task.manualFields.includes('time')}
        />
        <TaskImportanceButton task={task} />
        <TaskUrgencyButton task={task} />
      </div>
      <TaskAssignedButton />
      <TaskPriorityButton task={task} />
    </div>
  )
}
