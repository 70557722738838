import { Button, type ButtonProps } from '@/web/components/ui/button'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/web/components/ui/popover'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/web/components/ui/tooltip'
import React from 'react'

export const PopoverButtonWithTooltip = ({
  getButtonProps,
  popoverContentChildren,
  tooltipContentChildren,
}: {
  getButtonProps: ({ isOpen }: { isOpen: boolean }) => Partial<ButtonProps>
  popoverContentChildren: React.ReactNode
  tooltipContentChildren: React.ReactNode
}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const buttonProps = getButtonProps({ isOpen })
  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <TooltipProvider>
        <Tooltip disableHoverableContent>
          <TooltipTrigger
            asChild
            onClick={e => {
              e.preventDefault()
              setIsOpen(!isOpen)
            }}>
            <PopoverTrigger
              asChild
              onClick={e => {
                e.stopPropagation()
              }}>
              <Button {...buttonProps} />
            </PopoverTrigger>
          </TooltipTrigger>
          <TooltipContent>{tooltipContentChildren}</TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <PopoverContent
        className='flex flex-col items-center gap-4'
        onOpenAutoFocus={e => {
          // @see https://github.com/radix-ui/primitives/issues/2248
          e.preventDefault()
        }}>
        {popoverContentChildren}
      </PopoverContent>
    </Popover>
  )
}
