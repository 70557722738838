import { EVENT } from '@/shared/constants/analytics'
import { getTrackingData, isCancelled, isTodo } from '@/shared/utils/task'
import { currentTaskIdState } from '@/web/state/current-task-id'
import { track } from '@/web/utils/analytics'
import { api, type RouterInputs } from '@/web/utils/api'
import { getDenormalizedObject } from '@/web/utils/normy'
import { useQueryNormalizer } from '@normy/react-query'
import { TaskStatus, type Task, type User } from '@prisma/client'
import { useSetRecoilState } from 'recoil'
import { toast } from 'sonner'

export const useUpdateTaskMutation = ({
  onMutate,
}: {
  // onMutate?: Parameters<typeof api.task.update.useMutation>[0]['onMutate']
  onMutate?: (variables: RouterInputs['task']['update']) => void
} = {}) => {
  const setCurrentTaskId = useSetRecoilState(currentTaskIdState)
  const utils = api.useUtils()
  const { getNormalizedData } = useQueryNormalizer()
  return api.task.update.useMutation({
    onMutate(variables) {
      const task = utils.task.get.getData(variables.id)
      if (!task) {
        return
      }

      setCurrentTaskId(task.id)

      const updatedTask = {
        ...task,
        ...variables,
        lastReadDate: new Date(),
      } satisfies Task

      track(EVENT.UPDATE_TASK, variables)

      // Status
      if (variables.status) {
        track(
          variables.status === TaskStatus.COMPLETED
            ? EVENT.COMPLETE_TASK
            : variables.status === TaskStatus.TODO
            ? EVENT.UNCOMPLETE_TASK
            : EVENT.UPDATE_TASK_STATUS,
          {
            integration: task.integrationName,
            taskId: task.id,
            status: variables.status,
          }
        )
        updatedTask.completedDate =
          variables.status === TaskStatus.COMPLETED ? new Date() : null

        // Add to completed list
        if (variables.status === TaskStatus.COMPLETED) {
          utils.tasks.count.setData({ type: 'completed' }, data =>
            data ? data + 1 : data
          )
          utils.tasks.count.setData(
            {
              type: isCancelled(task)
                ? 'cancelled'
                : isTodo(task)
                ? 'todo'
                : 'completed',
            },
            data => (data ? data - 1 : data)
          )
        } else {
          // Update count if was previously completed
          if (task.status === TaskStatus.COMPLETED) {
            utils.tasks.count.setData({ type: 'completed' }, data =>
              data ? data - 1 : data
            )
            utils.tasks.count.setData(
              {
                type: isCancelled(updatedTask)
                  ? 'cancelled'
                  : isTodo(updatedTask)
                  ? 'todo'
                  : 'completed',
              },
              data => (data ? data + 1 : data)
            )
          }
        }
      }

      // Scheduled
      if (variables.hasOwnProperty('scheduledDate')) {
        if (variables.scheduledDate) {
          track(EVENT.SCHEDULE_TASK, {
            date: variables.scheduledDate,
            ...getTrackingData(task),
          })

          // Update lists if wasn't previously scheduled
          if (!task.scheduledDate) {
            utils.tasks.count.setData({ type: 'scheduled' }, data =>
              data ? data + 1 : data
            )
          }
        } else {
          track(EVENT.UNSCHEDULE_TASK, getTrackingData(task))
          utils.tasks.count.setData({ type: 'scheduled' }, data =>
            data ? data - 1 : data
          )
        }
      }

      // Assigned user
      if (variables.assignedUserId) {
        const assignedUser = getDenormalizedObject(
          getNormalizedData(),
          variables.assignedUserId
        ) as User | null
        updatedTask.assignedUser = assignedUser

        track(EVENT.ASSIGN_TASK, {
          userId: variables.assignedUserId,
          ...getTrackingData(task),
        })
      } else if (variables.assignedUserId === null) {
        track(EVENT.UNASSIGN_TASK, getTrackingData(task))
        updatedTask.assignedUser = null
      }

      onMutate?.(variables)

      return {
        optimisticData: updatedTask,
        rollbackData: task,
      }
    },
    onError(error) {
      toast.error('Error updating task', {
        description: error.message,
      })

      // TODO: Remove from completed/todo infinite lists if error marking as completed/todo
    },
    onSuccess(data) {
      if (data?.id) {
        console.log(
          'Successfully updated task %o — invalidating',
          data.title,
          data
        )
        utils.task.get.invalidate(data.id)
      }
    },
    onSettled() {
      console.log('Finished updating task — invalidating lists and counts')
      utils.tasks.orderedList.invalidate()
      utils.tasks.count.invalidate()
    },
  })
}
