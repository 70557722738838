import { EVENT } from '@/shared/constants/analytics'
import { getTrackingData, isScheduled } from '@/shared/utils/task'
import { AlertDialogButtonWithTooltip } from '@/web/components/AlertDialogButtonWithTooltip'
import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/web/components/ui/alert-dialog'
import { DemoContext } from '@/web/contexts/demo'
import { TaskContext } from '@/web/contexts/task'
import { track } from '@/web/utils/analytics'
import { api } from '@/web/utils/api'
import { deleteObjectFromNormalizedData } from '@/web/utils/normy'
import { useQueryNormalizer } from '@normy/react-query'
import { Trash } from 'lucide-react'
import { useContext } from 'react'

export const TaskDeleteButton = () => {
  const task = useContext(TaskContext)
  const isDemo = useContext(DemoContext)

  const utils = api.useUtils()
  const { getNormalizedData } = useQueryNormalizer()
  const deleteTaskMutation = api.task.delete.useMutation({
    onMutate() {
      if (!task) {
        console.log('No task to delete')
        return
      }

      deleteObjectFromNormalizedData(getNormalizedData(), task.id)
      utils.task.get.setData(task.id, null)
      utils.tasks.count.setData({ type: 'todo' }, data =>
        data ? data - 1 : data
      )
      utils.tasks.count.setData({ type: 'completed' }, data =>
        data ? data - 1 : data
      )
      utils.tasks.count.setData({ type: 'cancelled' }, data =>
        data ? data - 1 : data
      )
      if (isScheduled(task)) {
        utils.tasks.count.setData({ type: 'scheduled' }, data =>
          data ? data - 1 : data
        )
      }
      track(EVENT.DELETE_TASK, getTrackingData(task))
    },
    onError(err) {
      if (err.message.match(/does not exist/gi)) {
        console.log('No task to delete')
        return
      }

      if (!task) {
        return
      }

      utils.tasks.count.setData({ type: 'todo' }, data =>
        data ? data + 1 : data
      )
      utils.tasks.count.setData({ type: 'completed' }, data =>
        data ? data + 1 : data
      )
      utils.tasks.count.setData({ type: 'cancelled' }, data =>
        data ? data + 1 : data
      )
      if (isScheduled(task)) {
        utils.tasks.count.setData({ type: 'scheduled' }, data =>
          data ? data + 1 : data
        )
      }
    },
    onSettled() {
      utils.tasks.orderedList.invalidate()
      utils.tasks.count.invalidate()
    },
  })

  if (!task) {
    return null
  }

  return (
    <AlertDialogButtonWithTooltip
      getButtonProps={({ isOpen }) => ({
        variant: 'ghost',
        size: 'sm',
        theme: 'destructive',
        state: isOpen ? 'active' : null,
        children: <Trash className='size-[13px]' />,
      })}
      tooltipContentChildren={<span className='text-destructive'>Delete</span>}
      alertDialogChildren={
        <>
          <AlertDialogHeader>
            <AlertDialogTitle>
              Are you sure you want to delete “{task.title}”?
            </AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              theme='destructive'
              onClick={() => !isDemo && deleteTaskMutation.mutate(task.id)}>
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </>
      }
    />
  )
}
