import {
  UPDATE_EVENT,
  type PusherDataType,
  type ScopeId,
} from '@/shared/constants/pusher'
import dayjs from '@/shared/singletons/dayjs'
import { pusherClient } from '@/web/singletons/pusher'
import React, { useEffect, useMemo } from 'react'

const CHANNEL_SCOPE_TIME_MAP = new Map<string, number>()
const END_STATUSES: PusherDataType['status'][] = ['success', 'error']

const DATA_CACHE_MAP = new Map<string, PusherDataType[]>()
function removeMatchingScopeData(data: PusherDataType[], scopeId?: ScopeId) {
  return data.filter(cacheData => cacheData.scopeId == scopeId)
}
function updateDataCache(channel: string, data: PusherDataType) {
  const cache = DATA_CACHE_MAP.get(channel) || []
  let updatedCache: PusherDataType[] = []

  // If the previous data is ended, remove it and all data matching its scope from the cache
  const lastItem = cache.at(-1)
  if (lastItem && END_STATUSES.includes(lastItem.status)) {
    updatedCache = removeMatchingScopeData(cache, lastItem.scopeId)
  }

  updatedCache.push(data)

  console.debug('Updated data cache for channel %o: %o', channel, updatedCache)
  DATA_CACHE_MAP.set(channel, updatedCache)
}

function removeEndedDataCache(channel: string): boolean {
  const latestData = getLatestCacheData(channel)
  if (latestData && END_STATUSES.includes(latestData.status)) {
    const updatedCache = removeMatchingScopeData(
      DATA_CACHE_MAP.get(channel) || [],
      latestData.scopeId
    )

    console.debug(
      'Removed data for scope %o from channel %o: %o',
      latestData.scopeId,
      channel,
      updatedCache
    )

    if (updatedCache.length > 0) {
      DATA_CACHE_MAP.set(channel, updatedCache)
    } else {
      console.debug('Removing empty channel data %o', channel)
      DATA_CACHE_MAP.delete(channel)
    }
    return true
  }

  return false
}
function getLatestCacheData(channel: string): PusherDataType | null {
  return DATA_CACHE_MAP.get(channel)?.at(-1) || null
}

export const usePusherData = (channel: string) => {
  const latestData = getLatestCacheData(channel)
  const [initialData] = React.useState(latestData)
  const [data, setData] = React.useState<PusherDataType | null>(latestData)
  const dataRef = React.useRef(data)

  // If the data status is ended, remove it
  useEffect(() => {
    if (removeEndedDataCache(channel)) {
      const latestData = getLatestCacheData(channel)
      console.debug(
        'Reverting to latest data for channel %o',
        channel,
        latestData
      )
      setData(latestData)
    }
  }, [data])

  useEffect(() => {
    if (!channel) {
      return
    }

    console.debug('Subscribing to Pusher channel %o', channel)
    const pusherChannel = pusherClient.subscribe(channel)
    const onUpdate = (data: PusherDataType) => {
      const cacheKey = pusherChannel.name + '-' + data.scopeId
      const cacheTime = CHANNEL_SCOPE_TIME_MAP.get(cacheKey)

      // Enrich data with duration
      // FIXME: Prefer the cache data matching the scope id
      if (!cacheTime && !END_STATUSES.includes(data.status)) {
        console.log('Startup update for scope %o', cacheKey)
        CHANNEL_SCOPE_TIME_MAP.set(cacheKey, Date.now())
      }
      if (cacheTime && END_STATUSES.includes(data.status)) {
        CHANNEL_SCOPE_TIME_MAP.delete(cacheKey)
        const duration = Date.now() - cacheTime
        console.log(
          'Ended update for scope %o with duration %o',
          cacheKey,
          duration
        )
        data = {
          ...data,
          duration,
        }
      }

      if (
        dataRef.current?.date &&
        dayjs(data.date).isBefore(dayjs(dataRef.current?.date))
      ) {
        console.log('Ignoring outdated update', {
          old: data,
          current: dataRef.current,
        })
        return
      }

      const newData = {
        ...(getLatestCacheData(channel) ?? {}),
        ...data,
      }
      console.log('Received update for channel %o', channel, {
        previous: dataRef.current,
        current: newData,
      })
      setData(newData)
      updateDataCache(channel, newData)
    }
    pusherChannel.bind(UPDATE_EVENT, onUpdate)

    return () => {
      console.debug('Unsubscribing from task update channel', channel)
      pusherChannel.unbind(UPDATE_EVENT, onUpdate)
    }
  }, [channel])

  useEffect(() => {
    dataRef.current = data
  }, [data])

  return useMemo(
    () => ({
      ...data,
      isInitial: initialData === data,
    }),
    [data, initialData]
  )
}
