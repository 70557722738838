import {
  type GmailAttachmentMetadataType,
  type GmailEmailType,
} from '@/shared/constants/gmail'
import type { SummaryType } from '@/shared/constants/llm'
import { splitEmailContent } from '@/shared/utils/email'
import {
  convertMarkdownToHtml,
  sanitizeAndPreserveHtml,
  sanitizeHtml,
} from '@/shared/utils/html'
import { ContextContent } from '@/web/components/ContextContent'
import { RawHtml } from '@/web/components/RawHtml'
import { Avatar, AvatarImage } from '@/web/components/ui/avatar'
import { Button } from '@/web/components/ui/button'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/web/components/ui/collapsible'
import { Label } from '@/web/components/ui/label'
import { Skeleton } from '@/web/components/ui/skeleton'
import { cn } from '@/web/libs/utils'
import { api } from '@/web/utils/api'
import { faImage } from '@fortawesome/free-regular-svg-icons'
import {
  faEnvelopeOpenText,
  faPaperclip,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import Link from 'next/link'
// import email from 'next-auth/providers/email'
import { useMemo, useState } from 'react'

const EmailAttachment = ({
  // emailId,
  attachmentMetadata,
}: {
  // emailId: string
  attachmentMetadata: GmailAttachmentMetadataType
}) => {
  // const getAttachmentQueryResult = api.gmail.getAttachment.useQuery(
  //   {
  //     emailId,
  //     attachmentId: attachmentMetadata.attachmentId,
  //   },
  //   {
  //     enabled: false,
  //   }
  // )
  // const downloadAttachment = async () => {
  //   const result = await getAttachmentQueryResult.refetch()
  //   if (result.data) {
  //     // const blob = base64ToBlob(result.data.base64Data, result.data.mimeType);
  //     const url = URL.createObjectURL(result.data)

  //     // Trigger download
  //     const downloadLink = document.createElement('a')
  //     downloadLink.href = url
  //     downloadLink.download = attachmentMetadata.filename // Set the file name
  //     document.body.appendChild(downloadLink)
  //     downloadLink.click()
  //     document.body.removeChild(downloadLink)

  //     URL.revokeObjectURL(url)
  //   }
  // }

  return (
    <div
      key={attachmentMetadata.attachmentId}
      className='flex flex-col gap-1 text-sm'>
      <div>
        <span className='text-primary'>{attachmentMetadata.filename}</span>
        {attachmentMetadata.summary ? (
          <>
            {': '}
            {attachmentMetadata.summary}
          </>
        ) : null}
      </div>
      <ContextContent summary={attachmentMetadata as Partial<SummaryType>} />
      <small className='text-muted-foreground flex flex-row flex-wrap gap-4'>
        {attachmentMetadata.links?.length
          ? attachmentMetadata.links.map((link, index) => (
              <Link key={index} href={link.url}>
                <Button variant='link' theme='secondary' size='none'>
                  {link.title || link.url}
                </Button>
              </Link>
            ))
          : null}
        {attachmentMetadata.dates?.length
          ? attachmentMetadata.dates.map(({ date, title }) => (
              <span key={date?.toString()}>
                {title ? `${title}: ` : ''}
                {new Intl.DateTimeFormat('en-US', {
                  dateStyle: 'short',
                  timeStyle: 'short',
                }).format(new Date(date))}
              </span>
            ))
          : null}
        {attachmentMetadata.contacts?.length
          ? attachmentMetadata.contacts.map((contact, index) => (
              <span
                key={index}
                className='flex flex-row flex-wrap gap-x-2 gap-y-1'>
                {contact.photoUrl ? (
                  <Avatar size={'xs'}>
                    <AvatarImage src={contact.photoUrl} />
                  </Avatar>
                ) : null}
                {contact.name ? (
                  <span className='font-bold'>{contact.name}</span>
                ) : null}
                {contact.email ? (
                  <Link href={`mailto:${contact.email}`}>
                    <Button variant='link' theme='secondary' size='none'>
                      {contact.email}
                    </Button>
                  </Link>
                ) : null}
                {contact.phone ? (
                  <Link href={`tel:${contact.phone}`}>
                    <Button variant='link' theme='secondary' size='none'>
                      {contact.phone}
                    </Button>
                  </Link>
                ) : null}
                {contact.address ? (
                  <Link href={`https://maps.google.com/?q=${contact.address}`}>
                    <Button variant='link' theme='secondary' size='none'>
                      {contact.address}
                    </Button>
                  </Link>
                ) : null}
              </span>
            ))
          : null}
        {!_.isEmpty(attachmentMetadata.metadata) ? (
          <div className='flex flex-col gap-1'>
            {Object.entries(attachmentMetadata.metadata).map(([key, value]) => (
              <span key={key}>
                {key}: {value}
              </span>
            ))}
          </div>
        ) : null}
      </small>
    </div>
  )
}

export function RawEmailWithAttachments({ email }: { email: GmailEmailType }) {
  const [isMoreOpen, setIsMoreOpen] = useState(false)
  const [isAllowingImages, setIsAllowingImages] = useState(false)

  const hasRawBody = !!(email.body?.html || email.body?.plain)
  const loadEmailGmailResult = api.gmail.loadEmail.useQuery(
    {
      emailId: email.id,
    },
    {
      enabled: !hasRawBody,
    }
  )
  const emailWithHtml = hasRawBody ? email : loadEmailGmailResult.data?.email

  const html = useMemo(() => {
    const html = emailWithHtml?.body?.html
      ? sanitizeAndPreserveHtml(emailWithHtml?.body.html)
      : emailWithHtml?.body?.plain
      ? convertMarkdownToHtml(emailWithHtml?.body.plain)
      : null
    console.log('Converted email to html', { email, html })
    return html
  }, [emailWithHtml?.body?.html, emailWithHtml?.body?.plain])

  const sanitizedHtml = useMemo(() => {
    return html ? sanitizeHtml(html, { isAllowingImages }) : null
  }, [html, isAllowingImages])

  const htmlParts = useMemo(() => {
    return sanitizedHtml ? splitEmailContent(sanitizedHtml) : null
  }, [sanitizedHtml])

  if (!html || !htmlParts) {
    return null
  }

  return (
    <>
      {html && htmlParts ? (
        <div className='grid gap-2 [grid-column:span_2] md:[grid-column:2]'>
          <div className='flex flex-col justify-between gap-2 md:flex-row md:items-center'>
            <Label className='flex items-center gap-2'>
              <FontAwesomeIcon
                fixedWidth
                icon={faEnvelopeOpenText}
                className='text-muted-foreground/50'
              />
              Email
            </Label>

            <Button
              variant='link'
              theme='default'
              size='none'
              startIcon={
                <FontAwesomeIcon
                  fixedWidth
                  icon={faImage}
                  className='text-primary'
                />
              }
              className={cn(
                'justify-start text-sm',
                isAllowingImages && 'hidden md:invisible md:flex'
              )}
              onClick={() => setIsAllowingImages(!isAllowingImages)}>
              Images are not being displayed. Click here to display images.
            </Button>
          </div>
          <RawHtml
            html={htmlParts.latestMessageHtml}
            className='text-sm [&_p]:text-sm'
          />
          {htmlParts.previousMessagesHtml.length > 0 ? (
            <Collapsible onOpenChange={setIsMoreOpen}>
              <CollapsibleTrigger>
                <Button variant='link' size='xs'>
                  {isMoreOpen ? 'Less' : 'More'}…
                </Button>
              </CollapsibleTrigger>
              <CollapsibleContent>
                <RawHtml
                  html={htmlParts.previousMessagesHtml}
                  className='text-sm [&_p]:text-sm'
                />
              </CollapsibleContent>
            </Collapsible>
          ) : null}
        </div>
      ) : loadEmailGmailResult.isLoading ? (
        <Skeleton className='h-40 [grid-column:span_2] md:[grid-column:2]' />
      ) : null}

      {email.attachmentsMetadata?.length ? (
        <div className='grid gap-2 [grid-column:span_2] md:[grid-column:2]'>
          <Label className='flex items-center gap-2'>
            <FontAwesomeIcon
              fixedWidth
              icon={faPaperclip}
              className='text-muted-foreground/50'
            />
            Attachments
          </Label>
          <div className='flex flex-col gap-2'>
            {email.attachmentsMetadata.map(attachment => (
              <EmailAttachment
                attachmentMetadata={attachment}
                key={attachment.attachmentId}
              />
            ))}
          </div>
        </div>
      ) : null}
    </>
  )
}
