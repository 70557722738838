import { SAMPLE_CONTEXT_DATA_1 } from '@/shared/constants/demo'
import {
  getTaskOriginEmail,
  isGmailData,
  isGmailTask,
} from '@/shared/utils/gmail'
import {
  getConversationUsers,
  getTaskOriginConversation,
  isSlackData,
  isSlackTask,
} from '@/shared/utils/slack'
import { isTaskWithContextItems } from '@/shared/utils/task'
import { Avatars } from '@/web/components/Avatars'
import { DemoContext } from '@/web/contexts/demo'
import { TaskContext } from '@/web/contexts/task'
import { api } from '@/web/utils/api'
import { IntegrationName } from '@prisma/client'
import { useContext } from 'react'

export function TaskAvatars() {
  const task = useContext(TaskContext)
  const isDemo = useContext(DemoContext)
  if (!task) {
    throw new Error('Task not found')
  }

  const hasContextItems = isTaskWithContextItems(task)
  const hasSlackContext = hasContextItems && isSlackTask(task)
  const hasGmailContext = hasContextItems && isGmailTask(task)

  const loadSlackContextQueryResult = api.context.load.useQuery(
    {
      taskId: task?.id ?? '',
      integrationName: IntegrationName.SLACK,
    },
    {
      enabled: hasSlackContext && !isDemo,
    }
  )
  const slackContextData = isDemo
    ? SAMPLE_CONTEXT_DATA_1
    : loadSlackContextQueryResult.data

  const loadGmailContextQueryResult = api.context.load.useQuery(
    {
      taskId: task?.id ?? '',
      integrationName: IntegrationName.GMAIL,
    },
    {
      enabled: hasGmailContext && !isDemo,
    }
  )
  const gmailContextData = isDemo
    ? SAMPLE_CONTEXT_DATA_1
    : loadGmailContextQueryResult.data

  if (hasSlackContext && isSlackData(slackContextData)) {
    const taskOriginConversation = getTaskOriginConversation(slackContextData)
    if (!taskOriginConversation) {
      return null
    }

    const conversationUsers = getConversationUsers(taskOriginConversation)
    return (
      <Avatars
        size='xs'
        users={conversationUsers
          .filter(Boolean)
          .map(user => ({ name: user.name, image: user.image }))}
      />
    )
  }

  if (hasGmailContext && isGmailData(gmailContextData)) {
    const latestEmail =
      getTaskOriginEmail(gmailContextData) ?? gmailContextData.emails.at(-1)
    if (!latestEmail?.contacts?.length) {
      return null
    }

    return (
      <Avatars
        size='xs'
        users={latestEmail.contacts?.map(googleContact => ({
          name: googleContact.name ?? googleContact.email,
          image: googleContact.photoUrl,
        }))}
      />
    )
  }

  return null
}
