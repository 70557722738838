import { EmailsTable } from '@/web/components/EmailsTable'

export const SpamEmailsForm = ({
  emailIds,
  actionId,
  taskId,
}: {
  emailIds: string[]
  actionId: string
  taskId: string
}) => {
  return (
    <EmailsTable
      type='spam'
      emailIds={emailIds}
      actionId={actionId}
      taskId={taskId}
    />
  )
}
