import { type AllContextDataType } from '@/shared/constants/context'
import {
  type TaskWithContextItems,
  type TaskWithContextItemsAndActions,
} from '@/shared/constants/task'
import dayjs from '@/shared/singletons/dayjs'
import { hasGmailData, isGmailData } from '@/shared/utils/gmail'
import { hasSlackData, isSlackData } from '@/shared/utils/slack'
import { isTaskWithContextItemsAndActions } from '@/shared/utils/task'
import { Conversations } from '@/web/components/Conversations'
import { Email } from '@/web/components/Email'
import { Label } from '@/web/components/ui/label'
import { Skeleton } from '@/web/components/ui/skeleton'
import { TaskContext } from '@/web/contexts/task'
import { useAllContextData } from '@/web/hooks/use-all-context-data'
import { api } from '@/web/utils/api'
import { type Task } from '@prisma/client'
import _ from 'lodash'
import pluralize from 'pluralize'
import React, { useContext } from 'react'

type Change = {
  key: string
  from: string | number | boolean | object | Array<unknown> | null
  to: string | number | boolean | object | Array<unknown> | null
  change?: number | object | Array<unknown> | null
}

export const TaskHistoryTable = () => {
  const task = useContext(TaskContext)
  const { data: allContextData, isLoading } = useAllContextData()
  if (!task) {
    throw new Error('Task not found')
  }

  const taskHistoryResult = api.taskHistory.get.useQuery(task.id)
  const taskHistory = taskHistoryResult.data

  if (
    taskHistoryResult.isLoading ||
    isLoading ||
    !isTaskWithContextItemsAndActions(task)
  ) {
    return <Skeleton className='h-20' />
  }

  if (!taskHistory?.length) {
    return <span className='text-muted-foreground'>No changes</span>
  }

  let lastTask: TaskWithContextItemsAndActions = task
  const lastContextData = allContextData

  return taskHistory.map(historyItem => {
    const historyItemData = historyItem.data as unknown as Task & {
      data?: AllContextDataType
    }
    if (!historyItemData || typeof historyItemData !== 'object') {
      return null
    }

    let newConversations = null
    if (hasSlackData(lastTask as TaskWithContextItems)) {
      const previousConversations = isSlackData(historyItemData.data)
        ? historyItemData.data.conversations ?? []
        : []
      newConversations = _.differenceWith(
        isSlackData(lastContextData) ? lastContextData.conversations ?? [] : [],
        previousConversations,
        _.isEqual
      )
    }

    let newEmails = null
    if (hasGmailData(lastTask)) {
      const previousEmails = isGmailData(historyItemData.data)
        ? historyItemData.data.emails ?? []
        : []
      newEmails = _.differenceWith(
        isGmailData(lastContextData) ? lastContextData.emails ?? [] : [],
        previousEmails,
        _.isEqual
      )
    }

    // Create object with other property changes
    const { data, ...otherTaskProperies } = historyItemData
    const changes = Object.entries(
      _.pick(lastTask, Object.keys(otherTaskProperies)) as Partial<Task>
    )
      .map(([key, to]) => {
        const from = otherTaskProperies[key as keyof typeof otherTaskProperies]
        if (_.isEqual(from, to)) {
          console.warn('No change in task property', key)
          return null
        }

        if (typeof from === 'number' && typeof to === 'number') {
          return {
            key,
            from,
            to,
            change: to - from,
          }
        }

        if (
          (_.isDate(from) && _.isDate(to)) ||
          (from == null && _.isDate(to)) ||
          (_.isDate(from) && to == null)
        ) {
          return {
            key,
            from,
            to,
            change: (to?.getTime() ?? 0) - (from?.getTime() ?? 0),
          }
        }

        if (typeof from === 'string' && typeof to === 'string') {
          return {
            key,
            from,
            to,
          }
        }

        return {
          key,
          from,
          to,
        }
      })
      .filter(Boolean) as Change[]

    // Update last task for next loop
    lastTask = {
      ...lastTask,
      ...historyItemData,
    }

    return (
      <div
        key={historyItem.id}
        className='grid grid-flow-row gap-2 py-2 [grid-template-columns:1fr_auto]'>
        <div className='flex flex-col gap-6'>
          {newConversations?.length ? (
            <div className='flex flex-col gap-2'>
              <small className='text-muted-foreground'>{`+${
                newConversations.length
              } ${pluralize(
                'new conversation',
                newConversations.length
              )}`}</small>
              <div className='border-l-muted flex flex-col gap-2 border-l pl-4'>
                <Conversations conversations={newConversations} />
              </div>
            </div>
          ) : null}
          {newEmails?.length ? (
            <div className='flex flex-col gap-2'>
              <small className='text-muted-foreground'>{`+${
                newEmails.length
              } ${pluralize('new email', newEmails.length)}`}</small>
              <div className='border-l-muted flex flex-col gap-2 border-l pl-4'>
                {_.sortBy(newEmails, 'date').map(email => (
                  <Email key={email.id} email={email} isDefaultOpen={false} />
                ))}
              </div>
            </div>
          ) : null}
          {/* eslint-disable prettier/prettier */}
          <div className='grid grid-flow-row items-start gap-x-2 gap-y-1 [grid-column:span_2] [grid-template-columns:auto_1fr]'>
            {changes?.length
              ? changes.map((change, index) => (
                  <React.Fragment key={index}>
                    <Label>{_.startCase(change.key)}:</Label>
                    <div className='flex flex-wrap items-baseline gap-2'>
                      {change.from != null ? (
                        <del className='text-destructive'>
                          {JSON.stringify(change.from)}
                        </del>
                      ) : null}
                      {change.from != null && change.to != null ? ' → ' : null}
                      {change.to != null ? (
                        <ins className='text-success'>
                          {JSON.stringify(change.to)}
                        </ins>
                      ) : null}
                      {change.change != null ? (
                        <small className='text-muted-foreground'>
                          (
                          {_.isDate(change.from) &&
                          _.isDate(change.to) &&
                          typeof change.change === 'number'
                            ? dayjs
                                .duration(change.change, 'milliseconds')
                                .humanize()
                            : typeof change.change === 'number'
                            ? change.change > 0
                              ? '+' + change.change
                              : change.change
                            : JSON.stringify(change.change)}
                          )
                        </small>
                      ) : null}
                    </div>
                  </React.Fragment>
                ))
              : null}
            {historyItem.reason ? (
              <>
                <span className='text-muted-foreground'>Reason:</span>
                <span>{historyItem.reason}</span>
              </>
            ) : null}
          </div>
        </div>
        <small
          className='text-muted-foreground'
          title={new Intl.DateTimeFormat('en-US', {
            dateStyle: 'long',
            timeStyle: 'short',
          }).format(historyItem.createdAt)}>
          {dayjs(historyItem.createdAt).fromNow()}
        </small>
      </div>
    )
  })
}
