import { cva, type VariantProps } from 'class-variance-authority'

const variants = cva('rounded-full bg-gradient', {
  variants: {
    size: {
      default: 'h-3 w-3',
      sm: 'h-2.5 w-2.5',
      xs: 'h-1.5 w-1.5',
    },
  },
  defaultVariants: {
    size: 'default',
  },
})

export const NotificationDot = ({
  className,
  size,
}: { className?: string } & VariantProps<typeof variants>) => {
  return (
    <div className={className}>
      <div className={variants({ size })} />
    </div>
  )
}
