import type {
  GoogleCalendarCreateEventType,
  GoogleCalendarEventType,
} from '@/shared/constants/google-calendar'
import { isCreateEvent } from '@/shared/utils/google-calendar'
import { Button } from '@/web/components/ui/button'
import { CardTitle } from '@/web/components/ui/card'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { Route } from 'next'
import Link from 'next/link'

export const EventCardTitle = ({
  event,
}: {
  event: GoogleCalendarEventType | GoogleCalendarCreateEventType
}) => {
  const title = isCreateEvent(event)
    ? event.title
    : typeof event.summary === 'string'
    ? event.summary
    : event.title
  const url = isCreateEvent(event) ? null : event.url

  return (
    <CardTitle className='flex flex-row items-start justify-between gap-x-2'>
      <span className='flex-1'>{title}</span>
      {url ? (
        <Link href={(url ?? '') as Route} target='_blank'>
          <Button variant='ghost' theme='secondary' size='xs'>
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
          </Button>
        </Link>
      ) : null}
    </CardTitle>
  )
}
