import { BASE_TASK } from '@/shared/constants/demo'
import type { CreateTaskInputType } from '@/shared/constants/task'
import type { Task } from '@prisma/client'

let temporaryTaskId = 0

export const isTemporaryTask = (task: Task) => {
  return task.id.startsWith('temp-')
}

export function createTemporaryTask(data: CreateTaskInputType) {
  const newTemporaryTask: Task = {
    ...BASE_TASK,
    ...data,
    createdAt: new Date(),
    updatedAt: new Date(),
    id: `temp-${temporaryTaskId.toString()}`,
  }
  temporaryTaskId++

  return newTemporaryTask
}
