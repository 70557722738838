import { type SlackMessage } from '@/shared/constants/slack'
import { Message } from '@/web/components/Message'
import _ from 'lodash'

export const Messages = ({
  messages,
  conversationId,
  userIds,
}: {
  messages?: SlackMessage[] | null
  conversationId?: string
  userIds?: string[]
}) => (
  <div className='flex flex-col gap-2'>
    {messages
      ? _.sortBy(messages, 'date').map(message => (
          <Message
            key={message.id}
            message={message}
            conversationId={conversationId}
            userIds={userIds}
          />
        ))
      : null}
  </div>
)
