import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/web/components/ui/tooltip'
import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const EstimatedByPulseTooltip = ({
  children,
}: React.PropsWithChildren) => (
  <TooltipProvider>
    <Tooltip>
      <TooltipTrigger className='text-muted-foreground flex items-center gap-2 text-xs'>
        <FontAwesomeIcon icon={faWandMagicSparkles} /> Estimated for you
      </TooltipTrigger>
      <TooltipContent className='flex flex-col gap-2'>
        {children}
      </TooltipContent>
    </Tooltip>
  </TooltipProvider>
)
