import { type GmailEmailType } from '@/shared/constants/gmail'
import { CollapsibleCaret } from '@/web/components/CollapsibleCaret'
import { EmailSubject } from '@/web/components/EmailSubject'
import { EmailThreadMessage } from '@/web/components/EmailThreadMessage'
import { Avatar, AvatarFallback } from '@/web/components/ui/avatar'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/web/components/ui/collapsible'
import { cn } from '@/web/libs/utils'
import { faEnvelope, faEnvelopeOpen } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'

export const EmailThread = ({
  subject,
  labels,
  emails,
  isDefaultOpen = true,
  isAlwaysOpen = false,
  className,
}: {
  subject: string
  labels: string[]
  emails: GmailEmailType[]
  isDefaultOpen?: boolean
  isAlwaysOpen?: boolean
  className?: string
}) => {
  const [isOpen, setIsOpen] = useState(isDefaultOpen || isAlwaysOpen)

  return (
    <Collapsible
      className={cn('flex w-full flex-col gap-4', className)}
      defaultOpen={isDefaultOpen}
      open={isOpen}
      onOpenChange={isAlwaysOpen ? () => void 0 : setIsOpen}>
      <CollapsibleTrigger
        className='group grid w-full grid-flow-row items-start gap-2 break-words text-left [grid-template-columns:auto_minmax(0,1fr)]'
        disabled={isAlwaysOpen}>
        {/* Placeholder for grid */}
        {/* eslint-disable-next-line prettier/prettier */}
        <Avatar className='invisible relative -mb-1 h-0 [grid-column:1] [grid-row:1]'>
          <AvatarFallback />
        </Avatar>
        <FontAwesomeIcon
          icon={isOpen ? faEnvelopeOpen : faEnvelope}
          // eslint-disable-next-line prettier/prettier
          className='text-muted-foreground/50 h-full w-auto justify-self-center pt-1 [grid-column:1] [grid-row:1]'
        />
        {/* Subject */}
        <div className='flex flex-row items-start justify-between gap-4 [grid-column:2]'>
          <EmailSubject subject={subject} labels={labels} emails={emails} />
          {!isAlwaysOpen && (
            <CollapsibleCaret
              isOpen={isOpen}
              className='text-muted-foreground group-hover:text-primary flex-shrink-0'
            />
          )}
        </div>
      </CollapsibleTrigger>

      <CollapsibleContent className='flex flex-col gap-8'>
        {emails.map(email => (
          <EmailThreadMessage key={email.id} email={email} />
        ))}
      </CollapsibleContent>
    </Collapsible>
  )
}
