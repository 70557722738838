import { EventDetails } from '@/web/components/EventDetails'
import { TaskIntegrationContext } from '@/web/components/TaskIntegrationContext'
import { IntegrationName } from '@prisma/client'
import pluralize from 'pluralize'

export const GoogleCalendarTaskContext = () => {
  return (
    <TaskIntegrationContext
      integrationName={IntegrationName.GOOGLE_CALENDAR}
      getTriggerText={({ count, data }) =>
        `${data ? data.events.length : count} related ${pluralize(
          'event',
          data ? data.events.length : count
        )}`
      }>
      {data => (
        <div className='grid gap-y-2'>
          {data.events.map(event => (
            <EventDetails key={event.id} event={event} />
          ))}
        </div>
      )}
    </TaskIntegrationContext>
  )
}
