import { type IntegrationContextDataType } from '@/shared/constants/context'
import { CollapsibleCaret } from '@/web/components/CollapsibleCaret'
import { CardDescription } from '@/web/components/ui/card'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/web/components/ui/collapsible'
import { Skeleton } from '@/web/components/ui/skeleton'
import { INTEGRATION_DATA } from '@/web/constants/integration'
import { CONTEXT_ICON_PROPS } from '@/web/constants/task'
import { TaskContext } from '@/web/contexts/task'
import { useContextData } from '@/web/hooks/use-context-data'
import { type IntegrationName } from '@prisma/client'
import React, { useContext } from 'react'

export const TaskIntegrationContext = <
  TIntegrationName extends IntegrationName,
>({
  integrationName,
  getTriggerText,
  children,
}: {
  integrationName: TIntegrationName
  getTriggerText: (args: {
    count: number
    data?: IntegrationContextDataType<TIntegrationName> | null
  }) => string
  children: (
    data: IntegrationContextDataType<TIntegrationName>
  ) => React.ReactNode
}) => {
  const task = useContext(TaskContext)

  const [isOpen, setIsOpen] = React.useState(false)

  const { data, isLoading, count } = useContextData(integrationName)
  const Icon = INTEGRATION_DATA[integrationName].Icon

  if (isLoading && !count) {
    return (
      <div className='flex flex-row items-center gap-2'>
        <Icon {...CONTEXT_ICON_PROPS} />
        <Skeleton className='h-5 w-24' />
      </div>
    )
  }

  if ((!data && !isLoading) || !count) {
    return null
  }

  return (
    <CardDescription key={integrationName + task?.id}>
      <Collapsible onOpenChange={setIsOpen} open={isOpen}>
        <CollapsibleTrigger className='flex flex-row items-center gap-2'>
          <Icon {...CONTEXT_ICON_PROPS} />
          {getTriggerText({ data, count })}
          <CollapsibleCaret isOpen={isOpen} />
        </CollapsibleTrigger>
        <CollapsibleContent className='pt-1'>
          {data ? (
            children(data as IntegrationContextDataType<TIntegrationName>)
          ) : isLoading ? (
            <Skeleton className='h-4 w-24' />
          ) : null}
        </CollapsibleContent>
      </Collapsible>
    </CardDescription>
  )
}
