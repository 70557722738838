import { type NotionPage } from '@/shared/constants/notion'
import { hasContextMetadata, isSummary } from '@/shared/utils/context'
import { convertMarkdownToHtml } from '@/shared/utils/html'
import { toMarkdown } from '@/shared/utils/notion'
import { ContextContent } from '@/web/components/ContextContent'
import { NotionPageCardTitle } from '@/web/components/NotionPageCardTitle'
import { RawHtml } from '@/web/components/RawHtml'
import { Button } from '@/web/components/ui/button'
import { CardContent } from '@/web/components/ui/card'
import { Label } from '@/web/components/ui/label'
import { Separator } from '@/web/components/ui/separator'
import React from 'react'

export const NotionPageCardContent = ({
  page,
  isAlwaysOpen,
}: {
  page: NotionPage
  isAlwaysOpen?: boolean
}) => {
  const markdown = toMarkdown(page)
  const html = convertMarkdownToHtml(markdown)

  const [isShowingAll, setIsShowingAll] = React.useState(!!isAlwaysOpen)

  const contextContentElement = (
    <ContextContent
      contextMetadata={hasContextMetadata(page) ? page : undefined}
      summary={isSummary(page) ? page : undefined}
    />
  )

  const pagePropertyEntries = Object.entries(page.properties).filter(
    ([key, value]) => value != null && value !== '' && key !== 'title'
  )

  return (
    <CardContent>
      <NotionPageCardTitle page={page} />

      {contextContentElement}

      {pagePropertyEntries.length > 0 ? (
        <>
          <Separator className='my-4' />
          <div className='grid gap-2 [grid-template-columns:auto_1fr]'>
            {pagePropertyEntries.map(([key, value]) => (
              <Label
                key={key}
                size='lg'
                className='grid [grid-column:span_2] [grid-template-columns:subgrid]'>
                {key}:{' '}
                <span
                  className='text-secondary-foreground raw-html'
                  dangerouslySetInnerHTML={{
                    __html: convertMarkdownToHtml(value),
                  }}
                />
              </Label>
            ))}
          </div>
        </>
      ) : null}

      {html ? (
        <>
          <Separator className='my-4' />
          <RawHtml html={html} className={isShowingAll ? '' : 'line-clamp-3'} />
          {!isAlwaysOpen ? (
            <Button
              onClick={() => setIsShowingAll(!isShowingAll)}
              variant='link'
              theme='default'
              size='xs'
              className='justify-end'>
              {isShowingAll ? 'Less…' : 'More…'}{' '}
            </Button>
          ) : null}
        </>
      ) : null}
    </CardContent>
  )
}
