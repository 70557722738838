'use client'

import { type ActionParametersType } from '@/shared/constants/action'
import { EVENT } from '@/shared/constants/analytics'
import dayjs from '@/shared/singletons/dayjs'
import * as actionUtil from '@/shared/utils/action'
import { isValid } from '@/shared/utils/action'
import * as taskUtil from '@/shared/utils/task'
import { TaskActionButton } from '@/web/components/TaskActionButton'
import { TaskActionDetails } from '@/web/components/TaskActionDetails'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/web/components/ui/collapsible'
import { Skeleton } from '@/web/components/ui/skeleton'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/web/components/ui/tooltip'
import { DemoContext } from '@/web/contexts/demo'
import { TaskContext } from '@/web/contexts/task'
import { useAllContextData } from '@/web/hooks/use-all-context-data'
import { useIntegrationNames } from '@/web/hooks/use-integration-names'
import { usePrevious } from '@/web/hooks/use-previous'
import { track } from '@/web/utils/analytics'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ActionType, TaskType, type Action } from '@prisma/client'
import { useContext, useEffect, useState } from 'react'
import { TaskActionEditCard } from './TaskActionEditCard'

const ACTION_TYPES_WITH_RUN_AREA: readonly ActionType[] = [
  ActionType.SEND_MESSAGE,
  ActionType.REPLY_TO_MESSAGE,
  ActionType.SEND_EMAIL,
  ActionType.REPLY_TO_EMAIL,
] as const

export const TaskAction = ({ action }: { action: Action }) => {
  const task = useContext(TaskContext)
  if (!task) {
    throw new Error('Task not found')
  }
  const isDemo = useContext(DemoContext)

  const [isOpen, setIsOpen] = useState(false)

  const wasCompleted = usePrevious(!!action.completedDate)
  const isCompleted = !!action.completedDate

  const isRunningAction = isOpen && !isCompleted && !isDemo

  const { data: allContextData, isLoading } = useAllContextData()

  useEffect(() => {
    if (wasCompleted || !isCompleted || isDemo) {
      return
    }

    track(EVENT.COMPLETE_ACTION, {
      ...taskUtil.getTrackingData(task),
      ...actionUtil.getTrackingData(action),
      isCompleted: isRunningAction,
    })
    setIsOpen(false)
  }, [wasCompleted, isCompleted])

  const integrationNames = useIntegrationNames()

  if (
    (!isDemo && isLoading) ||
    !taskUtil.isTaskWithContextItemsAndActions(task)
  ) {
    console.debug('Loading contexts for task %o action %O', task?.title, action)
    return <Skeleton className='h-[30px] w-32' />
  }

  // Hide if invalid
  const validAction = isDemo
    ? action
    : allContextData &&
      actionUtil.getValidAction(action, {
        integrationNames,
        task,
        allContextData,
      })
  const isValidAction =
    isDemo ||
    !validAction ||
    (allContextData &&
      isValid(validAction, {
        task,
        integrationNames,
        allContextData,
      }))
  if (!isValidAction) {
    console.error('Invalid action %o for task %o', action, task?.title)
    return null
  }
  action = {
    ...action,
    ...(validAction ?? {}),
  }

  if (
    task.type === TaskType.MASS_ARCHIVE_EMAILS ||
    task.type === TaskType.MASS_SPAM_EMAILS
  ) {
    return <TaskActionEditCard action={action} />
  }

  return (
    <Collapsible
      key={action.id}
      onOpenChange={
        !isDemo || ACTION_TYPES_WITH_RUN_AREA.includes(action.type)
          ? setIsOpen
          : () => undefined
      }
      open={isOpen}
      className='flex w-full flex-col items-start gap-1'>
      <CollapsibleTrigger
        className='flex flex-row flex-wrap items-center gap-x-4 gap-y-1'
        asChild>
        <div>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <TaskActionButton
                  state={isOpen ? 'active' : 'inactive'}
                  integrationName={action.integrationName}
                  type={action.type}
                  task={task}
                  parameters={
                    action.parameters as unknown as ActionParametersType<
                      typeof action.type
                    >
                  }
                />
              </TooltipTrigger>
              {action.reason ? (
                <TooltipContent>{action.reason}</TooltipContent>
              ) : null}
            </Tooltip>
          </TooltipProvider>
          {action.completedDate ? (
            <small className='text-success flex flex-row items-center gap-2'>
              <FontAwesomeIcon icon={faCheckCircle} /> Completed{' '}
              {dayjs(action.completedDate).fromNow()}
            </small>
          ) : null}
        </div>
      </CollapsibleTrigger>
      <CollapsibleContent className='w-full space-y-2 xl:pl-10'>
        {isCompleted ? (
          <TaskActionDetails action={action} />
        ) : (
          <TaskActionEditCard action={action} />
        )}
      </CollapsibleContent>
    </Collapsible>
  )
}
