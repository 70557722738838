import type { NotionPage } from '@/shared/constants/notion'
import { Button } from '@/web/components/ui/button'
import { CardTitle } from '@/web/components/ui/card'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { Route } from 'next'
import Link from 'next/link'

export const NotionPageCardTitle = ({ page }: { page: NotionPage }) => {
  return (
    <CardTitle className='flex flex-row items-start justify-between gap-x-2'>
      <span>{page.title}</span>
      {page.url ? (
        <Link href={(page.url ?? '') as Route} target='_blank'>
          <Button variant='ghost' theme='secondary' size='xs'>
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
          </Button>
        </Link>
      ) : null}
    </CardTitle>
  )
}
