import { type SlackMessage } from '@/shared/constants/slack'
import dayjs from '@/shared/singletons/dayjs'
import { hasContextMetadata } from '@/shared/utils/context'
import { convertMarkdownToHtml } from '@/shared/utils/html'
import { getInitials } from '@/shared/utils/string'
import { ContextContent } from '@/web/components/ContextContent'
import { Avatar, AvatarFallback, AvatarImage } from '@/web/components/ui/avatar'
import { Badge } from '@/web/components/ui/badge'
import { Button } from '@/web/components/ui/button'
import { Separator } from '@/web/components/ui/separator'
import { cn } from '@/web/libs/utils'
import { api } from '@/web/utils/api'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type Route } from 'next'
import Link from 'next/link'
import * as emoji from 'node-emoji'
import pluralize from 'pluralize'
import { useMemo } from 'react'

export const Message = ({
  message,
  conversationId,
  userIds,
}: {
  message: SlackMessage
  conversationId?: string
  userIds?: string[]
}) => {
  const html = useMemo(
    () => convertMarkdownToHtml(message.text),
    [message.text]
  )

  const getSlackMessageUrlResult = api.slack.getMessageUrl.useQuery({
    conversationId: conversationId ?? '',
    messageId: message.id,
  })

  return (
    <div className='flex flex-col gap-1' key={message.id}>
      <div className='flex flex-row items-start gap-2'>
        <Avatar className='mt-1'>
          {message.user.image ? (
            <AvatarImage
              src={message.user.image}
              alt={message.user.name ?? ''}
            />
          ) : null}
          <AvatarFallback themeIndex={userIds?.indexOf(message.user.id)}>
            {getInitials(message.user.name)}
          </AvatarFallback>
        </Avatar>
        <div className='flex flex-1 flex-col gap-1'>
          <div className='flex flex-row flex-wrap items-start justify-between gap-x-2 md:items-center'>
            <div className='flex flex-col items-start gap-x-2 md:flex-row md:items-center'>
              <p className='text-sm font-semibold'>{message.user.name}</p>
              <small className='text-muted-foreground'>
                {dayjs(message.date).fromNow()}
              </small>
              {message.isTaskOrigin ? (
                <Badge theme='primary' size='sm'>
                  Origin of this task
                </Badge>
              ) : null}
            </div>
            <Link
              href={(getSlackMessageUrlResult.data ?? '') as Route}
              target='_blank'
              className={cn(!getSlackMessageUrlResult.data && 'invisible')}>
              <Button
                variant='ghost'
                theme='secondary'
                size='xs'
                title='View in Slack'>
                {/* View in Slack */}
                <FontAwesomeIcon fixedWidth icon={faArrowUpRightFromSquare} />
              </Button>
            </Link>
          </div>
          <p
            className='text-secondary-foreground [&_*]:text-secondary-foreground [&_a]:text-primary text-sm [&_a:hover]:underline [&_ol]:list-inside [&_ol]:list-decimal [&_p]:text-sm'
            dangerouslySetInnerHTML={{
              __html: html,
            }}
          />
          {message.reactions?.length ? (
            <div className='flex flex-row items-center gap-x-2 pt-1'>
              {message.reactions.map((reaction, index) => (
                <Badge
                  key={index}
                  variant='outline'
                  className='text-muted-foreground flex flex-row items-center gap-x-2'
                  title={new Intl.ListFormat('en', { style: 'long' }).format(
                    reaction.users?.map(user => user.name ?? 'Unknown') ?? []
                  )}>
                  {emoji.get(reaction.name) ?? `:${reaction.name}:`}
                  <small>{reaction.count}</small>
                </Badge>
              ))}
            </div>
          ) : null}

          {hasContextMetadata(message) ? (
            <ContextContent contextMetadata={message} size='sm' />
          ) : null}
        </div>
      </div>
      {message.replies?.length && message.replies.length > 0 ? (
        <div className='flex flex-col gap-y-1 pl-4'>
          <div className='text-muted-foreground flex flex-row items-center gap-x-2 [text-wrap:nowrap]'>
            <small>
              {message.replies.length}{' '}
              {pluralize('reply', message.replies.length)}
            </small>
            <Separator />
          </div>
          {message.replies.map((reply, index) => (
            <Message
              key={index}
              message={reply}
              conversationId={conversationId}
              userIds={userIds}
            />
          ))}
        </div>
      ) : null}
    </div>
  )
}
