import { SCORE_THEME, SCORE_TITLE } from '@/shared/constants/score'
import { TIME_SCORE_HOUR } from '@/shared/constants/task'
import { getMinutes, getTimeScore, getTimeString } from '@/shared/utils/task'
import DurationInput from '@/web/components/DurationInput'
import { PopoverButtonWithTooltip } from '@/web/components/PopoverButtonWithTooltip'
import { ScoreText } from '@/web/components/ScoreText'
import { ScoreTitle } from '@/web/components/ScoreTitle'
import { TimeIcon } from '@/web/components/TimeIcon'
import { Button } from '@/web/components/ui/button'
import { Label } from '@/web/components/ui/label'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/web/components/ui/tooltip'
import { useTask } from '@/web/hooks/use-task'
import { useUpdateTaskMutation } from '@/web/hooks/useUpdateTaskMutation'
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type Score, type Task } from '@prisma/client'
import pluralize from 'pluralize'
import { type ComponentProps } from 'react'

export const TaskTimeButton = ({
  taskId,
  time,
  hours,
  minutes,
  timeReason,
  isAutomated,
}: Pick<Task, 'time' | 'hours' | 'minutes' | 'timeReason'> & {
  taskId: string
  isAutomated?: boolean
}) => {
  const task = useTask(taskId)
  const updateTaskMutation = useUpdateTaskMutation()

  if (task) {
    time = time ?? getTimeScore(task)
  }

  const timeString = getTimeString(task)

  return (
    <PopoverButtonWithTooltip
      tooltipContentChildren={
        time ? (
          <>
            Time: <ScoreTitle score={time} />
            {timeString ? ` (${timeString})` : ''}
          </>
        ) : (
          'Set time'
        )
      }
      getButtonProps={({ isOpen }) => ({
        variant: 'ghost',
        size: 'sm',
        state: isOpen ? 'active' : 'selected',
        theme: time
          ? (SCORE_THEME[time] as ComponentProps<typeof Button>['theme'])
          : 'secondary',
        children: <TimeIcon time={time} />,
      })}
      popoverContentChildren={
        <>
          <Label className='flex flex-row items-center gap-2'>
            Time
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger className='text-muted-foreground flex items-center gap-2 text-xs'>
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </TooltipTrigger>
                <TooltipContent>
                  The time required to complete the task. If left unset, the
                  time is estimated using the task’s details. Default values are
                  LOW = {TIME_SCORE_HOUR.LOW}{' '}
                  {pluralize('hour', TIME_SCORE_HOUR.LOW)}, MEDIUM ={' '}
                  {TIME_SCORE_HOUR.MEDIUM}{' '}
                  {pluralize('hour', TIME_SCORE_HOUR.MEDIUM)} and HIGH ={' '}
                  {TIME_SCORE_HOUR.HIGH}{' '}
                  {pluralize('hour', TIME_SCORE_HOUR.HIGH)}.
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </Label>
          <div className='flex gap-2'>
            {(Object.keys(SCORE_THEME) as Array<Score | null>)
              .concat(null)
              .map((timeOption, index) => (
                <Button
                  key={index}
                  variant='ghost'
                  size='sm'
                  theme={
                    timeOption
                      ? (SCORE_THEME[timeOption] as ComponentProps<
                          typeof Button
                        >['theme'])
                      : 'secondary'
                  }
                  title={`${timeOption ? SCORE_TITLE[timeOption] : 'No'} time`}
                  onClick={() => {
                    if (!task) {
                      return
                    }
                    updateTaskMutation.mutate({
                      id: task.id,
                      time: timeOption,
                      hours: timeOption
                        ? Math.floor(TIME_SCORE_HOUR[timeOption])
                        : null,
                      minutes: timeOption
                        ? Math.floor((TIME_SCORE_HOUR[timeOption] * 60) % 60)
                        : null,
                    })
                  }}
                  state={timeOption === time ? 'active' : 'selected'}>
                  <TimeIcon time={timeOption} />
                </Button>
              ))}
          </div>

          <div className='flex flex-row items-center gap-2'>
            <Label size='sm' htmlFor='hoursAndMinutes'>
              Duration:
            </Label>
            <DurationInput
              id='hoursAndMinutes'
              defaultMinutes={getMinutes(task)}
              onChangeDuration={totalMinutes => {
                if (!task) {
                  return
                }
                const hours = Math.floor(totalMinutes / 60) ?? 0
                const minutes = totalMinutes % 60 ?? 0
                const time = getTimeScore({
                  ...task,
                  hours,
                  minutes,
                })
                updateTaskMutation.mutate({
                  id: task?.id,
                  hours,
                  minutes,
                  time,
                })
              }}
              size='sm'
              className='w-[60px]'
            />
          </div>
          {(hours != null || minutes != null) && isAutomated && (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger className='text-muted-foreground flex items-center gap-2 text-xs'>
                  <FontAwesomeIcon icon={faWandMagicSparkles} /> Estimated by
                  Pulse
                </TooltipTrigger>
                <TooltipContent className='flex flex-col gap-2'>
                  <Label size='lg'>
                    Time:{' '}
                    <ScoreText score={time}>
                      {hours
                        ? `${hours ?? 0} ${pluralize('hour', hours ?? 0)}`
                        : null}
                      {hours && minutes ? ' and ' : null}
                      {minutes
                        ? `${minutes} ${pluralize('minute', minutes)}`
                        : null}
                    </ScoreText>
                  </Label>
                  {timeReason ? (
                    <div className='text-muted-foreground'>{timeReason}</div>
                  ) : null}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
        </>
      }
    />
  )
}
