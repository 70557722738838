import { getInitials } from '@/shared/utils/string'
import { Avatar, AvatarFallback, AvatarImage } from '@/web/components/ui/avatar'
import { type ComponentProps } from 'react'

export function Avatars({
  users,
  max = 3,
  size = 'sm',
}: {
  users: { name?: string; image?: string }[]
  max?: number
  size?: ComponentProps<typeof Avatar>['size']
}) {
  const usedInitials: string[] = []
  return (
    <div className='flex flex-row -space-x-1'>
      {users.slice(0, max).map(({ name, image }, index) => {
        const initials = getInitials(name, usedInitials)
        usedInitials.push(initials ?? '')

        return (
          <Avatar
            key={index}
            size={size}
            title={name}
            className={`drop-shadow [z-index:${index}]`}>
            {image ? <AvatarImage src={image} alt={name ?? ''} /> : null}
            <AvatarFallback themeIndex={index}>{initials ?? ''}</AvatarFallback>
          </Avatar>
        )
      })}
      {users.length > max ? (
        <Avatar
          size={size}
          className='drop-shadow'
          title={`${users.length - max} more`}>
          <AvatarFallback themeIndex={max} className='[font-size:0.625rem]'>
            +{users.length - max}
          </AvatarFallback>
        </Avatar>
      ) : null}
    </div>
  )
}
