import { type SlackConversation } from '@/shared/constants/slack'
import { getFullConversationName, getMessageCount } from '@/shared/utils/slack'
import { Avatars } from '@/web/components/Avatars'
import { Messages } from '@/web/components/Messages'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/web/components/ui/collapsible'
import { Label } from '@/web/components/ui/label'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import pluralize from 'pluralize'
import React from 'react'

export const Conversation = ({
  conversation,
  isDefaultOpen = true,
  isAlwaysOpen,
}: {
  conversation: SlackConversation
  isDefaultOpen?: boolean
  isAlwaysOpen?: boolean
}) => {
  const messageCount = getMessageCount(conversation.messages)
  const [isOpen, setIsOpen] = React.useState(isDefaultOpen || isAlwaysOpen)
  return (
    <Collapsible
      className='flex flex-col'
      onOpenChange={setIsOpen}
      defaultOpen={isDefaultOpen}>
      <CollapsibleTrigger
        className='group flex flex-row items-center justify-between gap-2'
        disabled={isAlwaysOpen}>
        <Label className='text-secondary-foreground group-hover:text-primary flex flex-col items-start gap-1 md:flex-row md:items-center md:gap-2'>
          {getFullConversationName({
            name: conversation.name,
            type: conversation.type,
          })}
          {messageCount ? (
            <small className='text-muted-foreground group-hover:text-primary'>
              {messageCount} {pluralize('message', messageCount)}
            </small>
          ) : null}
        </Label>
        <div className='group-hover:text-primary text-muted-foreground flex flex-row items-center gap-2'>
          <Avatars
            users={_.uniqWith(
              conversation.messages?.flatMap(message => ({
                name: message.user.name,
                image: message.user.image,
              })),
              _.isEqual
            )}
          />
          <small>
            {conversation.memberCount}{' '}
            {pluralize('member', conversation.memberCount)}
          </small>
          {!isAlwaysOpen ? (
            <FontAwesomeIcon
              icon={isOpen ? faCaretUp : faCaretDown}
              fixedWidth
            />
          ) : null}
        </div>
      </CollapsibleTrigger>
      <CollapsibleContent className='border-l-border flex flex-col gap-1 border-l pl-4 pt-2'>
        <Messages
          messages={conversation.messages}
          conversationId={conversation.id}
          userIds={conversation.memberIds}
        />
      </CollapsibleContent>
    </Collapsible>
  )
}
