'use client'

import { type GmailEmailType } from '@/shared/constants/gmail'
import { MASS_EMAIL_TASK_TYPES } from '@/shared/constants/task'
import {
  archiveEmail,
  isArchivedEmail,
  isInboxEmail,
  isSpamEmail,
  isUnreadEmail,
  markNotSpamEmail,
  moveEmailToInbox,
  reportSpamEmail,
} from '@/shared/utils/gmail'
import { isComplete, isTodo } from '@/shared/utils/task'
import { CollapsibleCaret } from '@/web/components/CollapsibleCaret'
import { EmailSubject } from '@/web/components/EmailSubject'
import { EmailThreadMessageFromCcBcc } from '@/web/components/EmailThreadMessageFromCcBcc'
import { RawEmailWithAttachments } from '@/web/components/RawEmailWithAttachments'
import { Button, type ButtonProps } from '@/web/components/ui/button'
import { Checkbox } from '@/web/components/ui/checkbox'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/web/components/ui/dropdown-menu'
import { Input } from '@/web/components/ui/input'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/web/components/ui/table'
import { TaskContext } from '@/web/contexts/task'
import { cn } from '@/web/libs/utils'
import { api } from '@/web/utils/api'
import { updateInfiniteData } from '@/web/utils/trpc'
import {
  faArrowDown,
  faArrowsUpDown,
  faArrowUp,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TaskStatus } from '@prisma/client'
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  type Column,
  type ColumnDef,
  type ExpandedState,
  type GlobalFilterTableState,
  type OnChangeFn,
  type RowData,
  type RowSelectionState,
  type SortingState,
} from '@tanstack/react-table'
import { useVirtualizer } from '@tanstack/react-virtual'
import { TRPCClientError } from '@trpc/client'
import _ from 'lodash'
import {
  Archive,
  ArchiveRestore,
  MoreHorizontal,
  ShieldAlert,
  ShieldCheck,
  Wand2,
} from 'lucide-react'
import pluralize from 'pluralize'
import * as React from 'react'
import { toast } from 'sonner'
import { TableCellIconButton } from './TableCellIconButton'

const limit = 5

declare module '@tanstack/table-core' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface TableMeta<TData extends RowData> {
    emailIds: string[]
  }
}

type EmailRow = {
  id: string
  from: string
  subject: string
  summary?: string
  date: Date
  email: GmailEmailType
}

function TableHeaderButton({
  isSorting = true,
  column,
  children,
  ...props
}: React.ComponentProps<typeof Button> & {
  isSorting?: boolean
  column?: Column<EmailRow>
  children: React.ReactNode
}) {
  return (
    <Button
      variant='link'
      theme='secondary'
      size='none'
      onClick={
        isSorting && column
          ? () => column.toggleSorting(column.getIsSorted() === 'asc')
          : undefined
      }
      {...props}
      className={cn('justify-start', props.className)}>
      {children}
      {isSorting && column ? (
        <FontAwesomeIcon
          size='xs'
          icon={
            column.getIsSorted()
              ? column.getIsSorted() === 'asc'
                ? faArrowUp
                : faArrowDown
              : faArrowsUpDown
          }
        />
      ) : null}
    </Button>
  )
}

const columns: ColumnDef<EmailRow>[] = [
  {
    id: 'select',
    size: 20,
    header: ({ table }) => {
      const inboxRows = table
        .getCoreRowModel()
        .rows.filter(row => isInboxEmail(row.original.email))
      const areAllInboxRowsSelected = inboxRows.every(row =>
        row.getIsSelected()
      )

      return (
        <Checkbox
          checked={
            table.getIsAllPageRowsSelected() ||
            (table.getIsSomePageRowsSelected() && 'indeterminate')
          }
          onCheckedChange={() =>
            !areAllInboxRowsSelected
              ? inboxRows.forEach(row => row.toggleSelected(true))
              : table.toggleAllPageRowsSelected(false)
          }
          aria-label='Select all'
        />
      )
    },
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={value => row.toggleSelected(!!value)}
        aria-label='Select row'
        className='mt-0.5 block'
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'from',
    minSize: 150,
    header: ({ column }) => {
      return <TableHeaderButton column={column}>From</TableHeaderButton>
    },
    cell: ({ row }) => (
      <EmailThreadMessageFromCcBcc
        className='break-words'
        email={row.original.email}
        isExcludingTime
        isTruncating
      />
    ),
  },
  {
    accessorKey: 'subject',
    minSize: 400,
    maxSize: 600,
    header: ({ column }) => {
      return <TableHeaderButton column={column}>Email</TableHeaderButton>
    },
    cell: ({ row }) => (
      <div className='flex flex-col gap-1'>
        <EmailSubject
          subject={row.original.subject}
          emails={[row.original.email]}
        />
        {row.original.summary ? (
          <small className='text-muted-foreground text-sm'>
            <Wand2 className='inline h-3.5 w-3.5 opacity-50' />{' '}
            {row.original.summary}
          </small>
        ) : null}
      </div>
    ),
  },
  {
    size: 55,
    accessorKey: 'date',
    header: ({ column }) => {
      return <TableHeaderButton column={column}>Date</TableHeaderButton>
    },
    cell: ({ row }) => (
      <small className='text-muted-foreground text-nowrap'>
        {new Intl.DateTimeFormat('en-US', { dateStyle: 'short' }).format(
          row.original.date
        )}
        <br />
        {new Intl.DateTimeFormat('en-US', { timeStyle: 'short' }).format(
          row.original.date
        )}
      </small>
    ),
  },
  {
    id: 'actions',
    size: 32,
    enableHiding: false,
    header: ({ table }) => {
      const selectedEmails = table
        .getSelectedRowModel()
        .rows.map(row => row.original.email)
      const selectedEmailIds = table
        .getSelectedRowModel()
        .rows.map(row => row.id)

      const utils = api.useUtils()

      const onMutate = (
        emailIds: string[],
        updateEmail: (email: GmailEmailType) => GmailEmailType
      ) => {
        table.toggleAllRowsSelected(false)

        utils.gmail.loadEmailsInfinite.setInfiniteData(
          {
            emailIds: table.options.meta?.emailIds ?? [],
            limit,
          },
          data =>
            updateInfiniteData(data, item => {
              if (item.email && emailIds.includes(item.email.id)) {
                return {
                  ...item,
                  email: updateEmail(item.email),
                }
              }
              return item
            })
        )
      }
      const onError = (err: unknown) => {
        toast.error('Error managing emails', {
          description: err instanceof TRPCClientError ? err.message : undefined,
        })
        utils.gmail.loadEmailsInfinite.invalidate({
          emailIds: table.options.meta?.emailIds ?? [],
          limit,
        })
      }

      const archiveEmailsMutationResult = api.gmail.archiveEmails.useMutation({
        onMutate: ({ emailIds }) => {
          onMutate(emailIds, archiveEmail)
        },
        onError,
      })
      const inboxEmailsMutationResult = api.gmail.inboxEmails.useMutation({
        onMutate: ({ emailIds }) => {
          onMutate(emailIds, moveEmailToInbox)
        },
        onError,
      })
      const reportSpamEmailsMutationResult =
        api.gmail.reportSpamEmails.useMutation({
          onMutate: ({ emailIds }) => {
            onMutate(emailIds, reportSpamEmail)
          },
          onError,
        })
      const markNotSpamEmailsMutationResult =
        api.gmail.markNotSpamEmails.useMutation({
          onMutate: ({ emailIds }) => {
            onMutate(emailIds, markNotSpamEmail)
          },
          onError,
        })

      const isLoading =
        inboxEmailsMutationResult.isLoading ||
        archiveEmailsMutationResult.isLoading ||
        reportSpamEmailsMutationResult.isLoading ||
        markNotSpamEmailsMutationResult.isLoading

      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            {/* HACK: @see https://stackoverflow.com/questions/75316590/radix-ui-react-dropdown-menu-content-is-displayed-incorrectly */}
            <div>
              <TableCellIconButton>
                <span className='sr-only'>Open menu</span>
                <MoreHorizontal className='h-4 w-4' />
              </TableCellIconButton>
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent align='end' className='w-56'>
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              theme='success'
              disabled={
                isLoading ||
                selectedEmailIds.length === 0 ||
                !selectedEmails.some(isInboxEmail)
              }
              onClick={e => {
                e.stopPropagation()
                archiveEmailsMutationResult.mutate({
                  emailIds: selectedEmailIds,
                })
              }}>
              <Archive className='mr-2 h-4 w-4' />
              <span>
                Archive {selectedEmailIds.length}{' '}
                {pluralize('email', selectedEmailIds.length)}
              </span>
            </DropdownMenuItem>
            <DropdownMenuItem
              theme='destructive'
              disabled={
                isLoading || !selectedEmails.some(email => !isSpamEmail(email))
              }
              onClick={e => {
                e.stopPropagation()
                reportSpamEmailsMutationResult.mutate({
                  emailIds: selectedEmailIds,
                })
              }}>
              <ShieldAlert className='mr-2 h-4 w-4' />
              <span>
                Report {selectedEmailIds.length}{' '}
                {pluralize('email', selectedEmailIds.length)} as spam
              </span>
            </DropdownMenuItem>
            <DropdownMenuItem
              disabled={
                isLoading ||
                selectedEmailIds.length === 0 ||
                !selectedEmails.some(isArchivedEmail)
              }
              onClick={e => {
                e.stopPropagation()
                inboxEmailsMutationResult.mutate({ emailIds: selectedEmailIds })
              }}>
              <ArchiveRestore className='mr-2 h-4 w-4' />
              <span>
                Move {selectedEmailIds.length}{' '}
                {pluralize('email', selectedEmailIds.length)} to inbox
              </span>
            </DropdownMenuItem>
            <DropdownMenuItem
              disabled={isLoading || !selectedEmails.some(isSpamEmail)}
              onClick={e => {
                e.stopPropagation()
                markNotSpamEmailsMutationResult.mutate({
                  emailIds: selectedEmailIds,
                })
              }}>
              <ShieldCheck className='mr-2 h-4 w-4' />
              <span>
                Mark {selectedEmailIds.length}{' '}
                {pluralize('email', selectedEmailIds.length)} as not spam
              </span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )
    },
    cell: ({ row, table }) => {
      const emailId = row.original.id

      const utils = api.useUtils()

      const onMutate = (
        emailId: string,
        updateLabels: (labels: string[]) => string[]
      ) => {
        utils.gmail.loadEmailsInfinite.setInfiniteData(
          {
            emailIds: table.options.meta?.emailIds ?? [],
            limit,
          },
          data =>
            updateInfiniteData(data, item => {
              if (item.email && emailId === item.email.id) {
                return {
                  ...item,
                  email: {
                    ...item.email,
                    labels: updateLabels(item.email.labels),
                  },
                }
              }
              return item
            })
        )
      }
      const onError = (err: unknown) => {
        toast.error('Error managing emails', {
          description: err instanceof TRPCClientError ? err.message : undefined,
        })
        utils.gmail.loadEmailsInfinite.invalidate({
          emailIds: table.options.meta?.emailIds ?? [],
          limit,
        })
      }

      const archiveEmailMutationResult = api.gmail.archiveEmail.useMutation({
        onMutate: ({ emailId }) => {
          onMutate(emailId, labels => _.without(labels, 'INBOX'))
        },
        onError,
      })
      const inboxEmailMutationResult = api.gmail.inboxEmail.useMutation({
        onMutate: ({ emailId }) => {
          onMutate(emailId, labels => _.uniq([...labels, 'INBOX']))
        },
        onError,
      })
      const reportSpamEmailMutationResult =
        api.gmail.reportSpamEmail.useMutation({
          onMutate: ({ emailId }) => {
            onMutate(emailId, labels => _.uniq([...labels, 'SPAM']))
          },
          onError,
        })
      const markNotSpamEmailMutationResult =
        api.gmail.markNotSpamEmail.useMutation({
          onMutate: ({ emailId }) => {
            onMutate(emailId, labels => _.without(labels, 'SPAM'))
          },
          onError,
        })
      const isLoading =
        inboxEmailMutationResult.isLoading ||
        archiveEmailMutationResult.isLoading ||
        reportSpamEmailMutationResult.isLoading ||
        markNotSpamEmailMutationResult.isLoading

      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            {/* HACK: @see https://stackoverflow.com/questions/75316590/radix-ui-react-dropdown-menu-content-is-displayed-incorrectly */}
            <div>
              <TableCellIconButton>
                <span className='sr-only'>Open menu</span>
                <MoreHorizontal className='h-4 w-4' />
              </TableCellIconButton>
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent align='end' className='w-56'>
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              disabled={isLoading}
              theme={isInboxEmail(row.original.email) ? 'success' : 'default'}
              onClick={e => {
                e.stopPropagation()

                isInboxEmail(row.original.email)
                  ? archiveEmailMutationResult.mutate({ emailId })
                  : inboxEmailMutationResult.mutate({ emailId })
              }}>
              {isInboxEmail(row.original.email) ? (
                <Archive className='mr-2 h-4 w-4' />
              ) : (
                <ArchiveRestore className='mr-2 h-4 w-4' />
              )}
              <span>
                {isInboxEmail(row.original.email) ? 'Archive' : 'Move to Inbox'}
              </span>
            </DropdownMenuItem>
            <DropdownMenuItem
              theme={
                isSpamEmail(row.original.email) ? 'default' : 'destructive'
              }
              disabled={isLoading}
              onClick={e => {
                e.stopPropagation()
                if (isSpamEmail(row.original.email)) {
                  markNotSpamEmailMutationResult.mutate({ emailId })
                } else {
                  reportSpamEmailMutationResult.mutate({ emailId })
                }
              }}>
              {isSpamEmail(row.original.email) ? (
                <ShieldCheck className='mr-2 h-4 w-4' />
              ) : (
                <ShieldAlert className='mr-2 h-4 w-4' />
              )}
              <span>
                {isSpamEmail(row.original.email)
                  ? 'Mark as Not Spam'
                  : 'Report Spam'}
              </span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )
    },
  },
  {
    accessorKey: 'expanded',
    size: 40,
    header: ({ table }) => (
      <TableCellIconButton onClick={table.getToggleAllRowsExpandedHandler()}>
        <CollapsibleCaret isOpen={table.getIsSomeRowsExpanded()} />
      </TableCellIconButton>
    ),
    cell: ({ row }) => (
      <TableCellIconButton
        onClick={e => {
          e.stopPropagation()
          row.toggleExpanded()
        }}>
        <CollapsibleCaret isOpen={row.getIsExpanded()} />
      </TableCellIconButton>
    ),
  },
]

interface MassActionButtonProps extends Omit<ButtonProps, 'onClick' | 'type'> {
  type: 'archive' | 'spam'
  onClick: () => void
  isLoading: boolean
  emailCount: number
}

const MassActionButton: React.FC<MassActionButtonProps> = ({
  type,
  onClick,
  isLoading,
  emailCount,
  ...props
}) => {
  return (
    <Button
      variant='default'
      size='sm'
      disabled={isLoading || !emailCount}
      state={isLoading ? 'loading' : undefined}
      onClick={onClick}
      {...props}>
      {type === 'archive'
        ? `Archive ${emailCount} ${pluralize('email', emailCount)}`
        : `Report ${emailCount} spam ${pluralize('email', emailCount)}`}
    </Button>
  )
}

export function EmailsTable({
  emailIds,
  actionId,
  taskId,
  type,
}: {
  emailIds: string[]
  actionId: string
  taskId: string
  type: 'archive' | 'spam'
}) {
  const task = React.useContext(TaskContext)

  const [sorting, setSorting] = React.useState<SortingState>([])
  const [globalFilter, setGlobalFilter] = React.useState<
    GlobalFilterTableState | undefined
  >()
  const [expanded, setExpanded] = React.useState<ExpandedState>({})

  const loadEmailsInfiniteInput = React.useMemo(
    () => ({
      emailIds,
      limit,
    }),
    [emailIds]
  )
  const loadEmailsInfiniteQueryResult =
    api.gmail.loadEmailsInfinite.useInfiniteQuery(loadEmailsInfiniteInput, {
      getNextPageParam: lastPage => lastPage.nextCursor,
    })
  const data: EmailRow[] = React.useMemo(
    () =>
      (loadEmailsInfiniteQueryResult.data?.pages
        ?.flatMap(({ items }) =>
          items.map(({ email }) =>
            email
              ? ({
                  id: email.id,
                  from: email.from,
                  subject: email.subject,
                  summary: email.summary,
                  date: new Date(email.date),
                  email,
                } satisfies EmailRow)
              : null
          )
        )
        .filter(Boolean) as EmailRow[]) ?? [],
    [loadEmailsInfiniteQueryResult.data?.pages]
  )

  const totalFetched = data.length

  //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = React.useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (!containerRefElement) {
        console.log('No container ref')
        return
      }

      const { scrollHeight, scrollTop, clientHeight } = containerRefElement
      //once the user has scrolled within 500px of the bottom of the table, fetch more data if we can
      if (
        scrollHeight - scrollTop - clientHeight < 500 &&
        !loadEmailsInfiniteQueryResult.isFetching &&
        totalFetched < emailIds.length &&
        loadEmailsInfiniteQueryResult.hasNextPage
      ) {
        console.log(
          'Fetching next page',
          loadEmailsInfiniteQueryResult.data?.pages.length
        )
        loadEmailsInfiniteQueryResult.fetchNextPage()
      }
    },
    [
      loadEmailsInfiniteQueryResult.fetchNextPage,
      loadEmailsInfiniteQueryResult.isFetching,
      totalFetched,
      emailIds.length,
    ]
  )

  //a check on mount and after a fetch to see if the table is already scrolled to the bottom and immediately needs to fetch more data
  React.useEffect(() => {
    console.log('Checking if table is scrolled to bottom', tableContainerRef)
    fetchMoreOnBottomReached(tableContainerRef.current)
  }, [fetchMoreOnBottomReached])

  // Select all by default
  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({})

  // Complete the action if all emails are not in the inbox
  const completeActionMutationResult = api.action.complete.useMutation()
  const updateTaskMutationResult = api.task.update.useMutation()
  React.useEffect(() => {
    if (
      loadEmailsInfiniteQueryResult.isLoading ||
      loadEmailsInfiniteQueryResult.hasNextPage ||
      data.some(({ email }) => isInboxEmail(email)) ||
      !completeActionMutationResult.isIdle ||
      !updateTaskMutationResult.isIdle ||
      isComplete(task)
    ) {
      console.log('Not completing email table action', actionId, {
        loadEmailsInfiniteQueryResult,
        data,
        completeActionMutationResult,
        updateTaskMutationResult,
      })
      return
    }

    console.log('Completing email table action', actionId)
    completeActionMutationResult.mutate(actionId)

    if (
      isTodo(task) &&
      task?.type &&
      MASS_EMAIL_TASK_TYPES.includes(task.type)
    ) {
      updateTaskMutationResult.mutate({
        id: taskId,
        status: TaskStatus.COMPLETED,
      })
    }
  }, [data, actionId, completeActionMutationResult])

  const utils = api.useUtils()
  const archiveEmailsMutationResult = api.gmail.archiveEmails.useMutation({
    onMutate: ({ emailIds }) => {
      table.toggleAllRowsSelected(false)

      utils.gmail.loadEmailsInfinite.setInfiniteData(
        loadEmailsInfiniteInput,
        data =>
          updateInfiniteData(data, item => {
            if (item.email && emailIds.includes(item.email.id)) {
              return {
                ...item,
                email: archiveEmail(item.email),
              }
            }
            return item
          })
      )
    },
    onError(err) {
      toast.error('Error archiving emails', {
        description: err instanceof Error ? err.message : undefined,
      })

      utils.gmail.loadEmailsInfinite.invalidate(loadEmailsInfiniteInput)
    },
  })

  const reportSpamEmailsMutationResult = api.gmail.reportSpamEmails.useMutation(
    {
      onMutate: ({ emailIds }) => {
        table.toggleAllRowsSelected(false)

        utils.gmail.loadEmailsInfinite.setInfiniteData(
          loadEmailsInfiniteInput,
          data =>
            updateInfiniteData(data, item => {
              if (item.email && emailIds.includes(item.email.id)) {
                return {
                  ...item,
                  email: reportSpamEmail(item.email),
                }
              }
              return item
            })
        )
      },
      onError(err) {
        toast.error('Error archiving emails', {
          description: err instanceof Error ? err.message : undefined,
        })

        utils.gmail.loadEmailsInfinite.invalidate(loadEmailsInfiniteInput)
      },
    }
  )

  const table = useReactTable({
    data,
    columns,
    enableGlobalFilter: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onExpandedChange: setExpanded,
    onGlobalFilterChange: setGlobalFilter,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    getRowId(originalRow) {
      return originalRow.id
    },
    meta: {
      emailIds,
    },
    state: {
      sorting,
      rowSelection,
      expanded,
      globalFilter,
    },
  })

  const selectedEmailIds = table.getSelectedRowModel().rows.map(row => row.id)
  const areButtonsLoading =
    archiveEmailsMutationResult.isLoading ||
    reportSpamEmailsMutationResult.isLoading

  const mainMassActionButton = (
    <MassActionButton
      type={type}
      emailCount={selectedEmailIds.length}
      isLoading={areButtonsLoading}
      onClick={() =>
        (type === 'archive'
          ? archiveEmailsMutationResult
          : reportSpamEmailsMutationResult
        ).mutate({ emailIds: selectedEmailIds })
      }
      variant='default'
      theme={type === 'archive' ? 'success' : 'destructive'}
    />
  )
  const secondaryMassActionButton = (
    <MassActionButton
      type={type === 'archive' ? 'spam' : 'archive'}
      emailCount={selectedEmailIds.length}
      isLoading={areButtonsLoading}
      onClick={() =>
        (type === 'archive'
          ? reportSpamEmailsMutationResult
          : archiveEmailsMutationResult
        ).mutate({ emailIds: selectedEmailIds })
      }
      variant='outline'
      theme={type === 'archive' ? 'destructive' : 'success'}
    />
  )

  const tableContainerRef = React.useRef<HTMLDivElement>(null)

  const rowHeights = React.useRef<Record<string, number>>({})
  const measureEmailRowHeight = React.useCallback(
    (element: HTMLTableRowElement) => {
      if (!element) return 120

      const baseHeightCache = element.getAttribute('data-height')

      const isExpanded = element?.getAttribute('data-expanded') === 'expanded'
      const expandedHeightCache = element.getAttribute('data-expanded-height')

      if (baseHeightCache && (!isExpanded || expandedHeightCache)) {
        return (
          parseInt(baseHeightCache) +
          (isExpanded ? parseInt(expandedHeightCache ?? '0') : 0)
        )
      }

      const baseHeight = element?.getBoundingClientRect().height
      element.setAttribute('data-height', `${baseHeight}`)
      rowHeights.current[element.id] = baseHeight

      if (isExpanded) {
        const expandedHeight =
          element.nextElementSibling?.getBoundingClientRect().height ?? 0
        if (expandedHeight) {
          element.setAttribute('data-expanded-height', `${expandedHeight}`)
        }
        return baseHeight + expandedHeight
      }

      return baseHeight
    },
    []
  )

  const rowVirtualizer = useVirtualizer({
    count: emailIds.length, // table.getRowModel().rows.length,
    estimateSize: () => 120,
    getScrollElement: () => tableContainerRef.current,
    //measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== 'undefined' &&
      navigator.userAgent.indexOf('Firefox') === -1
        ? measureEmailRowHeight
        : undefined,
    overscan: 5,
  })

  //scroll to top of table when sorting changes
  const handleSortingChange: OnChangeFn<SortingState> = updater => {
    setSorting(updater)
    if (!!table.getRowModel().rows.length) {
      rowVirtualizer.scrollToIndex?.(0)
    }
  }
  //since this table option is derived from table row model state, we're using the table.setOptions utility
  table.setOptions(prev => ({
    ...prev,
    onSortingChange: handleSortingChange,
  }))

  return (
    <div className='w-full'>
      <div className='flex items-center py-1'>
        <Input
          placeholder='Filter emails...'
          value={(table.getColumn('from')?.getFilterValue() as string) ?? ''}
          onChange={event =>
            table.getColumn('from')?.setFilterValue(event.target.value)
          }
          className='max-w-sm rounded-sm'
          autoComplete='off'
          type='search'
          size='sm'
        />
      </div>
      <div
        className='border-border relative h-auto max-h-[min(80dvh,50cqh)] overflow-auto rounded-sm border'
        ref={tableContainerRef}
        onScroll={e => fetchMoreOnBottomReached(e.target as HTMLDivElement)}>
        <Table className='grid'>
          <TableHeader className='sticky top-0 z-10 grid backdrop-blur-md'>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id} isHeader className='flex w-full'>
                {headerGroup.headers.map(header => {
                  return (
                    <TableHead
                      key={header.id}
                      className='flex items-center'
                      style={{
                        minWidth: header.getSize(),
                        width: `${header.getSize()}%`,
                      }}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody
            className='relative grid'
            style={{
              height: loadEmailsInfiniteQueryResult.isInitialLoading
                ? 'auto'
                : `${rowVirtualizer.getTotalSize()}px`, // This should always be a `style` as it changes on scroll
            }}>
            {table.getRowModel().rows.length ? (
              rowVirtualizer.getVirtualItems().map(virtualRow => {
                const row = table.getRowModel().rows[virtualRow.index]
                if (!row) {
                  return
                }

                const className = cn(
                  'absolute flex w-full',
                  isArchivedEmail(row.original.email) &&
                    'bg-success/10 opacity-50',
                  isSpamEmail(row.original.email) &&
                    'bg-destructive/10 opacity-50',
                  isUnreadEmail(row.original.email) && 'bg-primary/5'
                )

                return (
                  <React.Fragment key={row.id}>
                    <TableRow
                      key={row.id}
                      id={row.id}
                      data-index={virtualRow.index} //needed for dynamic row height measurement
                      ref={node => rowVirtualizer.measureElement(node)} //measure dynamic row height
                      data-state={row.getIsSelected() && 'selected'}
                      data-expanded={row.getIsExpanded() && 'expanded'}
                      onClick={row.getToggleSelectedHandler()}
                      className={cn(
                        className,
                        row.getIsExpanded() &&
                          // FIXME: Uncaught (in promise) SyntaxError: Failed to execute 'matches' on 'Element': '.has-\[\+_\\]\:bg-muted\/50:has(+ :hover)' is not a valid selector.
                          'has-[+_:hover]:bg-muted/50 border-b-0',
                        'cursor-pointer'
                      )}
                      style={{
                        transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                      }}>
                      {row.getVisibleCells().map(cell => (
                        <TableCell
                          key={cell.id}
                          className='flex items-start'
                          style={{
                            minWidth: cell.column.getSize(),
                            width: `${cell.column.getSize()}%`,
                          }}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                    {row.getIsExpanded() && (
                      <TableRow
                        key={row.id + 'expanded'}
                        className={cn('[:hover_+_&]:bg-muted/50', className)}
                        style={{
                          transform: `translateY(${
                            virtualRow.start +
                            (rowHeights?.current?.[row.id] ?? 0)
                          }px)`, //this should always be a `style` as it changes on scroll
                        }}>
                        <TableCell colSpan={columns.length} className='w-full'>
                          <RawEmailWithAttachments email={row.original.email} />
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                )
              })
            ) : (
              <TableRow
                key='no-results'
                className='flex w-full items-center justify-center p-8'>
                <TableCell
                  colSpan={columns.length}
                  className={cn(
                    'flex h-full w-full items-center justify-center text-center',
                    loadEmailsInfiniteQueryResult.isLoading && 'animate-pulse'
                  )}>
                  {loadEmailsInfiniteQueryResult.isLoading
                    ? 'Loading...'
                    : loadEmailsInfiniteQueryResult.isError
                    ? loadEmailsInfiniteQueryResult.error.message
                    : 'No results.'}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className='flex items-center justify-between gap-2 py-4'>
        <div className='flex flex-row gap-2'>
          {mainMassActionButton}
          {secondaryMassActionButton}
        </div>
        <div className='text-muted-foreground text-sm'>
          {table.getFilteredSelectedRowModel().rows.length} of{' '}
          {table.getFilteredRowModel().rows.length}{' '}
          {pluralize('row', table.getFilteredRowModel().rows.length)} selected
        </div>
      </div>
    </div>
  )
}
