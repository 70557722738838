import { hasContext } from '@/shared/utils/context'
import { CollapsibleCaret } from '@/web/components/CollapsibleCaret'
import { GmailTaskContext } from '@/web/components/GmailTaskContext'
import { GoogleCalendarTaskContext } from '@/web/components/GoogleCalendarTaskContext'
import { NotionTaskContext } from '@/web/components/NotionTaskContext'
import { SlackTaskContext } from '@/web/components/SlackTaskContext'
import { TaskHistoryTable } from '@/web/components/TaskHistory'
import { Card, CardDescription } from '@/web/components/ui/card'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/web/components/ui/collapsible'
import { TaskContext } from '@/web/contexts/task'
import { env } from '@/web/env'
import { faPencil } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Role } from '@prisma/client'
import { useSession } from 'next-auth/react'
import React, { useContext } from 'react'

export const TaskCardContext = () => {
  const [isShowingHistory, setIsShowingHistory] = React.useState(false)

  const task = useContext(TaskContext)

  const session = useSession()
  if (!task) {
    return null
  }

  const isShowingTaskHistory =
    env.NEXT_PUBLIC_NODE_ENV === 'development' ||
    session.data?.user.role === Role.ADMIN
  if (!hasContext(task) && !isShowingTaskHistory) {
    return null
  }

  return (
    <div className='flex flex-col gap-2 break-words'>
      <SlackTaskContext />
      <GmailTaskContext />
      <GoogleCalendarTaskContext />
      <NotionTaskContext />
      {isShowingTaskHistory ? (
        <CardDescription key={'history' + task.id}>
          <Collapsible onOpenChange={isOpen => setIsShowingHistory(isOpen)}>
            <CollapsibleTrigger className='flex flex-row items-center gap-2'>
              <FontAwesomeIcon fixedWidth icon={faPencil} />
              View changelog
              <CollapsibleCaret isOpen={isShowingHistory} />
            </CollapsibleTrigger>
            <CollapsibleContent className='pt-1'>
              <Card
                key={task.id}
                variant='secondary'
                className='divide-border divide-y px-4 py-2'>
                <TaskHistoryTable />
              </Card>
            </CollapsibleContent>
          </Collapsible>
        </CardDescription>
      ) : null}
    </div>
  )
}
