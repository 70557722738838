import {
  type ReactQueryOptions,
  type RouterInputs,
  type RouterOutputs,
} from '@/web/utils/api'
import { useTaskQueryResult } from './use-task-query-result'

export const useTask: (
  id: RouterInputs['task']['get'],
  options?: ReactQueryOptions['task']['get']
) => RouterOutputs['task']['get'] = (id, options) => {
  return (
    (useTaskQueryResult(id, options)?.data as RouterOutputs['task']['get']) ??
    null
  )
}
