import { type GmailEmailType } from '@/shared/constants/gmail'
import dayjs from '@/shared/singletons/dayjs'
import { getContact } from '@/shared/utils/gmail'
import { cn } from '@/web/libs/utils'

export const EmailThreadMessageFromCcBcc = ({
  email,
  className,
  isExcludingTime,
  isTruncating,
}: {
  email: GmailEmailType
  className?: string
  isExcludingTime?: boolean
  isTruncating?: boolean
}) => {
  const fromContact = getContact({
    emailString: email.from,
    contacts: email.contacts,
  })

  const toNames = email.to
    .map(toField => {
      const toContact = getContact({
        emailString: toField,
        contacts: email.contacts,
      })
      return toContact?.name ?? toContact?.email
    })
    .filter(Boolean) as string[]
  const ccNames =
    (email.cc
      ?.map(ccField => {
        const ccContact = getContact({
          emailString: ccField,
          contacts: email.contacts,
        })
        return ccContact?.name ?? ccContact?.email
      })
      .filter(Boolean) as string[]) ?? []
  const bccNames =
    (email.bcc
      ?.map(bccField => {
        const bccContact = getContact({
          emailString: bccField,
          contacts: email.contacts,
        })
        return bccContact?.name ?? bccContact?.email
      })
      .filter(Boolean) as string[]) ?? []

  const truncatingClassName = isTruncating && 'line-clamp-1 w-full break-all'

  return (
    <div className={cn('flex min-w-0 flex-col items-start', className)}>
      <div className='flex w-full flex-1 flex-row flex-wrap items-center justify-between gap-x-2'>
        <p className='flex flex-wrap items-baseline gap-x-1 text-sm font-semibold'>
          <span>{fromContact?.name ?? email.from}</span>
          {fromContact?.name ? (
            <small className='text-muted-foreground inline-block'>
              &lt;{fromContact.email}&gt;
            </small>
          ) : null}
        </p>
        {isExcludingTime ? null : (
          <small
            className='text-muted-foreground'
            title={new Intl.DateTimeFormat('en', {
              dateStyle: 'medium',
              timeStyle: 'short',
            }).format(new Date(email.date))}>
            {dayjs(email.date).fromNow()}
          </small>
        )}
      </div>
      {toNames.length > 0 ? (
        <small
          className={cn('text-muted-foreground', truncatingClassName)}
          title={email.to.join(', ')}>
          to{' '}
          {new Intl.ListFormat('en', {
            style: 'long',
            type: 'conjunction',
          }).format(toNames)}
        </small>
      ) : null}
      {ccNames.length > 0 ? (
        <small
          className={cn('text-muted-foreground', truncatingClassName)}
          title={email.cc?.join(', ') ?? ''}>
          cc{' '}
          {new Intl.ListFormat('en', {
            style: 'long',
            type: 'conjunction',
          }).format(ccNames)}
        </small>
      ) : null}
      {bccNames.length > 0 ? (
        <small
          className={cn('text-muted-foreground', truncatingClassName)}
          title={email.bcc?.join(', ') ?? ''}>
          bcc{' '}
          {new Intl.ListFormat('en', {
            style: 'long',
            type: 'conjunction',
          }).format(bccNames)}
        </small>
      ) : null}
    </div>
  )
}
