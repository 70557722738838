import {
  type GoogleCalendarCreateEventType,
  type GoogleCalendarEventType,
} from '@/shared/constants/google-calendar'
import dayjs from '@/shared/singletons/dayjs'
import { isCreateEvent } from '@/shared/utils/google-calendar'
import { convertMarkdownToHtml } from '@/shared/utils/html'
import { ContextContent } from '@/web/components/ContextContent'
import { EventCardTitle } from '@/web/components/EventCardTitle'
import { RawHtml } from '@/web/components/RawHtml'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
} from '@/web/components/ui/card'
import {
  faCheckCircle,
  faClock,
  faQuestionCircle,
  faTimesCircle,
} from '@fortawesome/free-regular-svg-icons'
import {
  faAlignLeft,
  faLocationDot,
  faPeopleGroup,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Briefcase } from 'lucide-react'

export const EventDetails = ({
  event,
  className,
}: {
  event: GoogleCalendarCreateEventType | GoogleCalendarEventType
  className?: string
}) => {
  const details = isCreateEvent(event) ? event.details : event.description
  const contextMetadata = isCreateEvent(event)
    ? undefined
    : event.contextMetadata
  const summary = isCreateEvent(event)
    ? undefined
    : typeof event.summary !== 'string'
    ? event.summary
    : undefined

  return (
    <Card variant='secondary' size='small' className={className}>
      <CardHeader>
        <EventCardTitle event={event} />
        <small className='text-muted-foreground uppercase'>
          {dayjs(event.start).format('dddd, MMMM D, YYYY · h:mmA')} -{' '}
          {dayjs(event.end).format('h:mmA')}
        </small>
      </CardHeader>
      <ContextContent contextMetadata={contextMetadata} summary={summary} />
      <CardContent className='grid grid-flow-row grid-cols-[auto_1fr] gap-2'>
        {event.location ? (
          <CardDescription className='grid grid-cols-subgrid [grid-column:span_2]'>
            <FontAwesomeIcon
              fixedWidth
              icon={faLocationDot}
              className='text-muted-foreground shrink-0 justify-self-center'
              size='sm'
            />
            <small>{event.location}</small>
          </CardDescription>
        ) : null}
        {event.attendees?.length ? (
          <CardDescription className='grid grid-cols-subgrid [grid-column:span_2]'>
            <FontAwesomeIcon
              fixedWidth
              icon={faPeopleGroup}
              className='text-muted-foreground shrink-0 justify-self-center pt-0.5'
              size='sm'
            />
            <div>
              {isCreateEvent(event)
                ? event.attendees.join(', ')
                : event.attendees.map(attendee => (
                    <Attendee key={attendee.email} attendee={attendee} />
                  ))}
            </div>
          </CardDescription>
        ) : null}
        {!isCreateEvent(event) ? (
          <CardDescription className='grid grid-cols-subgrid items-center [grid-column:span_2]'>
            <Briefcase className='text-muted-foreground w-4 shrink-0 justify-self-center' />
            <small>{event.isBusy ? 'Busy' : 'Free'}</small>
          </CardDescription>
        ) : null}
        {details ? (
          <CardDescription className='grid grid-cols-subgrid [grid-column:span_2]'>
            <FontAwesomeIcon
              fixedWidth
              icon={faAlignLeft}
              className='text-muted-foreground shrink-0 justify-self-center pt-1.5'
              size='sm'
            />
            <RawHtml html={convertMarkdownToHtml(details)} className='flex-1' />
          </CardDescription>
        ) : null}
      </CardContent>
    </Card>
  )
}

const Attendee = ({
  attendee,
}: {
  attendee: Required<GoogleCalendarEventType>['attendees'][number]
}) => {
  return (
    <small className='flex flex-row items-center gap-2' title={attendee.email}>
      {attendee.name ?? attendee.email}
      {attendee.responseStatus ? (
        <FontAwesomeIcon
          size='xs'
          icon={
            attendee.responseStatus === 'accepted'
              ? faCheckCircle
              : attendee.responseStatus === 'declined'
              ? faTimesCircle
              : attendee.responseStatus === 'tentative'
              ? faQuestionCircle
              : attendee.responseStatus === 'needsAction'
              ? faClock
              : faQuestionCircle
          }
          className={
            attendee.responseStatus === 'accepted'
              ? 'text-success'
              : attendee.responseStatus === 'declined'
              ? 'text-error'
              : 'text-warning'
          }
        />
      ) : (
        ''
      )}
    </small>
  )
}
