import { cn } from '@/web/libs/utils'
import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

const rootVariants = cva(
  'flex flex-col justify-start rounded-sm border text-card-foreground',
  {
    variants: {
      variant: {
        default: 'bg-card',
        secondary: 'bg-base-200 shadow-inner',
        primary: 'outline-gradient outline-gradient-sm',
        transparent: 'bg-card/50 border-secondary-foreground/50',
        success: 'border-success bg-success/5',
        warning: 'border-warning bg-warning/5',
        error: 'border-error bg-error/5',
        clickable: 'cursor-pointer bg-card hover:bg-primary/5',
      },
      size: {
        xs: 'p-2 gap-2',
        small: 'p-4 gap-2',
        'sm-short': 'py-2 px-4',
        medium: 'p-6 gap-6',
        large: 'p-8 gap-8',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'medium',
    },
  }
)

export type CardVariants = VariantProps<typeof rootVariants>

const Card = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof rootVariants>
>(({ className, variant, size, ...props }, ref) => {
  return (
    <div
      className={cn(rootVariants({ variant, size }), className)}
      {...props}
      ref={ref}
    />
  )
})
Card.displayName = 'Card'

const cardHeaderVariants = cva('flex flex-col empty:hidden')
const CardHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof cardHeaderVariants>
>(({ className, ...props }, ref) => {
  return (
    <div ref={ref} className={cn(cardHeaderVariants(), className)} {...props} />
  )
})
CardHeader.displayName = 'CardHeader'

const CardTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h5 ref={ref} className={cn('break-words', className)} {...props} />
))
CardTitle.displayName = 'CardTitle'

const CardDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('text-muted-foreground text-sm', className)}
    {...props}
  />
))
CardDescription.displayName = 'CardDescription'

const cardContentVariants = cva('flex flex-col gap-2 empty:hidden')
const CardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> &
    VariantProps<typeof cardContentVariants>
>(({ className, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(cardContentVariants(), className)}
      {...props}
    />
  )
})
CardContent.displayName = 'CardContent'

const cardFooterVariants = cva('flex items-center empty:hidden')
const CardFooter = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof cardFooterVariants>
>(({ className, ...props }, ref) => {
  return (
    <div ref={ref} className={cn(cardFooterVariants(), className)} {...props} />
  )
})
CardFooter.displayName = 'CardFooter'

export { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle }
