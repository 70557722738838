import { EVENT } from '@/shared/constants/analytics'
import { TASK_TYPES_WITHOUT_DESCRIPTION } from '@/shared/constants/task'
import * as taskUtil from '@/shared/utils/task'
import { TaskActions } from '@/web/components/TaskActions'
import { TaskCardContext } from '@/web/components/TaskCardContext'
import { TaskCardFooter } from '@/web/components/TaskCardFooter'
import { TaskCardLayout } from '@/web/components/TaskCardLayout'
import { TaskDescriptionForm } from '@/web/components/TaskDescriptionForm'
import { TaskHeaderControls } from '@/web/components/TaskHeaderControls'
import { TaskHeaderDetails } from '@/web/components/TaskHeaderDetails'
import { TaskStatusText } from '@/web/components/TaskStatusText'
import { TaskTitleForm } from '@/web/components/TaskTitleForm'
import { Button } from '@/web/components/ui/button'
import {
  CardContent,
  CardDescription,
  CardHeader,
} from '@/web/components/ui/card'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/web/components/ui/collapsible'
import { KEYBOARD_SHORTCUT } from '@/web/constants/keyboard'
import { type TaskFromQuery } from '@/web/constants/task'
import { DemoContext } from '@/web/contexts/demo'
import { TaskContext } from '@/web/contexts/task'
import { usePrevious } from '@/web/hooks/use-previous'
import { useTask } from '@/web/hooks/use-task'
import { useKeyboardShortcut } from '@/web/hooks/useKeyboardShortcut'
import { cn } from '@/web/libs/utils'
import { currentTaskIdState } from '@/web/state/current-task-id'
import { track } from '@/web/utils/analytics'
import { api } from '@/web/utils/api'
import { type Route } from 'next'
import Link from 'next/link'
import React, { forwardRef, useCallback, useEffect, useRef } from 'react'
import { useRecoilState } from 'recoil'
import { useIntersectionObserver } from 'usehooks-ts'

type PropsType = {
  id?: string
  task?: TaskFromQuery | null
  isDemo?: boolean
  isInitiallyOpen?: boolean
  className?: string
  isAlwaysOpen?: boolean
}

export const TaskCard = forwardRef<HTMLDivElement | null, PropsType>(
  function TaskCard(
    { id, isInitiallyOpen, isAlwaysOpen, task, isDemo, className },
    ref
  ) {
    if (!ref) {
      ref = React.createRef()
    }

    const taskResult = useTask(id ?? '-1', {
      enabled: !task && !isDemo,
    })
    task = task ?? taskResult

    const [isOpen, setIsOpen] = React.useState(
      isAlwaysOpen || (isInitiallyOpen ?? isDemo ?? false)
    )
    // Close if changed to completed (unless in demo mode)
    const isCompleted = taskUtil.isComplete(task)
    const wasCompleted = usePrevious(isCompleted)
    useEffect(() => {
      if (!wasCompleted && isCompleted && !isDemo && !isAlwaysOpen) {
        console.log('Closing task after completing', {
          wasCompleted,
          isCompleted,
          isDemo,
        })
        setIsOpen(false)
      }
    }, [wasCompleted, isCompleted, isDemo])

    const [currentTaskId, setCurrentTaskId] = useRecoilState(currentTaskIdState)
    const isCurrent = currentTaskId === id

    const readTaskMutation = api.task.read.useMutation({
      onMutate() {
        track(EVENT.READ_TASK, {
          type: taskUtil.isNew(task) ? 'new' : 'updated',
        })
      },
    })

    // Open on ENTER
    const toggleOpen = useCallback(() => {
      setIsOpen(!isOpen)
      setCurrentTaskId(id ?? null)
    }, [isOpen, setIsOpen, id])
    useKeyboardShortcut(
      KEYBOARD_SHORTCUT.TASKS_TOGGLE_OPEN,
      toggleOpen,
      isCurrent && !isAlwaysOpen
    )

    const isUnread = taskUtil.isUnread(task)
    const visibilityRef = useRef<HTMLDivElement | null>(null)
    const intersectionObserverEntry = useIntersectionObserver(visibilityRef, {})
    const isVisible = !!intersectionObserverEntry?.isIntersecting
    useEffect(() => {
      if (
        isDemo ||
        !isVisible ||
        !task?.id ||
        !isUnread ||
        readTaskMutation.isLoading ||
        !isOpen
      ) {
        return
      }

      console.info('Marking task %o as read %O', task?.title, {
        isUnread,
        isVisible,
        isDemo,
        task,
        readTaskMutation,
        isOpen,
      })
      readTaskMutation.mutate({
        taskId: task?.id,
      })
    }, [
      isUnread,
      isVisible,
      isDemo,
      task?.id,
      readTaskMutation.isLoading,
      isOpen,
    ])

    if (!task) {
      return null
    }

    return (
      <TaskContext.Provider value={task}>
        <DemoContext.Provider value={isDemo ?? false}>
          <TaskCardLayout
            className={cn('group', className)}
            isCurrent={isCurrent}
            isOpen={isOpen}
            isUnread={isUnread}
            isCompleted={isCompleted}
            isErrored={taskUtil.isCancelled(task)}
            isWarning={!!task.expiryDate && task.expiryDate < new Date()}
            ref={ref}
            visibilityRef={visibilityRef}
            taskId={id}>
            <Collapsible
              open={isDemo ?? isOpen}
              defaultOpen={isInitiallyOpen}
              onOpenChange={isOpen => !isAlwaysOpen && setIsOpen(isOpen)}
              /* eslint-disable prettier/prettier */
              className='grid [grid-column:1_/_span_2] [grid-row:1_/_span_2] [grid-template-columns:subgrid] [grid-template-rows:subgrid]'>
              <CollapsibleTrigger
                className='cursor-pointer py-1 [grid-column:2] [grid-row:span_1]'
                asChild>
                <CardHeader className='flex flex-col items-center justify-between gap-2 sm:flex-row'>
                  <div className='flex w-full flex-1 flex-col flex-wrap items-start justify-between gap-x-4 gap-y-1 leading-4 sm:w-auto lg:flex-row lg:items-center'>
                    {/* eslint-disable prettier/prettier */}
                    <div className='flex w-full flex-col flex-wrap text-left [flex-basis:40%] [flex-grow:1] [flex-shrink:0]'>
                      <TaskHeaderDetails />
                      <TaskTitleForm />
                      <TaskStatusText />
                    </div>
                  </div>
                  <TaskHeaderControls task={task} />
                </CardHeader>
              </CollapsibleTrigger>
              <CollapsibleContent className='[grid-column:1_/_span_2] [grid-row:2] sm:[grid-column:2]'>
                <CardContent className='flex flex-col gap-4 pb-4'>
                  {!TASK_TYPES_WITHOUT_DESCRIPTION.includes(task.type!) ? (
                    <TaskDescriptionForm />
                  ) : null}
                  {task.links?.filter(link => !!link.trim()).length ? (
                    <CardDescription className='line-clamp-1 flex flex-col items-start gap-0'>
                      {task.links.map(link => (
                        <Link key={link} href={link as Route} target='_blank'>
                          <Button
                            key={link}
                            variant='link'
                            size='xs'
                            className='line-clamp-1 text-left [display:-webkit-inline-box_!important]'>
                            {link}
                          </Button>
                        </Link>
                      ))}
                    </CardDescription>
                  ) : null}
                  <TaskCardContext />
                  <TaskActions />
                  <TaskCardFooter
                    task={task}
                    isDemo={isDemo}
                    setIsOpen={!isAlwaysOpen ? setIsOpen : undefined}
                  />
                </CardContent>
              </CollapsibleContent>
            </Collapsible>
          </TaskCardLayout>
        </DemoContext.Provider>
      </TaskContext.Provider>
    )
  }
)
TaskCard.displayName = 'TaskCard'
