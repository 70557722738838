import { Input } from '@/web/components/ui/input'
import { cn } from '@/web/libs/utils'
import { useEffect, useState, type ComponentProps } from 'react'
import { useDebouncedCallback } from 'use-debounce'

function maintainPosition(target: HTMLInputElement, position: number) {
  console.log('Maintaining position %d in duration input', position)
  target.selectionStart = position
  target.selectionEnd = position
  setTimeout(() => {
    target.selectionStart = position
    target.selectionEnd = position
  }, 0)
}

const formatDurationString = (totalMinutes: number) => {
  const hours = Math.floor(totalMinutes / 60)
    .toString()
    .padStart(2, '0')
  const minutes = (totalMinutes % 60).toString().padStart(2, '0')
  return `${hours}:${minutes}`
}

const parseDurationIntoMinutes = (duration: string) => {
  let [hours, minutes] = duration.split(':').map(Number)

  hours = hours ?? 0
  minutes = minutes ?? 0

  if (isNaN(hours) || isNaN(minutes) || hours >= 100 || minutes >= 100) {
    console.log('Invalid duration', duration)
    return null
  }

  return hours * 60 + minutes
}

const DurationInput = ({
  onChangeDuration,
  defaultMinutes = 0,
  ...props
}: Partial<ComponentProps<typeof Input>> & {
  onChangeDuration: (minutes: number) => void
  defaultMinutes?: number | null
}) => {
  const [duration, setDuration] = useState(
    formatDurationString(defaultMinutes ?? 0)
  )

  // Update the duration if defaultMinutes changes
  useEffect(() => {
    setDuration(formatDurationString(defaultMinutes ?? 0))
  }, [defaultMinutes])

  const updateDurationIfValid = (newDuration: string) => {
    const parsed = parseDurationIntoMinutes(newDuration)
    if (parsed == null) {
      console.warn('Invalid duration', newDuration)
      return
    }

    console.log('Updating valid duration', newDuration, parsed)
    setDuration(formatDurationString(parsed))
    debouncedOnChangeDuration(parseDurationIntoMinutes(newDuration) ?? 0)
  }
  const debouncedOnChangeDuration = useDebouncedCallback(
    onChangeDuration,
    200,
    {
      leading: false,
    }
  )

  const handleChange: ComponentProps<typeof Input>['onChange'] = event => {
    const { selectionStart, selectionEnd } = event.target
    const inputChar = (
      event.nativeEvent as unknown as { data: string | undefined }
    ).data

    // Handle non-digit input or if for some reason the selectionStart is null
    if (
      selectionStart === null ||
      selectionEnd === null ||
      !inputChar ||
      !inputChar.match(/\d/)
    ) {
      maintainPosition(event.target, selectionStart ? selectionStart - 1 : 0)
      return
    }

    // Remove non-numeric characters for easier manipulation
    let newDuration = duration.replace(/[^0-9]/g, '')
    let newPosition = selectionStart > 2 ? selectionStart - 1 : selectionStart

    // Handling highlighted text replacement
    if (selectionStart !== selectionEnd) {
      // Replace highlighted text with the new character
      newDuration =
        newDuration.slice(0, selectionStart) +
        inputChar +
        newDuration.slice(selectionEnd)
    } else {
      // Calculate the new position, considering the colon
      newPosition = selectionStart > 2 ? selectionStart - 1 : selectionStart

      // Insert the new character and remove the first character if we're at max length
      newDuration =
        newDuration.slice(0, newPosition - 1) +
        inputChar +
        newDuration.slice(newPosition)
      if (newDuration.length > 4) newDuration = newDuration.slice(1)
    }

    // Re-insert the colon for the hh:mm format
    newDuration = newDuration.slice(0, 2) + ':' + newDuration.slice(2)

    // Update the state with the new formatted duration
    updateDurationIfValid(newDuration)

    // Move the cursor to the next position after the state update
    const target = event.target as HTMLInputElement
    setTimeout(() => {
      if (newPosition >= 2) newPosition++ // Skip the colon
      // newPosition = newPosition + 1 // Move to the next position
      target.selectionStart = newPosition
      target.selectionEnd = newPosition
      console.log('Moving duration cursor to position %d', newPosition)
    }, 0)
  }
  const handleBlur: ComponentProps<typeof Input>['onBlur'] = event => {
    updateDurationIfValid(event.target.value)
  }

  const handleKeyDown: ComponentProps<typeof Input>['onKeyDown'] = event => {
    let [hours, minutes] = duration.split(':').map(Number)
    hours = hours ?? 0
    minutes = minutes ?? 0

    const isArrowUp = event.key === 'ArrowUp'
    const isArrowDown = event.key === 'ArrowDown'
    const isBackspace = event.key === 'Backspace'

    const target = event.target as HTMLInputElement
    const selectionStart = target.selectionStart ?? 0
    const selectionEnd = target.selectionEnd ?? 0

    const cursorPosition = event.currentTarget.selectionStart ?? 0
    const colonIndex = 2
    const isAfterColonCharacter = cursorPosition === colonIndex + 1

    if (isArrowUp || isArrowDown) {
      event.preventDefault() // Prevent cursor from moving
      let newHours = hours
      let newMinutes = minutes

      const isHoursInput = cursorPosition < 3

      if (isHoursInput) {
        newHours = isArrowUp ? (hours + 1) % 100 : (hours - 1 + 100) % 100
      } else {
        newMinutes = isArrowUp ? (minutes + 1) % 60 : (minutes - 1 + 60) % 60
      }

      const newDuration =
        `${newHours}`.padStart(2, '0') + ':' + `${newMinutes}`.padStart(2, '0')

      const target = event.target as HTMLInputElement
      maintainPosition(target, cursorPosition)

      updateDurationIfValid(newDuration)
    } else if (isBackspace) {
      event.preventDefault() // Prevent default backspace behavior
      let newDuration = duration
      const target = event.target as HTMLInputElement
      const newPosition = cursorPosition - (isAfterColonCharacter ? 1 : 0)

      if (selectionStart !== selectionEnd) {
        event.preventDefault() // Prevent default backspace behavior

        const isColonSelected =
          selectionStart <= colonIndex && selectionEnd >= colonIndex
        // Calculate the number of characters to replace with 0
        const lengthOfSelection =
          selectionEnd - selectionStart - (isColonSelected ? 1 : 0)
        const zeros = '0'.repeat(lengthOfSelection)

        // Replace the selected chunk with zeros
        newDuration =
          duration.slice(0, selectionStart) +
          zeros +
          duration.slice(selectionEnd)

        // Add back the colon
        if (isColonSelected) {
          newDuration = newDuration.slice(0, 2) + ':' + newDuration.slice(2)
        }
      } else {
        // Remove the character before the cursor, accounting for the colon
        if (isAfterColonCharacter) {
          newDuration =
            newDuration.substring(0, cursorPosition - 2) +
            '0:' +
            newDuration.substring(cursorPosition)
        } else {
          newDuration =
            newDuration.substring(0, cursorPosition - 1) +
            '0' +
            newDuration.substring(cursorPosition)
        }
      }

      maintainPosition(target, newPosition - 1)

      updateDurationIfValid(newDuration)
    }
  }

  return (
    <Input
      {...props}
      className={cn('w-[60px] text-center', props.className)}
      type='text'
      value={duration}
      pattern='[0-9][0-9]:[0-5][0-9]'
      placeholder='hh:mm'
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
    />
  )
}

export default DurationInput
