import { SCORE_VALUE, VALUE_SCORE } from '@/shared/constants/score'
import { Score } from '@prisma/client'

export function invertScore(score?: Score | null): Score | null {
  if (score == null) {
    return null
  }

  const invertedScore = VALUE_SCORE[2 - SCORE_VALUE[score]]
  if (invertedScore === undefined) {
    throw new Error(`Invalid score: ${score}`)
  }
  return invertedScore
}

export function calculateScore(scores: Score[]): Score | null {
  if (scores.length === 0) {
    return null // Return null if there are no scores to calculate
  }

  const totalScore = scores.reduce(
    (totalScore, score) => totalScore + SCORE_VALUE[score],
    0
  )

  const averageScore = totalScore / scores.length

  // Define the cutoffs for the score ranges
  if (averageScore >= (2 / 3) * SCORE_VALUE[Score.HIGH]) {
    return Score.HIGH
  } else if (averageScore >= (1 / 3) * SCORE_VALUE[Score.HIGH]) {
    return Score.MEDIUM
  } else {
    return Score.LOW
  }
}

// export const gaussian = (x: number, mean = 0, std = 1): number => {
//   // Check for std being zero or negative
//   if (std <= 0) {
//     console.error('Standard deviation (std) must be greater than 0')
//     return NaN // or handle this case as appropriate for your application
//   }

//   // Ensure inputs are numbers (additional safeguard)
//   if (
//     typeof x !== 'number' ||
//     typeof mean !== 'number' ||
//     typeof std !== 'number'
//   ) {
//     console.error('Inputs must be numbers')
//     return NaN
//   }

//   // Calculation
//   return Math.exp(-((x - mean) ** 2) / (2 * std ** 2))
// }

export const normalize = (
  value: number,
  minVal: number,
  maxVal: number
): number => {
  console.assert(
    minVal <= maxVal,
    'minVal must be less than or equal to maxVal'
  )

  if (minVal === maxVal) {
    return maxVal
  }

  return Math.min(Math.max((value - minVal) / (maxVal - minVal), 0), 1)
}

export const normalizeArray = (values: number[]): number[] => {
  const minVal = Math.min(...values)
  const maxVal = Math.max(...values)
  return values.map(value => normalize(value, minVal, maxVal))
}
