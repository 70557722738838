import { SCORE_THEME, SCORE_TITLE } from '@/shared/constants/score'
import { cn } from '@/web/libs/utils'
import { faFlag as faFlagRegular } from '@fortawesome/free-regular-svg-icons'
import { faFlag as faFlagSolid } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type Score } from '@prisma/client'
import { type ComponentProps } from 'react'

export const PriorityIcon = ({
  priority,
  size,
  className,
}: {
  priority?: Score | null
  className?: string
  size?: ComponentProps<typeof FontAwesomeIcon>['size']
}) => (
  <FontAwesomeIcon
    icon={priority ? faFlagSolid : faFlagRegular}
    titleId={priority ? `Priority: ${SCORE_TITLE[priority]}` : undefined}
    className={cn(
      priority ? `text-${SCORE_THEME[priority]}` : 'text-muted-foreground',
      className
    )}
    size={size}
    fixedWidth
  />
)
