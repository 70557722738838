function getPlatform() {
  if (typeof navigator === 'undefined') return 'unknown'

  const userAgentData = (navigator as { userAgentData?: { platform: string } })
    .userAgentData
  // 2022 way of detecting. Note : this userAgentData feature is available only in secure contexts (HTTPS)
  if (typeof userAgentData !== 'undefined' && userAgentData != null) {
    return userAgentData.platform
  }
  // Deprecated but still works for most of the browser
  if (typeof navigator.platform !== 'undefined') {
    if (
      typeof navigator.userAgent !== 'undefined' &&
      /android/.test(navigator.userAgent.toLowerCase())
    ) {
      // android device’s navigator.platform is often set as 'linux', so let’s use userAgent for them
      return 'android'
    }
    return navigator.platform
  }
  return 'unknown'
}

export const PLATFORM = getPlatform().toLowerCase()
export const IS_OSX = /mac/.test(PLATFORM) // Mac desktop
export const IS_IOS = ['iphone', 'ipad', 'ipod'].indexOf(PLATFORM) >= 0 // Mac iOs
export const IS_APPLE = IS_OSX || IS_IOS // Apple device (desktop or iOS)
export const IS_WINDOWS = /win/.test(PLATFORM) // Windows
export const IS_ANDROID = /android/.test(PLATFORM) // Android
export const IS_LINUX = /linux/.test(PLATFORM) // Linux

export const HAS_TOUCH_SCREEN =
  (typeof window !== 'undefined' && 'ontouchstart' in window) ||
  (typeof navigator !== 'undefined' && navigator.maxTouchPoints > 0)
