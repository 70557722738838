import { SCORE_THEME } from '@/shared/constants/score'
import { cn } from '@/web/libs/utils'
import { faClock as faClockRegular } from '@fortawesome/free-regular-svg-icons'
import { faClock as faClockSolid } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type Score } from '@prisma/client'

export const TimeIcon = ({
  time,
  className,
}: {
  time?: Score | null
  className?: string
}) => (
  <FontAwesomeIcon
    icon={time ? faClockSolid : faClockRegular}
    className={cn(
      time ? `text-${SCORE_THEME[time]}` : 'text-muted-foreground',
      className
    )}
    fixedWidth
  />
)
