import {
  ACTION_TYPES_WITH_EMAIL_BODY,
  type INTEGRATION_NAME_ACTION_TYPE_PARAMETERS_SCHEMA,
  type IntegrationActionResultType,
} from '@/shared/constants/action'
import { getActionParameters } from '@/shared/utils/action'
import { getTaskOriginEmail, isGmailTask } from '@/shared/utils/gmail'
import { isTaskWithContextItemsAndActions } from '@/shared/utils/task'
import { EmailThread } from '@/web/components/EmailThread'
import { EventDetails } from '@/web/components/EventDetails'
import { IssueDetails } from '@/web/components/IssueDetails'
import { Message } from '@/web/components/Message'
import { Button } from '@/web/components/ui/button'
import { Card, CardContent } from '@/web/components/ui/card'
import { Skeleton } from '@/web/components/ui/skeleton'
import { TaskContext } from '@/web/contexts/task'
import { useAllContextData } from '@/web/hooks/use-all-context-data'
import { api } from '@/web/utils/api'
import { ActionType, IntegrationName, type Action } from '@prisma/client'
import { type Route } from 'next'
import Link from 'next/link'
import { useContext } from 'react'
import { type z } from 'zod'

export const TaskActionDetails = ({ action }: { action: Action }) => {
  const task = useContext(TaskContext)

  const emailId =
    action.integrationName === IntegrationName.GMAIL &&
    ACTION_TYPES_WITH_EMAIL_BODY.includes(action.type)
      ? (
          action.result as IntegrationActionResultType['GMAIL'][
            | typeof ActionType.SEND_EMAIL
            | typeof ActionType.REPLY_TO_EMAIL]
        )?.emailId ?? null
      : null
  const loadEmailQueryResult = api.gmail.loadEmail.useQuery(
    { emailId: emailId ?? '' },
    {
      enabled: !!emailId,
    }
  )

  const { data: allContextData, isLoading } = useAllContextData()

  if (!task) {
    throw new Error('Task not found')
  }

  if (action.type === ActionType.FOLLOW_LINK) {
    const parameters = getActionParameters<typeof ActionType.FOLLOW_LINK>(
      action as Action & { type: typeof ActionType.FOLLOW_LINK }
    )

    return (
      <Link href={parameters.url as Route} target='_blank'>
        <Button variant='link' size='sm'>
          {parameters.url}
        </Button>
      </Link>
    )
  }

  if (
    action.type === ActionType.SEND_MESSAGE &&
    action.integrationName === IntegrationName.SLACK
  ) {
    const message =
      action.result as IntegrationActionResultType['SLACK']['SEND_MESSAGE']
    if (!message) {
      return null
    }
    return (
      <Card variant='secondary'>
        <Message message={message} />
      </Card>
    )
  }

  if (
    action.type === ActionType.REPLY_TO_MESSAGE &&
    action.integrationName === IntegrationName.SLACK
  ) {
    const message =
      action.result as IntegrationActionResultType['SLACK']['REPLY_TO_MESSAGE']
    if (!message) {
      return null
    }

    return (
      <Card variant='secondary'>
        <Message message={message} />
      </Card>
    )
  }

  if (
    action.type === ActionType.SEND_EMAIL &&
    action.integrationName === IntegrationName.GMAIL
  ) {
    const { emailId, message } =
      (action.result as IntegrationActionResultType['GMAIL']['SEND_EMAIL']) ||
      {}
    const parameters = action.parameters as z.infer<
      (typeof INTEGRATION_NAME_ACTION_TYPE_PARAMETERS_SCHEMA)['GMAIL']['SEND_EMAIL']
    >

    return (
      <Card size='xs' variant='secondary'>
        <CardContent>
          <EmailThread
            emails={[
              {
                id: emailId ?? 'sent123',
                body: { plain: message },
                to: parameters.to,
                cc: [],
                bcc: [],
                from: parameters.from,
                subject: parameters.subject,
                labels: ['SENT'],
                date: action.completedDate!,
              },
            ]}
            labels={['SENT']}
            subject={parameters.subject}
          />
        </CardContent>
      </Card>
    )
  }

  if (
    isTaskWithContextItemsAndActions(task) &&
    isGmailTask(task) &&
    action.type === ActionType.REPLY_TO_EMAIL &&
    action.integrationName === IntegrationName.GMAIL
  ) {
    const { emailId, message } =
      (action.result as IntegrationActionResultType['GMAIL']['REPLY_TO_EMAIL']) ||
      {}
    const parameters = action.parameters as z.infer<
      (typeof INTEGRATION_NAME_ACTION_TYPE_PARAMETERS_SCHEMA)['GMAIL']['REPLY_TO_EMAIL']
    >

    if (isLoading) {
      return <Skeleton />
    }

    const email =
      allContextData?.emails?.find(email => email.id === parameters.emailId) ??
      getTaskOriginEmail(allContextData) ??
      allContextData.emails?.[0]
    if (!email || !emailId || !message) {
      console.error('No last email in task to render for action result', task)
      return null
    }

    return (
      <Card size='xs' variant='secondary'>
        <CardContent>
          <EmailThread
            emails={[
              email,
              loadEmailQueryResult.data?.email ?? {
                ...email,
                id: emailId,
                body: { plain: message },
                to: [email.from],
                cc: [],
                bcc: [],
                from: 'You',
                labels: [],
                date: action.completedDate!,
              },
            ]}
            subject={email.subject}
            labels={email.labels}
          />
        </CardContent>
      </Card>
    )
  }

  if (
    action.type === ActionType.CREATE_TASK &&
    action.integrationName === IntegrationName.JIRA
  ) {
    const data = action.parameters as unknown as z.infer<
      (typeof INTEGRATION_NAME_ACTION_TYPE_PARAMETERS_SCHEMA)['JIRA']['CREATE_TASK']
    >

    return <IssueDetails issue={data} />
  }

  if (
    action.type === ActionType.CREATE_EVENT &&
    action.integrationName === IntegrationName.GOOGLE_CALENDAR
  ) {
    const data = action.parameters as unknown as z.infer<
      (typeof INTEGRATION_NAME_ACTION_TYPE_PARAMETERS_SCHEMA)['GOOGLE_CALENDAR']['CREATE_EVENT']
    >

    return <EventDetails event={data} />
  }

  console.log('Unhandled action type for task action details', action)
  return null
}
