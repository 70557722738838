import { SCORE_THEME } from '@/shared/constants/score'
import dayjs from '@/shared/singletons/dayjs'
import {
  getTaskIntegrationTitle,
  isTaskWithContextItemsAndActions,
} from '@/shared/utils/task'
import { getDueDateScore } from '@/shared/utils/task-priority'
import { TaskAvatars } from '@/web/components/TaskAvatars'
import { TaskIntegrationIcon } from '@/web/components/TaskIntegrationIcon'
import { Skeleton } from '@/web/components/ui/skeleton'
import { DemoContext } from '@/web/contexts/demo'
import { TaskContext } from '@/web/contexts/task'
import { useAllContextData } from '@/web/hooks/use-all-context-data'
import { cn } from '@/web/libs/utils'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext } from 'react'

export const TaskHeaderDetails = ({ className }: { className?: string }) => {
  const task = useContext(TaskContext)
  const isDemo = useContext(DemoContext)
  const { data: allContextData, isLoading } = useAllContextData()
  if (!task) {
    return null
  }

  const taskIntegrationTitle = isTaskWithContextItemsAndActions(task)
    ? getTaskIntegrationTitle({
        task,
        allContextData,
      })
    : null
  const dueDateScore = getDueDateScore(task.dueDate)

  return (
    <div
      className={cn(
        'line-clamp-1 flex h-auto flex-row flex-wrap items-start justify-start gap-x-2 gap-y-1 sm:h-4 sm:flex-nowrap',
        className
      )}>
      <TaskIntegrationIcon />
      {taskIntegrationTitle ? (
        <small
          className='text-accent-foreground line-clamp-1 max-w-20 md:max-w-60 break-all leading-4'
          title={taskIntegrationTitle}>
          {taskIntegrationTitle}
        </small>
      ) : task.integrationName && !isDemo && isLoading ? (
        <Skeleton className='h-4 w-20' />
      ) : null}
      <TaskAvatars />

      {dueDateScore ? (
        <small
          className={cn(
            'flex items-center justify-center gap-1',
            `text-${SCORE_THEME[dueDateScore]}`
          )}>
          <FontAwesomeIcon icon={faTriangleExclamation} />
          Due {dayjs(task.dueDate).fromNow()}
        </small>
      ) : null}
    </div>
  )
}
