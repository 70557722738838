import { Email } from '@/web/components/Email'
import { TaskIntegrationContext } from '@/web/components/TaskIntegrationContext'
import { Card } from '@/web/components/ui/card'
import { IntegrationName } from '@prisma/client'
import pluralize from 'pluralize'

export const GmailTaskContext = () => {
  return (
    <TaskIntegrationContext
      integrationName={IntegrationName.GMAIL}
      getTriggerText={({ count, data }) =>
        `${data ? data.emails.length : count} related ${pluralize(
          'email',
          data ? data.emails.length : count
        )}`
      }>
      {data => (
        <Card
          variant='secondary'
          size='xs'
          className='divide-border gap-0 divide-y py-0'>
          {data.emails.map((email, index, emails) => (
            <Email
              key={email.id}
              email={email}
              isDefaultOpen={index === 0 && emails.length === 1}
              className='py-2'
            />
          ))}
        </Card>
      )}
    </TaskIntegrationContext>
  )
}
