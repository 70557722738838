import { Button, type ButtonProps } from '@/web/components/ui/button'

export function TableCellIconButton(props: ButtonProps) {
  return (
    <Button
      variant='ghost'
      theme='secondary-dark'
      className='h-8 w-8 border-0 p-0'
      {...props}
    />
  )
}
