import { getStatus } from '@/shared/utils/task'
import { TaskStatusCheckbox } from '@/web/components/TaskStatusCheckbox'
import { Card } from '@/web/components/ui/card'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/web/components/ui/tooltip'
import { KEYBOARD_SHORTCUT } from '@/web/constants/keyboard'
import { TASK_STATUS_OPTIONS } from '@/web/constants/task'
import { DemoContext } from '@/web/contexts/demo'
import { TaskContext } from '@/web/contexts/task'
import { useKeyboardShortcut } from '@/web/hooks/useKeyboardShortcut'
import { useUpdateTaskMutation } from '@/web/hooks/useUpdateTaskMutation'
import { cn } from '@/web/libs/utils'
import { currentTaskIdState } from '@/web/state/current-task-id'
import { currentTaskStatusCheckboxGroupState } from '@/web/state/current-task-status-checkbox-group'
import { TaskStatus } from '@prisma/client'
import React, {
  useCallback,
  useContext,
  useEffect,
  useId,
  useRef,
  type ComponentProps,
} from 'react'
import { useRecoilState } from 'recoil'

export const TaskStatusCheckboxGroup = React.memo(
  function TaskStatusCheckboxGroup({
    size,
    className,
    checkboxClassName,
  }: {
    size?: ComponentProps<typeof TaskStatusCheckbox>['size']
    className?: string
    checkboxClassName?: string
  }) {
    const isDemo = useContext(DemoContext)
    const task = useContext(TaskContext)

    const status = getStatus(task)

    const currentId = useId()
    const [currentTaskId, setCurrentTaskId] = useRecoilState(currentTaskIdState)

    const [currentTaskStatusCheckboxGroup, setCurrentTaskStatusCheckboxGroup] =
      useRecoilState(currentTaskStatusCheckboxGroupState)

    const updateTaskMutationResult = useUpdateTaskMutation()

    const onChangeStatus = useCallback(
      (status: TaskStatus) => {
        if (!task?.id || isDemo) {
          return
        }

        updateTaskMutationResult.mutate({
          id: task.id,
          status,
        })
      },
      [isDemo, task, updateTaskMutationResult]
    )

    const [isOpen, setIsOpen] = React.useState(false)

    // FIX: Close if the currently open checkbox group is different
    useEffect(() => {
      if (isOpen && currentTaskStatusCheckboxGroup !== currentId) {
        setIsOpen(false)
      }
    }, [currentId, currentTaskStatusCheckboxGroup, isOpen])

    const hoverRef = useRef<'currentCheckbox' | 'checkboxGroup' | null>()
    const closeTimeoutRef = useRef<number | null>(null)
    const clearCloseTimeout = useCallback(() => {
      if (closeTimeoutRef.current) {
        clearTimeout(closeTimeoutRef.current)
        closeTimeoutRef.current = null
      }
    }, [])
    const handleMouseEnter = useCallback(
      (element: 'currentCheckbox' | 'checkboxGroup') => {
        console.debug('Mouse enter, preventing closing', element)
        clearCloseTimeout()
        hoverRef.current = element
        setIsOpen(true)
        setCurrentTaskStatusCheckboxGroup(currentId)
      },
      [setIsOpen]
    )
    const handleMouseLeave = useCallback(
      (element: 'currentCheckbox' | 'checkboxGroup') => {
        closeTimeoutRef.current = window.setTimeout(() => {
          console.debug('Mouse leave, delaying closing', element)
          if (hoverRef.current === element) {
            console.debug('Closing after delay', element)
            hoverRef.current = null
            setIsOpen(false)
          }
        }, 50)
      },
      [setIsOpen]
    )

    const onMouseEnterCurrentCheckbox = useCallback(
      () => handleMouseEnter('currentCheckbox'),
      [handleMouseEnter]
    )
    const onMouseLeaveCurrentCheckbox = useCallback(
      () => handleMouseLeave('currentCheckbox'),
      [handleMouseLeave]
    )
    const onMouseEnterCheckboxGroup = useCallback(
      () => handleMouseEnter('checkboxGroup'),
      [handleMouseEnter]
    )
    const onMouseLeaveCheckboxGroup = useCallback(
      () => handleMouseLeave('checkboxGroup'),
      [handleMouseLeave]
    )

    const onCurrentCheckedChange = useCallback(() => {
      onChangeStatus?.(
        status === TaskStatus.TODO ? TaskStatus.COMPLETED : TaskStatus.TODO
      )
      setIsOpen(false)
    }, [onChangeStatus, setCurrentTaskId, status, task?.id])
    const createOnGroupCheckedChange = useCallback(
      (checkboxStatus: TaskStatus) => () => {
        onChangeStatus?.(checkboxStatus as TaskStatus)
        setIsOpen(false)
      },
      [onChangeStatus]
    )

    // Complete/uncomplete on spacebar if current task and focused
    useKeyboardShortcut(
      KEYBOARD_SHORTCUT.TASK_COMPLETE_TOGGLE,
      onCurrentCheckedChange,
      currentTaskId === task?.id
    )

    if (!task) {
      return null
    }

    return (
      <TooltipProvider>
        <Tooltip
          disableHoverableContent={false}
          open={isOpen}
          onOpenChange={isOpen => {
            if (isOpen) {
              setIsOpen(true)
            }
          }}>
          <TooltipTrigger
            onMouseEnter={onMouseEnterCurrentCheckbox}
            onMouseLeave={onMouseLeaveCurrentCheckbox}
            className={className}
            asChild>
            <span className='leading-[0]'>
              <TaskStatusCheckbox
                size={size}
                isChecked={!!status && status !== TaskStatus.TODO}
                onCheckedChange={onCurrentCheckedChange}
                status={status ?? TaskStatus.TODO}
                className={checkboxClassName}
              />
            </span>
          </TooltipTrigger>
          <TooltipContent
            side='bottom'
            asChild
            portalProps={{}}
            onMouseEnter={onMouseEnterCheckboxGroup}
            onMouseLeave={onMouseLeaveCheckboxGroup}>
            <Card
              size='xs'
              className={cn('flex shadow-md', size === 'xs' && 'p-1')}>
              {TASK_STATUS_OPTIONS.map(checkboxStatus => (
                <TaskStatusCheckbox
                  size={size}
                  key={checkboxStatus}
                  isChecked={
                    status === checkboxStatus ||
                    (!status && checkboxStatus === TaskStatus.TODO)
                  }
                  onCheckedChange={createOnGroupCheckedChange(checkboxStatus)}
                  status={(checkboxStatus as TaskStatus) ?? TaskStatus.TODO}
                  isAlwaysIndicating
                />
              ))}
            </Card>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    )
  }
)
