import { SCORE_THEME, SCORE_TITLE } from '@/shared/constants/score'
import { autoUpdatePriorityFields } from '@/shared/utils/task-priority'
import { EstimatedByPulseTooltip } from '@/web/components/EstimatedByPulseTooltip'
import { ImportanceIcon } from '@/web/components/ImportanceIcon'
import { PopoverButtonWithTooltip } from '@/web/components/PopoverButtonWithTooltip'
import { ProgressButton } from '@/web/components/ProgressButton'
import { ScoreTitle } from '@/web/components/ScoreTitle'
import { Button } from '@/web/components/ui/button'
import { Label } from '@/web/components/ui/label'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/web/components/ui/tooltip'
import { useUpdateTaskMutation } from '@/web/hooks/useUpdateTaskMutation'
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type Score, type Task } from '@prisma/client'
import { type ComponentProps } from 'react'

const ImportanceScoreReasonRow = ({
  score,
  reason,
  title,
}: {
  score?: Score | null
  reason?: string | null
  title: string
}) =>
  reason ? (
    <>
      <span className='text-muted-foreground text-right'>{title}:</span>
      <span className='text-secondary-foreground inline-block space-x-2'>
        <ScoreTitle score={score} />
        <span>{reason}</span>
      </span>
    </>
  ) : null

export const TaskImportanceButton = ({ task }: { task: Task }) => {
  const updateTaskMutation = useUpdateTaskMutation()
  const updateTaskImportance: typeof updateTaskMutation.mutate = (
    variables,
    options
  ) => {
    variables = autoUpdatePriorityFields(task, variables)

    updateTaskMutation.mutate(variables, options)
  }

  const importanceScoreReasons = [
    task?.reachScoreReason,
    task?.impactScoreReason,
    task?.confidenceScoreReason,
    task?.effortScoreReason,
  ].filter(Boolean)

  const isEstimatedByPulse =
    importanceScoreReasons.length &&
    (!task.manualFields.includes('importance') ||
      !task.manualFields.includes('reachScore') ||
      !task.manualFields.includes('impactScore') ||
      !task.manualFields.includes('confidenceScore') ||
      !task.manualFields.includes('effortScore'))

  return (
    <PopoverButtonWithTooltip
      tooltipContentChildren={
        task.importance ? (
          <>
            Importance: <ScoreTitle score={task.importance} />
          </>
        ) : (
          'Set importance'
        )
      }
      getButtonProps={({ isOpen }) => ({
        variant: 'ghost',
        size: 'sm',
        state: isOpen ? 'active' : 'selected',
        theme: task.importance
          ? (SCORE_THEME[task.importance] as ComponentProps<
              typeof Button
            >['theme'])
          : 'secondary',
        children: <ImportanceIcon importance={task.importance} />,
      })}
      popoverContentChildren={
        <>
          <Label className='flex flex-row items-center gap-2'>
            Importance
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger className='text-muted-foreground flex items-center gap-2 text-xs'>
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </TooltipTrigger>
                <TooltipContent>
                  If left unset, the importance of your task is calculated by
                  averaging the RICE scores that are set. Higher Reach, Impact,
                  and Confidence scores increase the importance, while a higher
                  Effort score decreases the importance.
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </Label>
          <div className='flex gap-2'>
            {(Object.keys(SCORE_THEME) as Array<Score | null>)
              .concat(null)
              .map((importanceOption, index) => (
                <Button
                  key={index}
                  variant='ghost'
                  size='sm'
                  theme={
                    importanceOption
                      ? (SCORE_THEME[importanceOption] as ComponentProps<
                          typeof Button
                        >['theme'])
                      : 'secondary'
                  }
                  title={`${
                    importanceOption ? SCORE_TITLE[importanceOption] : 'No'
                  } importance`}
                  onClick={() => {
                    if (!task) {
                      return
                    }
                    updateTaskImportance({
                      id: task.id,
                      importance: importanceOption,
                    })
                  }}
                  state={
                    importanceOption === task.importance ? 'active' : 'selected'
                  }>
                  <ImportanceIcon importance={importanceOption} />
                </Button>
              ))}
          </div>
          <div className='grid w-full grid-cols-2 items-center'>
            <Label size='sm'>
              Reach{' '}
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <FontAwesomeIcon icon={faQuestionCircle} size='xs' />
                  </TooltipTrigger>
                  <TooltipContent>
                    The number of people or transactions affected by the task
                    within a given time frame.
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </Label>
            <ProgressButton
              selected={task?.reachScore}
              onClick={reachScore =>
                updateTaskImportance({ reachScore, id: task.id })
              }
            />

            <Label size='sm'>
              Impact{' '}
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <FontAwesomeIcon icon={faQuestionCircle} size='xs' />
                  </TooltipTrigger>
                  <TooltipContent>
                    The degree of change or benefit the project will have on the
                    targeted users or objectives.
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </Label>
            <ProgressButton
              selected={task?.impactScore}
              onClick={impactScore =>
                updateTaskImportance({ impactScore, id: task.id })
              }
            />

            <Label size='sm'>
              Confidence{' '}
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <FontAwesomeIcon icon={faQuestionCircle} size='xs' />
                  </TooltipTrigger>
                  <TooltipContent>
                    The level of certainty or evidence supporting the estimated
                    Reach and Impact.
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </Label>
            <ProgressButton
              selected={task?.confidenceScore}
              onClick={confidenceScore =>
                updateTaskImportance({ confidenceScore, id: task.id })
              }
            />

            <Label size='sm'>
              Effort{' '}
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <FontAwesomeIcon icon={faQuestionCircle} size='xs' />
                  </TooltipTrigger>
                  <TooltipContent>
                    The total amount of work required to complete the task.
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </Label>
            <ProgressButton
              selected={task?.effortScore}
              onClick={effortScore =>
                updateTaskImportance({ effortScore, id: task.id })
              }
            />
          </div>
          {isEstimatedByPulse ? (
            <EstimatedByPulseTooltip>
              {!task.manualFields.includes('importance') ? (
                <Label size='lg' className='text-center'>
                  Importance: <ScoreTitle score={task?.importance} />
                </Label>
              ) : null}
              <div className='grid grid-flow-row gap-2 [grid-template-columns:auto_1fr]'>
                {!task.manualFields.includes('reachScore') ? (
                  <ImportanceScoreReasonRow
                    title='Reach'
                    score={task?.reachScore}
                    reason={task?.reachScoreReason}
                  />
                ) : null}
                {!task.manualFields.includes('impactScore') ? (
                  <ImportanceScoreReasonRow
                    title='Impact'
                    score={task?.impactScore}
                    reason={task?.impactScoreReason}
                  />
                ) : null}
                {!task.manualFields.includes('confidenceScore') ? (
                  <ImportanceScoreReasonRow
                    title='Confidence'
                    score={task?.confidenceScore}
                    reason={task?.confidenceScoreReason}
                  />
                ) : null}
                {!task.manualFields.includes('effortScore') ? (
                  <ImportanceScoreReasonRow
                    title='Effort'
                    score={task?.effortScore}
                    reason={task?.effortScoreReason}
                  />
                ) : null}
              </div>
            </EstimatedByPulseTooltip>
          ) : null}
        </>
      }
    />
  )
}
