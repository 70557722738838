import { ERROR, SUCCESS, WARNING } from '@/shared/constants/color'
import { Score } from '@prisma/client'
import _ from 'lodash'

export const SCORE_ORDER = [Score.LOW, Score.MEDIUM, Score.HIGH]
export const SCORE_VALUE: Record<Score, number> = {
  LOW: 0,
  MEDIUM: 1,
  HIGH: 2,
}
export const VALUE_SCORE: Record<number, Score> = _.invert(
  SCORE_VALUE
) as Record<number, Score>

export const SCORE_TITLE = {
  [Score.LOW]: 'Low',
  [Score.MEDIUM]: 'Medium',
  [Score.HIGH]: 'High',
} as const
// NOTE: Must state the classes used by the theme for Tailwind to generate them
// text-success
// text-warning
// text-destructive
// border-success
// border-warning
// border-destructive
// bg-success/25
// bg-warning/25
// bg-destructive/25
export const SCORE_THEME: Record<Score, 'success' | 'warning' | 'destructive'> =
  {
    [Score.LOW]: 'success',
    [Score.MEDIUM]: 'warning',
    [Score.HIGH]: 'destructive',
  } as const
export const SCORE_COLOR: Record<Score, string> = {
  [Score.LOW]: SUCCESS,
  [Score.MEDIUM]: WARNING,
  [Score.HIGH]: ERROR,
} as const
