import { type GmailEmailType } from '@/shared/constants/gmail'
import {
  isArchivedEmail,
  isInboxEmail,
  isSpamEmail,
  isUnreadEmail,
} from '@/shared/utils/gmail'
import { Badge } from '@/web/components/ui/badge'
import { cn } from '@/web/libs/utils'

export const EmailSubject = ({
  subject,
  labels,
  emails,
  className,
}: {
  subject: string
  labels?: string[]
  emails: GmailEmailType[]
  className?: string
}) => (
  <div className='flex w-full flex-wrap items-center gap-x-4 gap-y-1 leading-normal'>
    <h6
      className={cn(
        'max-w-full shrink-0 whitespace-normal text-wrap leading-6',
        className
      )}>
      {subject}
    </h6>
    <div className='flex flex-wrap items-center gap-2'>
      {emails.some(email => email.isTaskOrigin) ? (
        <Badge
          size='sm'
          theme='primary'
          title='This email is the origin of the task.'>
          SOURCE EMAIL
        </Badge>
      ) : null}
      {emails.some(isInboxEmail) ? (
        <Badge size='sm' variant='outline' theme='primary'>
          INBOX
        </Badge>
      ) : null}
      {emails.some(isUnreadEmail) ? (
        <Badge size='sm' variant='outline' theme='primary'>
          UNREAD
        </Badge>
      ) : null}
      {emails.some(isSpamEmail) ? (
        <Badge size='sm' variant='outline' theme='destructive'>
          SPAM
        </Badge>
      ) : emails.some(isArchivedEmail) ? (
        <Badge size='sm' variant='outline' theme='success'>
          ARCHIVED
        </Badge>
      ) : null}
      {labels
        ?.filter(
          label => label !== 'INBOX' && label !== 'SPAM' && label !== 'UNREAD'
        )
        .map(label => (
          <Badge size='sm' key={label} variant='outline'>
            {label}
          </Badge>
        ))}
    </div>
  </div>
)
