import { getInitials } from '@/shared/utils/string'
import { isTaskWithAssignedUser } from '@/shared/utils/task'
import { Select } from '@/web/components/Select'
import { Avatar, AvatarFallback, AvatarImage } from '@/web/components/ui/avatar'
import { Button } from '@/web/components/ui/button'
import { DemoContext } from '@/web/contexts/demo'
import { TaskContext } from '@/web/contexts/task'
import { useUpdateTaskMutation } from '@/web/hooks/useUpdateTaskMutation'
import { api } from '@/web/utils/api'
import { UserCircle2 } from 'lucide-react'
import React, { useCallback, useContext } from 'react'
import { z } from 'zod'

export const TaskAssignedButton = () => {
  const task = useContext(TaskContext)

  const isDemo = useContext(DemoContext)

  const [isOpen, setIsOpen] = React.useState(false)

  const teamMembersQueryResult = api.team.members.useQuery(undefined, {
    enabled: !isDemo,
  })
  const updateTaskMutationResult = useUpdateTaskMutation()
  const assignUser = useCallback(
    (userId: string | null) => {
      if (!task?.id) return

      console.log('Assigning user %s to task %s', userId, task.id)
      updateTaskMutationResult.mutate({
        id: task.id,
        assignedUserId: userId || null,
      })
    },
    [task?.id, updateTaskMutationResult]
  )

  const inviteTeamMemberMutationResult = api.team.invite.useMutation({
    onSuccess: data => {
      teamMembersQueryResult.refetch()
      assignUser(data.userId)
    },
  })

  if (!task) {
    return null
  }

  const assignedUser = isTaskWithAssignedUser(task) && task.assignedUser
  return (
    <Select
      className='min-w-56'
      onOpenChange={setIsOpen}
      options={
        teamMembersQueryResult.data?.map(user => ({
          value: user.id,
          label: user.name ?? user.email,
        })) ?? []
      }
      isSearchable
      getEmptySearchOption={text => ({
        labelNode: text ? (
          <span>
            Invite and assign <span className='text-primary'>{text}</span>
          </span>
        ) : null,
        label: 'Enter an email to invite',
        value: text,
      })}
      placeholder='Assign to...'
      placeholderNoun='team members'
      onCreateOption={email => {
        if (!email || !z.string().email().safeParse(email).success)
          return 'Invalid email'

        console.log('Inviting user %s', email)
        inviteTeamMemberMutationResult.mutate({
          email,
        })
      }}
      onChange={userId => {
        assignUser(userId ?? null)
      }}
      canDeselect
      value={task.assignedUserId || undefined}
      tooltip={
        assignedUser ? (
          <>
            Assigned to{' '}
            <span className='text-primary'>
              {assignedUser.name ?? assignedUser.email}
            </span>
          </>
        ) : (
          'Assign to...'
        )
      }
      customButton={
        <Button
          variant='ghost'
          size='sm'
          state={isOpen ? 'active' : 'selected'}
          theme='secondary'
          onClick={e => {
            e.stopPropagation()
          }}>
          {assignedUser ? (
            <Avatar size='xs'>
              <AvatarImage
                src={assignedUser?.image ?? undefined}
                alt={assignedUser?.name ?? assignedUser?.email}
              />
              <AvatarFallback>
                {getInitials(assignedUser?.name ?? assignedUser?.email)}
              </AvatarFallback>
            </Avatar>
          ) : (
            <UserCircle2 className='text-muted-foreground size-4' />
          )}
        </Button>
      }
    />
  )
}
