import { getConversationsMessageCount } from '@/shared/utils/slack'
import { Conversations } from '@/web/components/Conversations'
import { TaskIntegrationContext } from '@/web/components/TaskIntegrationContext'
import { Card } from '@/web/components/ui/card'
import { IntegrationName } from '@prisma/client'
import pluralize from 'pluralize'

export const SlackTaskContext = () => {
  return (
    <TaskIntegrationContext
      integrationName={IntegrationName.SLACK}
      getTriggerText={({ count, data }) =>
        `${
          data ? getConversationsMessageCount(data.conversations) : count
        } related
        ${pluralize(
          data ? 'message' : 'conversation',
          data ? getConversationsMessageCount(data.conversations) : count
        )}`
      }>
      {data => (
        <Card variant='secondary' size='small'>
          <Conversations conversations={data.conversations} />
        </Card>
      )}
    </TaskIntegrationContext>
  )
}
