import { isTaskWithActions } from '@/shared/utils/task'
import { TaskAction } from '@/web/components/TaskAction'
import { TaskContext } from '@/web/contexts/task'
import { useContext } from 'react'

export const TaskActions = () => {
  const task = useContext(TaskContext)
  if (!isTaskWithActions(task) || !task?.actions?.length) {
    return null
  }

  return (
    <div className='flex flex-col flex-wrap gap-2'>
      {task.actions.map(action => (
        <TaskAction key={action.id} action={action} />
      ))}
    </div>
  )
}
