import { type GmailEmailType } from '@/shared/constants/gmail'
import { hasContextMetadata, isSummary } from '@/shared/utils/context'
import { ContextContent } from '@/web/components/ContextContent'
import { EmailThreadMessageFromAvatar } from '@/web/components/EmailThreadMessageFromAvatar'
import { EmailThreadMessageFromCcBcc } from '@/web/components/EmailThreadMessageFromCcBcc'
import { RawEmailWithAttachments } from '@/web/components/RawEmailWithAttachments'

export const EmailThreadMessage = ({ email }: { email: GmailEmailType }) => {
  return (
    /* eslint-disable prettier/prettier */
    <div className='grid grid-flow-row gap-x-2 gap-y-4 [grid-column:span_2] [grid-template-columns:auto_minmax(0,1fr)]'>
      <EmailThreadMessageFromAvatar
        email={email}
        className='mt-1 [grid-column:1]'
      />
      <EmailThreadMessageFromCcBcc
        email={email}
        className='flex-1 [grid-column:2]'
      />

      <ContextContent
        className='[grid-column:span_2] md:[grid-column:2]'
        contextMetadata={hasContextMetadata(email) ? email : undefined}
        summary={isSummary(email) ? email : undefined}
      />

      <RawEmailWithAttachments email={email} />
    </div>
  )
}
