'use client'

import { type MessageOptionsType } from '@/shared/constants/action'
import { type SlackMessage } from '@/shared/constants/slack'
import { Message } from '@/web/components/Message'
import { Button } from '@/web/components/ui/button'
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from '@/web/components/ui/card'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/web/components/ui/form'
import { Textarea } from '@/web/components/ui/textarea'
import { env } from '@/web/env'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as z from 'zod'

const FormSchema = z.object({
  message: z.string().min(1, 'Please enter a message'),
  optionIndex: z.number(),
})

export const SendMessageForm = ({
  originalMessage,
  to,
  text,
  options,
  onSend,
  isDemo,
}: {
  originalMessage?: SlackMessage | null
  to: string
  text?: string
  options: MessageOptionsType
  onSend: (text: string) => void
  isDemo?: boolean
}) => {
  const [isSending, setIsSending] = useState(false)
  const optionTextsRef = useRef<string[]>(options.map(option => option.message))

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      message: text || options[0]?.message || '',
      optionIndex: 0,
    },
  })

  const { watch, setValue } = form
  const optionIndex = watch('optionIndex')
  const message = watch('message')

  // Update the message field when the optionIndex changes
  useEffect(() => {
    const newText = optionTextsRef.current[optionIndex] || ''
    setValue('message', newText, { shouldValidate: true })
    console.log('Updating message after changing option index', {
      optionIndex,
      newText,
      options: optionTextsRef.current,
    })
  }, [optionIndex, options, setValue])

  // Update the option text when the message field changes
  useEffect(() => {
    console.log('Updating option text', { optionIndex, message })
    optionTextsRef.current[optionIndex] = message
  }, [message])

  const onSubmit = (data: z.infer<typeof FormSchema>) => {
    if (isDemo) {
      return
    }

    setIsSending(true)
    try {
      onSend(data.message)
    } catch (error) {
      console.error(error)
      setIsSending(false)
    }
  }

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const currentTexts = optionTextsRef.current
    currentTexts[optionIndex] = e.target.value
  }

  if (env.NEXT_PUBLIC_NODE_ENV === 'development') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      console.log({ state: form.formState })
    }, [form.formState])
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className='flex w-full flex-col gap-y-6'>
        <Card variant='secondary' size='small'>
          <FormField
            control={form.control}
            name='message'
            render={({ field }) => (
              <FormItem className='flex flex-col gap-2'>
                <CardHeader className='gap-2'>
                  {originalMessage && <Message message={originalMessage} />}
                  <FormLabel>
                    To: <span className='text-primary'>{to}</span>
                  </FormLabel>
                </CardHeader>
                <CardContent>
                  <FormControl>
                    <Textarea
                      placeholder={`Write a message to ${to}`}
                      className='resize-none'
                      {...field}
                      disabled={isSending}
                      onChange={e => {
                        field.onChange(e)
                        handleTextChange(e)
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </CardContent>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='optionIndex'
            render={({ field }) => (
              <FormItem>
                <section className='flex flex-row flex-wrap gap-2'>
                  {options.map((option, index) => (
                    <Button
                      key={option.title + index}
                      variant='outline'
                      size='xs'
                      type='button'
                      state={index === field.value ? 'active' : 'inactive'}
                      theme='secondary'
                      onClick={() => field.onChange(index)}
                      className='self-start text-xs'>
                      {option.title}
                    </Button>
                  ))}
                </section>
              </FormItem>
            )}
          />

          <CardFooter>
            <Button type='submit' disabled={isSending} size='sm'>
              <FontAwesomeIcon icon={faPaperPlane} />{' '}
              {isSending ? 'Sending...' : 'Send'}
            </Button>
          </CardFooter>
        </Card>
      </form>
    </Form>
  )
}
