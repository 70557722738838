import { EVENT } from '@/shared/constants/analytics'
import dayjs from '@/shared/singletons/dayjs'
import * as taskUtil from '@/shared/utils/task'
import { getTimeSaved, getTimeSavedString } from '@/shared/utils/task'
import { Button } from '@/web/components/ui/button'
import { UserAvatar } from '@/web/components/UserAvatar'
import { type TaskFromQuery } from '@/web/constants/task'
import { env } from '@/web/env'
import { useUserQueryResult } from '@/web/hooks/use-user-query-result'
import { cn } from '@/web/libs/utils'
import { track } from '@/web/utils/analytics'
import { api } from '@/web/utils/api'
import {
  faCheckCircle,
  faClockFour,
  faThumbsDown,
  faThumbsUp,
} from '@fortawesome/free-regular-svg-icons'
import {
  faBug,
  faPlus,
  faRefresh,
  faWandMagicSparkles,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Feedback, Role, type Task } from '@prisma/client'
import _ from 'lodash'
import { useSession } from 'next-auth/react'

export const TaskCardFooter = ({
  task,
  isDemo,
  setIsOpen,
}: {
  task?: TaskFromQuery | null
  isDemo?: boolean
  setIsOpen?: (isOpen: boolean) => void
}) => {
  const utils = api.useUtils()

  const runUpdateTaskJobMutation = api.task.runUpdateJob.useMutation()

  const session = useSession()

  const taskAuthorQueryResult = useUserQueryResult(task?.authorId ?? '-1', {
    enabled: !!task?.authorId && task?.authorId !== session.data?.user.id,
  })

  const setFeedbackMutation = api.task.setFeedback.useMutation({
    onMutate(variables) {
      if (!task || !variables || isDemo) {
        return
      }

      track(EVENT.SUBMIT_TASK_FEEDBACK, {
        integration: task.integrationName,
        taskId: task.id,
        feedback: variables.feedback,
      })

      // Add bad feedback to archived list
      if (variables.feedback === Feedback.BAD) {
        utils.tasks.count.setData(
          { type: taskUtil.isTodo(task) ? 'todo' : 'completed' },
          data => (data ? data - 1 : data)
        )
      }
      // Add back to todo list if it was bad
      else if (task.feedback === Feedback.BAD) {
        utils.tasks.count.setData(
          { type: taskUtil.isTodo(task) ? 'todo' : 'completed' },
          data => (data ? data + 1 : data)
        )
      }

      return {
        optimisticData: {
          ...task,
          feedback: variables.feedback,
        } satisfies Task,
        rollbackData: task,
      }
    },
    onSettled: () => {
      utils.tasks.count.invalidate()
      utils.tasks.orderedList.invalidate()
    },
  })

  if (!task) {
    return null
  }

  const onClickFeedback = (feedback: Feedback) => {
    if (!task?.id || isDemo) {
      return
    }

    if (task.feedback === feedback) {
      setFeedbackMutation.mutate({
        taskId: task.id,
        feedback: null,
      })
      return
    }

    setFeedbackMutation.mutate({
      taskId: task.id,
      feedback,
    })

    if (feedback === Feedback.BAD) {
      console.info('Closing task due to bad feedback')
      setIsOpen?.(false)
    }
  }

  const tokenCount = taskUtil.getTokenCount(task)
  const isShowingTokenCount = env.NEXT_PUBLIC_DEBUG === 'true' && tokenCount
  const isShowingTimeSaved = !!getTimeSaved(task)
  const taskAuthor =
    task.authorId === session.data?.user.id
      ? session.data?.user
      : taskAuthorQueryResult.data

  return (
    <div className='flex flex-col items-center justify-between gap-2 sm:flex-row'>
      <div className='text-muted-foreground/75 flex flex-1 flex-row flex-wrap items-center gap-x-4 gap-y-1'>
        <small
          className='flex items-center justify-center gap-1'
          suppressHydrationWarning
          title={new Intl.DateTimeFormat('en-US', {
            dateStyle: 'full',
            timeStyle: 'full',
          }).format(task.createdAt)}>
          {taskAuthor ? (
            <UserAvatar user={taskAuthor} size='xs' />
          ) : (
            <FontAwesomeIcon icon={faPlus} />
          )}
          created {dayjs(task.createdAt).fromNow()}
        </small>
        {task.expiryDate && task.expiryDate < new Date() && (
          <small
            className={cn(
              'text-warning flex items-center justify-center gap-1'
            )}>
            <FontAwesomeIcon icon={faClockFour} />
            expired {dayjs(task.expiryDate).fromNow()}
          </small>
        )}
        {task.completedDate ? (
          <small className='flex items-center justify-center gap-1'>
            <FontAwesomeIcon icon={faCheckCircle} />
            completed {dayjs(task.completedDate).fromNow()}
          </small>
        ) : null}
        {isShowingTimeSaved ? (
          <small className='flex items-center justify-center gap-1'>
            <FontAwesomeIcon icon={faWandMagicSparkles} />
            saves {getTimeSavedString(task)}
          </small>
        ) : null}
        {/* {task.history?.length && task.history[0] ? ( */}
        {task.createdAt !== task.updatedAt ? (
          <small className='flex items-center justify-center gap-1'>
            <FontAwesomeIcon icon={faRefresh} />
            updated {dayjs(task.updatedAt).fromNow()}
          </small>
        ) : null}
        {env.NEXT_PUBLIC_NODE_ENV !== 'production' ||
        session.data?.user?.role === Role.ADMIN ? (
          <Button
            variant='link'
            theme='secondary'
            onClick={() => runUpdateTaskJobMutation.mutate(task.id)}
            startIcon={<FontAwesomeIcon icon={faRefresh} />}
            disabled={runUpdateTaskJobMutation.isLoading}
            size='xs'>
            Update
          </Button>
        ) : null}
        {isShowingTokenCount ? (
          <>
            <small className={cn('flex items-center justify-center gap-1')}>
              <FontAwesomeIcon icon={faBug} />
              {tokenCount} tokens
            </small>
            <small className={cn('flex items-center justify-center gap-0')}>
              <FontAwesomeIcon icon={faBug} /> $
              {_.round((tokenCount * 0.002) / 1000, 4)}
            </small>
          </>
        ) : null}
      </div>
      {!task.authorId ? (
        <div className='flex w-full flex-row items-center justify-around gap-2 sm:w-auto sm:justify-end sm:self-end'>
          <Button
            variant='ghost'
            theme='success'
            size='xs'
            state={task.feedback === Feedback.GOOD ? 'active' : 'inactive'}
            // className='flex-1'
            onClick={() => onClickFeedback(Feedback.GOOD)}
            startIcon={<FontAwesomeIcon icon={faThumbsUp} />}>
            I like this task
          </Button>
          <Button
            variant='ghost'
            theme='destructive'
            size='xs'
            state={task.feedback === Feedback.BAD ? 'active' : 'inactive'}
            // className='flex-1'
            onClick={() => onClickFeedback(Feedback.BAD)}
            startIcon={<FontAwesomeIcon icon={faThumbsDown} />}>
            I dislike this task
          </Button>
        </div>
      ) : null}
    </div>
  )
}
