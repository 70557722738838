import { type SlackConversation } from '@/shared/constants/slack'
import { Conversation } from '@/web/components/Conversation'
import _ from 'lodash'

export const Conversations = ({
  conversations,
}: {
  conversations?: SlackConversation[] | null
}) =>
  conversations
    ? _.sortBy(conversations, conversation =>
        conversation.lastUpdatedDate
          ? new Date(conversation.lastUpdatedDate)
          : null
      ).map((conversation, index) => (
        <Conversation
          key={conversation.id}
          conversation={conversation}
          isDefaultOpen={index === 0 && conversations.length === 1}
        />
      ))
    : null
