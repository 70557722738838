import { type InfiniteData } from '@tanstack/react-query'
import _ from 'lodash'

export function updateInfiniteData<
  TItem extends object,
  TData extends { items: TItem[] },
>(
  data?: InfiniteData<TData>,
  itemUpdater?: (item: TData['items'][number]) => TData['items'][number]
) {
  if (!data) {
    return data
  }

  return {
    ...data,
    pages: data.pages.map(page => ({
      ...page,
      items: page.items.map(item => itemUpdater?.(item) || item),
    })),
  }
}

export function prependInfiniteData<
  TItem extends { id?: string },
  TData extends { items: TItem[] },
>(
  data?: InfiniteData<TData>,
  itemToAdd?: TItem
): InfiniteData<TData> | undefined {
  return addToInfiniteData(data, itemToAdd, false)
}

export function appendInfiniteData<
  TItem extends { id?: string },
  TData extends { items: TItem[] },
>(
  data?: InfiniteData<TData>,
  itemToAdd?: TItem
): InfiniteData<TData> | undefined {
  return addToInfiniteData(data, itemToAdd, true)
}

export function addToInfiniteData<
  TItem extends { id?: string },
  TData extends { items: TItem[] },
>(
  data?: InfiniteData<TData>,
  itemToAdd?: TItem,
  isAppending?: boolean
): InfiniteData<TData> | undefined {
  if (!data) {
    console.log('No infinite data to add item to', itemToAdd)
    return data
  }
  // Don't re-add if exists
  if (
    data.pages.some(page => page.items.some(item => item.id === itemToAdd?.id))
  ) {
    console.log('Item %o already exists in %O', itemToAdd, data)
    return data
  }

  console.log('Adding item to infinite data', itemToAdd)
  const updatedData = {
    ...data,
    pages: data.pages.length
      ? data.pages.map((page, pageIndex) => ({
          ...page,
          items:
            isAppending && pageIndex === 0
              ? [...page.items, itemToAdd]
              : !isAppending && pageIndex === data.pages.length - 1
              ? [itemToAdd, ...page.items]
              : page.items,
          // totalCount: page.totalCount + 1,
        }))
      : [{ items: [itemToAdd] }],
  } as InfiniteData<TData>

  console.log('Updated infinite data', updatedData)

  return updatedData
}

export function removeFromInfiniteData<
  TItem extends object,
  TData extends { items: TItem[] },
>(
  data?: InfiniteData<TData>,
  itemToRemove?: TItem | null,
  { isExactMatch = true } = {}
): InfiniteData<TData> | undefined {
  if (!data || !itemToRemove) {
    return data
  }

  const updatedData = {
    ...data,
    pages: data.pages.map(page => ({
      ...page,
      items: page.items?.filter(item => {
        const isRemovedItem = isExactMatch
          ? _.isEqual(item, itemToRemove)
          : _.isMatch(item, itemToRemove)
        if (isRemovedItem) {
          console.log('Removed item from infinite data', itemToRemove, data)
        }
        return !isRemovedItem
      }),
      // totalCount: page.totalCount - 1,
    })),
  }

  return updatedData
}
