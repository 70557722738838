import { NotificationDot } from '@/web/components/NotificationDot'
import { TaskStatusCheckboxGroup } from '@/web/components/TaskStatusCheckboxGroup'
import { Card, type CardVariants } from '@/web/components/ui/card'
import { cn } from '@/web/libs/utils'
import { type TaskStatus } from '@prisma/client'
import React, {
  forwardRef,
  useEffect,
  useState,
  type PropsWithChildren,
} from 'react'

type PropsType = PropsWithChildren<{
  isCurrent?: boolean
  isUnread?: boolean
  isCompleted?: boolean
  isErrored?: boolean
  isWarning?: boolean
  isOpen?: boolean
  onChangeStatus?: (status: TaskStatus) => void
  Icon?: React.ReactNode
  visibilityRef?: React.MutableRefObject<HTMLDivElement | null>
  variant?: CardVariants['variant']
  className?: string
  onClick?: () => void
  status?: TaskStatus
  taskId?: string
}>
export const TaskCardLayout = forwardRef<HTMLDivElement | null, PropsType>(
  (
    {
      isCurrent,
      isUnread,
      isCompleted,
      isErrored,
      isWarning,
      children,
      Icon,
      visibilityRef,
      variant,
      className,
      onClick,
      taskId,
    },
    ref
  ) => {
    if (!ref) {
      ref = React.createRef()
    }

    const [wasCurrent, setWasCurrent] = useState(isCurrent)
    useEffect(() => {
      let timeout: NodeJS.Timeout

      if (!wasCurrent && isCurrent) {
        setWasCurrent(true)
      } else if (wasCurrent && !isCurrent) {
        timeout = setTimeout(() => setWasCurrent(false), 5000)
      }

      return () => {
        timeout && clearTimeout(timeout)
      }
    }, [isCurrent])

    // Focus/scroll to the task if it's the current one
    useEffect(() => {
      if (!isCurrent) {
        return
      }

      if (!visibilityRef) {
        return
      }

      if (document.activeElement instanceof HTMLElement)
        document.activeElement?.blur?.()
      visibilityRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
      visibilityRef.current?.focus({ preventScroll: true })
    }, [isCurrent])

    return (
      <Card
        variant={
          variant ??
          (isCurrent
            ? 'primary'
            : isCompleted
            ? 'success'
            : isErrored
            ? 'error'
            : isWarning
            ? 'warning'
            : 'default')
        }
        size='small'
        className={cn(
          'relative grid min-h-[68px] grid-flow-row gap-x-4 gap-y-0 py-0 [grid-template-columns:min-content_minmax(0,1fr)] md:[grid-template-rows:1fr_auto]',
          className
        )}
        onClick={onClick}
        ref={instance => {
          if (typeof ref === 'function') {
            ref(instance)
          } else if (ref) {
            ref.current = instance
          }

          if (!visibilityRef) {
            return
          }
          visibilityRef.current = instance
        }}>
        {/* eslint-disable-next-line prettier/prettier */}
        <div className='z-1 group relative flex items-center justify-center [grid-column:1] [grid-row:1]'>
          {Icon ?? (taskId ? <TaskStatusCheckboxGroup /> : null)}
        </div>
        {children}
        {isUnread ? (
          <NotificationDot className='absolute -left-1 -top-1 z-10' size='sm' />
        ) : null}
      </Card>
    )
  }
)
TaskCardLayout.displayName = 'TaskCardLayout'
