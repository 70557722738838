import { type GmailEmailType } from '@/shared/constants/gmail'
import { EmailThread } from '@/web/components/EmailThread'

export const Email = ({
  email,
  isDefaultOpen = true,
  isAlwaysOpen = false,
  className,
}: {
  email: GmailEmailType
  isDefaultOpen?: boolean
  isAlwaysOpen?: boolean
  className?: string
}) => (
  <EmailThread
    subject={email.subject}
    labels={email.labels}
    emails={[email]}
    isDefaultOpen={isDefaultOpen}
    isAlwaysOpen={isAlwaysOpen}
    className={className}
  />
)
