import { type GmailEmailType } from '@/shared/constants/gmail'
import { getContact } from '@/shared/utils/gmail'
import { getInitials } from '@/shared/utils/string'
import { Avatar, AvatarFallback, AvatarImage } from '@/web/components/ui/avatar'

export const EmailThreadMessageFromAvatar = ({
  email,
  className,
}: {
  email: GmailEmailType
  className?: string
}) => {
  const fromContact = getContact({
    emailString: email.from,
    contacts: email.contacts,
  })
  const fromPhoto = fromContact?.photoUrl

  return (
    <Avatar className={className} title={email.from}>
      {fromPhoto ? (
        <AvatarImage
          src={fromPhoto}
          alt={fromContact.name ?? fromContact.email ?? ''}
        />
      ) : null}
      <AvatarFallback>
        {getInitials(fromContact?.name ?? fromContact?.email ?? email.from)}
      </AvatarFallback>
    </Avatar>
  )
}
