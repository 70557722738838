import { type GmailEmailType } from '@/shared/constants/gmail'
import {
  getUniqueEmailStrings,
  isMatchingEmailString,
} from '@/shared/utils/email'
import { Email } from '@/web/components/Email'
import { Select } from '@/web/components/Select'
import { Button } from '@/web/components/ui/button'
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from '@/web/components/ui/card'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/web/components/ui/form'
import { Label } from '@/web/components/ui/label'
import MultipleSelector from '@/web/components/ui/multiple-selector'
import { Textarea } from '@/web/components/ui/textarea'
import { env } from '@/web/env'
import { api } from '@/web/utils/api'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState, type ComponentProps } from 'react'
import { useForm } from 'react-hook-form'
import * as z from 'zod'

const FormSchema = z.object({
  message: z.string().min(1),
  subject: z.string().min(1),
  from: z.string().min(1),
  to: z.array(z.string().min(1)).min(1),
})

export const SendEmailForm = ({
  originalEmail,
  subject,
  from,
  fromOptions,
  to,
  toOptions,
  text,
  onSend,
  error,
}: {
  originalEmail?: GmailEmailType | null
  subject?: string
  from?: string | null
  fromOptions?: string[]
  to?: string[]
  toOptions?: string[]
  text?: string
  onSend: (
    {
      message,
      subject,
      from,
      to,
    }: { message: string; subject: string; from: string; to: string[] },
    { isArchiving }: { isArchiving: boolean }
  ) => void
  error?: string
}) => {
  const [toSearchTerm, setToSearchTerm] = useState<string | null>(null)
  const searchRecipientsGmailQueryResult = api.gmail.searchRecipients.useQuery(
    toSearchTerm ?? '',
    {
      enabled: !!toSearchTerm,
    }
  )

  const [isSending, setIsSending] = useState(false)

  const defaultFrom = fromOptions
    ? fromOptions.find(option => isMatchingEmailString(option, from)) ??
      fromOptions[0]
    : from ?? undefined

  const toOptionObjects = getUniqueEmailStrings([
    ...(toOptions ?? []),
    ...(to ?? []),
    ...(originalEmail?.from ? [originalEmail.from] : []),
  ]).map(email => ({
    label: email,
    value: email,
  }))

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      message: text,
      subject,
      from: defaultFrom,
      to,
    },
  })

  const canEditSubject = !originalEmail

  const onSubmit = (
    data: z.infer<typeof FormSchema>,
    options: Parameters<ComponentProps<typeof SendEmailForm>['onSend']>[1]
  ) => {
    onSend(data, options)
    setIsSending(true)
  }

  if (env.NEXT_PUBLIC_NODE_ENV === 'development') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      console.log('Form state', { state: form.formState })
    }, [form.formState])
  }

  // Update `from` whenever it changes
  useEffect(() => {
    if (!from) return

    console.log('Updating `from`', from)
    form.setValue('from', from)
  }, [from, form])

  // Update `from` whenever `fromOptions` changes
  useEffect(() => {
    if (!fromOptions || !defaultFrom) return

    console.log('Updating `from` with `fromOptions`', {
      fromOptions,
      defaultFrom,
    })
    form.setValue('from', defaultFrom)
  }, [fromOptions, defaultFrom])

  const canSend = !isSending || error
  const canArchive = !!originalEmail
  return (
    <Form {...form}>
      <form className='flex w-full flex-col space-y-6' autoComplete='off'>
        <Card variant='secondary' size='small'>
          <CardHeader className='grid gap-2 [grid-template-columns:auto_1fr]'>
            {originalEmail && (
              <Email
                email={originalEmail}
                isDefaultOpen={false}
                className='[grid-column:span_2]'
              />
            )}
            {subject ? (
              <FormField
                control={form.control}
                name='subject'
                render={({ field }) => (
                  <FormItem asChild>
                    <FormLabel
                      htmlFor={field.name}
                      className='grid items-center [grid-column:span_2] [grid-template-columns:subgrid]'>
                      <span>Subject:</span>
                      {canEditSubject ? (
                        <Textarea
                          placeholder={subject}
                          {...field}
                          variant='ghost'
                          theme='secondary'
                          size='none'
                          autoCapitalize='on'
                          autoCorrect='on'
                          autoComplete='off'
                          spellCheck='true'
                          inputMode='text'
                          className='text-sm'
                          id={field.name}
                        />
                      ) : (
                        <span className='text-secondary-foreground'>
                          {subject}
                        </span>
                      )}
                    </FormLabel>
                  </FormItem>
                )}
              />
            ) : null}

            <FormField
              control={form.control}
              name='from'
              render={({ field }) => (
                <FormItem asChild>
                  <FormLabel
                    className='grid items-center [grid-column:span_2] [grid-template-columns:subgrid]'
                    htmlFor={field.name}>
                    <span>From:</span>
                    {fromOptions ? (
                      <Select
                        buttonProps={{
                          variant: 'ghost',
                          size: 'none',
                          theme: 'secondary-dark',
                          state: 'selected',
                          className: 'rounded-none',
                          id: field.name,
                        }}
                        options={fromOptions.map(fromOption => ({
                          label: fromOption,
                          value: fromOption,
                        }))}
                        placeholder='Select “from” email...'
                        placeholderNoun='“from” emails'
                        {...field}
                      />
                    ) : (
                      <span className='text-primary'>{field.value}</span>
                    )}
                  </FormLabel>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name='to'
              render={({ field }) => (
                <FormItem asChild>
                  <FormLabel
                    className='grid items-center [grid-column:span_2] [grid-template-columns:subgrid]'
                    htmlFor={field.name}>
                    <span>To:</span>
                    <MultipleSelector
                      onSearch={searchTerm => {
                        setToSearchTerm(searchTerm)
                      }}
                      inputProps={{ id: field.name }}
                      searchResults={
                        !!toSearchTerm
                          ? searchRecipientsGmailQueryResult.data?.map(
                              emailString => ({
                                label: emailString,
                                value: emailString,
                              })
                            )
                          : undefined
                      }
                      isLoading={searchRecipientsGmailQueryResult.isFetching}
                      loadingIndicator={
                        <p className='text-muted-foreground py-2 text-center text-lg leading-10'>
                          Searching contacts...
                        </p>
                      }
                      variant='ghost'
                      size='none'
                      className='rounded-none'
                      defaultOptions={toOptionObjects}
                      placeholder='Add recipients...'
                      creatable
                      emptyIndicator={
                        <p className='text-center text-lg leading-10 text-gray-600 dark:text-gray-400'>
                          {!!toSearchTerm
                            ? 'No results found.'
                            : 'Enter a name or email...'}
                        </p>
                      }
                      value={field.value.map(email => ({
                        label: email,
                        value: email,
                      }))}
                      onChange={emailOptions =>
                        field.onChange(emailOptions.map(option => option.value))
                      }
                    />
                  </FormLabel>
                </FormItem>
              )}
            />
          </CardHeader>
          <CardContent>
            <FormField
              control={form.control}
              name='message'
              render={({ field }) => (
                <FormItem asChild>
                  <FormControl>
                    <Textarea
                      placeholder={`Write a message to ${
                        Array.isArray(to) ? to.join(', ') : to
                      }`}
                      {...field}
                      disabled={!canSend}
                    />
                  </FormControl>
                  {/* <small className='text-muted-foreground text-right'>
                    Use{' '}
                    <Link href='https://www.markdownguide.org/basic-syntax/'>
                      <Button variant='link' size='sm'>
                        markdown
                      </Button>
                    </Link>{' '}
                    to format your message.
                  </small> */}
                  <FormMessage />
                </FormItem>
              )}
            />
          </CardContent>

          <CardFooter className='flex flex-col items-start gap-4'>
            {error ? <Label variant='error'>{error}</Label> : null}
            <div className='flex flex-row items-start gap-4'>
              {canArchive && (
                <Button
                  type='submit'
                  disabled={!canSend}
                  size='sm'
                  onClick={() =>
                    onSubmit(form.getValues(), { isArchiving: true })
                  }>
                  <FontAwesomeIcon icon={faPaperPlane} />{' '}
                  {!canSend ? 'Sending...' : 'Send & Archive'}
                </Button>
              )}
              <Button
                type='button'
                theme={canArchive ? 'secondary' : 'primary'}
                disabled={!canSend}
                size='sm'
                onClick={() =>
                  onSubmit(form.getValues(), { isArchiving: false })
                }>
                <FontAwesomeIcon icon={faPaperPlane} />{' '}
                {!canSend ? 'Sending...' : 'Send'}
              </Button>
            </div>
          </CardFooter>
        </Card>
      </form>
    </Form>
  )
}
