import { isTask, isTaskWithContextItemsAndActions } from '@/shared/utils/task'
import { DemoContext } from '@/web/contexts/demo'
import {
  api,
  type ReactQueryOptions,
  type RouterInputs,
  type RouterOutputs,
} from '@/web/utils/api'
import { getDenormalizedObject } from '@/web/utils/normy'
import { useQueryNormalizer } from '@normy/react-query'
import { useWhatChanged } from '@simbathesailor/use-what-changed'
import { useContext, useEffect } from 'react'

export const useTaskQueryResult = (
  id: RouterInputs['task']['get'],
  options: ReactQueryOptions['task']['get'] = {}
) => {
  const utils = api.useUtils()
  const { getNormalizedData } = useQueryNormalizer()
  const cachedTask = getDenormalizedObject(getNormalizedData(), id)
  if (isTask(cachedTask) && !utils.task.get.getData(id)) {
    console.log('Setting cached task', cachedTask)
    utils.task.get.setData(id, cachedTask as RouterOutputs['task']['get'])
  }

  const isDemo = useContext(DemoContext)
  const getTaskQueryResult = api.task.get.useQuery(id, {
    enabled: !isDemo && id != '-1',
    ...options,
  })

  const hasContextItemsAndActions = isTaskWithContextItemsAndActions(
    getTaskQueryResult.data
  )
  useWhatChanged(
    [
      id,
      getTaskQueryResult.isLoading,
      getTaskQueryResult.isFetching,
      getTaskQueryResult.data,
      hasContextItemsAndActions,
    ],
    'id, getTaskQueryResult.isLoading, getTaskQueryResult.isFetching, getTaskQueryResult.data, hasContextItemsAndActions'
  )
  useEffect(() => {
    if (
      getTaskQueryResult.data &&
      !getTaskQueryResult.isLoading &&
      !getTaskQueryResult.isFetching &&
      !hasContextItemsAndActions &&
      getTaskQueryResult.isStale
    ) {
      console.log(
        'Invalidating task %o to fill in context items and actions',
        id,
        getTaskQueryResult
      )
      utils.task.get.invalidate(id)
    }
  }, [
    hasContextItemsAndActions,
    getTaskQueryResult.data,
    getTaskQueryResult.isLoading,
    getTaskQueryResult.isFetching,
  ])

  return getTaskQueryResult as ReturnType<
    (typeof api)['task']['get']['useQuery']
  > & {
    data:
      | RouterOutputs['task']['get']
      | RouterOutputs['tasks']['orderedList']['items'][number]
  }
}
