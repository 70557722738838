import {
  INTEGRATION_DATA,
  type IntegrationData,
} from '@/web/constants/integration'
import { TaskContext } from '@/web/contexts/task'
import { useContext } from 'react'

export const TaskIntegrationIcon = (
  props: Partial<Parameters<IntegrationData['Icon']>[0]>
) => {
  const task = useContext(TaskContext)
  if (!task || !task.integrationName) {
    return null
  }

  return INTEGRATION_DATA[task.integrationName].Icon({
    isColored: true,
    width: 16,
    height: 16,
    ...props,
  })
}
