import {
  type NewOrExistingTask,
  type TaskWithRelations,
  type UpdateTaskInputType,
} from '@/shared/constants/task'
import dayjs from '@/shared/singletons/dayjs'
import { calculateScore, invertScore } from '@/shared/utils/score'
import { getTimeScore } from '@/shared/utils/task'
import { Score, type Task } from '@prisma/client'
import _ from 'lodash'

// CHECKERS //

export function shouldEstimateHoursAndMinutes(task: Task): boolean {
  return (
    !task.manualFields.includes('hours') &&
    !task.manualFields.includes('minutes') &&
    task.hours == null &&
    task.minutes == null
  )
}

export function hasAllImportanceScores(task: Task): boolean {
  return (
    !!task.reachScore &&
    !!task.impactScore &&
    !!task.confidenceScore &&
    !!task.effortScore
  )
}
export function shouldEstimateImportance(task: Task): boolean {
  return (
    (!task.manualFields.includes('importance') && task.importance == null) ||
    !hasAllImportanceScores(task)
  )
}

export function shouldEstimateUrgency(task: Task): boolean {
  return !task.manualFields.includes('urgency') && task.urgency == null
}

export function shouldEstimatePriority(task: Task): boolean {
  return !task.manualFields.includes('priority') && task.priority == null
}

//////////////////
// CALCULATIONS //
//////////////////

export function calculateImportance(
  task?: NewOrExistingTask | null
): Score | null {
  if (!task) {
    return null
  }

  const scores = [
    task.confidenceScore,
    task.impactScore,
    task.reachScore,
    invertScore(task.effortScore),
  ].filter(x => x != null) as Score[]

  return calculateScore(scores)
}

export function getImportance(
  task?: NewOrExistingTask | null
): Task['importance'] | null {
  if (
    task?.importance ||
    (task &&
      'manualFields' in task &&
      task?.manualFields?.includes('importance'))
  ) {
    return task?.importance ?? null
  }

  return calculateImportance(task)
}

export function getDueDateScore(dueDate?: Date | string | null): Score | null {
  if (!dueDate) {
    return null
  }

  const daysUntilDue = dayjs(dueDate).diff(new Date(), 'day')
  return daysUntilDue <= 1
    ? Score.HIGH
    : daysUntilDue <= 7
    ? Score.MEDIUM
    : Score.LOW
}

export function calculateUrgency(
  task?: NewOrExistingTask | null
): Score | null {
  return getDueDateScore(task?.dueDate)
}

export function getUrgency(
  task?: NewOrExistingTask | null
): Task['urgency'] | null {
  if (
    task?.urgency ||
    (task && 'manualFields' in task && task?.manualFields?.includes('urgency'))
  ) {
    return task?.urgency ?? null
  }

  return calculateUrgency(task)
}

export function calculatePriority(
  task?: NewOrExistingTask | null
): Score | null {
  if (!task) {
    return null
  }

  const importance = getImportance(task)
  const urgency = getUrgency(task)

  const scores = [importance, urgency].filter(x => x != null) as Score[]
  return calculateScore(scores)
}

export function getPriority(task: NewOrExistingTask): Task['priority'] | null {
  if (
    task?.priority ||
    (task && 'manualFields' in task && task?.manualFields?.includes('priority'))
  ) {
    return task?.priority ?? null
  }

  return calculatePriority(task)
}

export function fillMissingPriorityFields<T extends TaskWithRelations>(
  task: T
): T {
  return {
    ...task,
    time: getTimeScore(task),
    importance: getImportance(task),
    urgency: getUrgency(task),
    priority: getPriority(task),
  }
}

export function autoUpdatePriorityFields(
  task: Task,
  { id, ...variables }: UpdateTaskInputType
): UpdateTaskInputType {
  variables.manualFields = _.uniq(
    (task?.manualFields || []).concat(...Object.keys(variables))
  ) as string[]

  // Importance
  const updatedImportance = calculateImportance({
    ...task,
    ...variables,
  })
  if (
    !variables.hasOwnProperty('importance') &&
    updatedImportance !== task.importance &&
    !task.manualFields.includes('importance')
  ) {
    variables.importance = calculateImportance({
      ...task,
      ...variables,
    })
    console.log(
      'Auto-updated importance for %o before task update',
      task.title,
      { task, variables }
    )
  }

  // Urgency
  const updatedUrgency = calculateUrgency({
    ...task,
    ...variables,
  })
  if (
    !variables.hasOwnProperty('urgency') &&
    updatedUrgency !== task.urgency &&
    !task.manualFields.includes('urgency')
  ) {
    variables.urgency = calculateUrgency({
      ...task,
      ...variables,
    })
    console.log('Auto-updated urgency for %o before task update', task.title, {
      task,
      variables,
    })
  }

  // Priority
  const updatedPriority = calculatePriority({
    ...task,
    ...variables,
  })
  if (
    !variables.hasOwnProperty('priority') &&
    task.priority !== updatedPriority &&
    !task.manualFields.includes('priority')
  ) {
    variables.priority = updatedPriority
    console.log('Auto-updated priority for %o before task update', task.title, {
      task,
      variables,
    })
  }

  return { id, ...variables }
}
