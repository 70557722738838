import { EVENT } from '@/shared/constants/analytics'
import {
  CreateTaskInput,
  type CreateTaskInputType,
  type UpdateTaskInputType,
} from '@/shared/constants/task'
import { KeyboardShortcut } from '@/web/components/KeyboardShortcut'
import { Button } from '@/web/components/ui/button'
import { Form, FormField } from '@/web/components/ui/form'
import { type Input } from '@/web/components/ui/input'
import { Textarea } from '@/web/components/ui/textarea'
import { KEYBOARD_SHORTCUT } from '@/web/constants/keyboard'
import { DemoContext } from '@/web/contexts/demo'
import { TaskContext } from '@/web/contexts/task'
import { cn } from '@/web/libs/utils'
import { currentTaskIdState } from '@/web/state/current-task-id'
import { track } from '@/web/utils/analytics'
import { api, type RouterInputs } from '@/web/utils/api'
import { createTemporaryTask } from '@/web/utils/task'
import { prependInfiniteData, removeFromInfiniteData } from '@/web/utils/trpc'
import { zodResolver } from '@hookform/resolvers/zod'
import { type Task } from '@prisma/client'
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  type ComponentProps,
} from 'react'
import { useForm } from 'react-hook-form'
import { useSetRecoilState } from 'recoil'
import { useDebouncedCallback } from 'use-debounce'

type PropsType = {
  className?: string
  variant?: ComponentProps<typeof Input>['variant']
  size?: ComponentProps<typeof Textarea>['size']
  tasksQueryOptions?: RouterInputs['tasks']['orderedList']
}

export const TaskTitleForm = forwardRef<HTMLTextAreaElement, PropsType>(
  function TaskTitleForm(
    { className, variant = 'ghost', size = 'lg', tasksQueryOptions }: PropsType,
    forwardedRef
  ) {
    const task = useContext(TaskContext)
    const isDemo = useContext(DemoContext)
    const setCurrentTaskId = useSetRecoilState(currentTaskIdState)

    const isNewTask = !task

    const form = useForm<CreateTaskInputType>({
      defaultValues: {
        title: task?.title ?? '',
      },
      resolver: zodResolver(CreateTaskInput),
    })
    const utils = api.useUtils()
    const [temporaryTask, setTemporaryTask] = React.useState<Task | null>(null)
    const createTaskMutation = api.task.create.useMutation({
      onMutate(variables) {
        const newTemporaryTask = createTemporaryTask(variables)
        setTemporaryTask(newTemporaryTask)
        utils.tasks.orderedList.setInfiniteData(
          {
            ...tasksQueryOptions,
            type: 'todo',
            creatorFilter: 'byYou',
          },
          data => prependInfiniteData(data, newTemporaryTask)
        )
        utils.tasks.count.setData({ type: 'todo' }, data =>
          data ? data + 1 : data
        )
        track(EVENT.CREATE_TASK, {
          title: variables.title,
        })
        setCurrentTaskId(newTemporaryTask.id)
      },
      onError() {
        utils.tasks.count.setData(
          { type: 'todo', creatorFilter: 'byYou' },
          data => (data ? data - 1 : data)
        )
        form.reset({
          title: temporaryTask?.title,
        })
        setTemporaryTask(null)
      },
      onSuccess(task) {
        utils.tasks.orderedList.setInfiniteData(
          { ...tasksQueryOptions, type: 'todo' },
          data => removeFromInfiniteData(data, temporaryTask)
        )
        setTemporaryTask(null)
        setCurrentTaskId(task.id)
        form.reset()
      },
      onSettled() {
        utils.tasks.orderedList.invalidate()
        utils.tasks.count.invalidate()
      },
    })

    // Reset form on save/error of a new task and focus title input
    useEffect(() => {
      if (
        !(createTaskMutation.isSuccess || !createTaskMutation.isError) ||
        !isNewTask
      ) {
        return
      }

      form.setFocus('title')
      createTaskMutation.reset()
    }, [
      createTaskMutation.isError,
      createTaskMutation.isLoading,
      createTaskMutation.isSuccess,
    ])

    const updateTaskMutation = api.task.update.useMutation()
    const title = form.watch('title')
    const trimmedTitle = title.trim()

    const debouncedUpdateTask = useDebouncedCallback(
      (variables: UpdateTaskInputType) => {
        if (isDemo) {
          console.log('Demo mode, not updating task title')
          return
        }

        track(EVENT.UPDATE_TASK, variables)
        updateTaskMutation.mutate(variables)
      },
      500
    )
    useEffect(() => {
      if (
        !task ||
        !trimmedTitle ||
        trimmedTitle === task.title.trim() ||
        updateTaskMutation.isLoading
      ) {
        return
      }

      console.log('Updating task title %o -> %o', task.title, title)
      debouncedUpdateTask.cancel()
      debouncedUpdateTask({ id: task.id, title: trimmedTitle })
    }, [trimmedTitle, task, updateTaskMutation])

    const onSubmit = (data: CreateTaskInputType) => {
      if (task || !data.title.trim()) {
        return
      }

      console.log('Saving new task', data)

      createTaskMutation.mutate({
        title: data.title.trim(),
      })
    }

    // Allow both forwarded ref and react-hook-form ref to use textarea ref
    const { ref } = form.register('title')
    const localRef = React.useRef<HTMLTextAreaElement | null>(null)
    useImperativeHandle<HTMLTextAreaElement | null, HTMLTextAreaElement | null>(
      forwardedRef,
      () => localRef.current
    )
    useImperativeHandle<HTMLTextAreaElement | null, HTMLTextAreaElement | null>(
      ref,
      () => localRef.current
    )

    const isDisabled = createTaskMutation.isLoading

    return (
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className={cn(
            'space-y-2 sm:flex sm:flex-row sm:items-center sm:gap-2 sm:space-y-0 min-h-[28px]',
            className
          )}>
          <FormField
            control={form.control}
            name='title'
            render={({ field }) => (
              <Textarea
                {...field}
                ref={localRef}
                size={size}
                rows={1}
                placeholder='Add a task…'
                variant={variant}
                autoComplete='off'
                autoCapitalize='on'
                autoCorrect='on'
                spellCheck='true'
                inputMode='text'
                className={cn(
                  'h-auto w-full flex-1 resize-none self-center overflow-y-hidden px-0 py-0 text-left font-medium'
                  // !!task?.completedDate && 'line-through'
                )}
                disabled={isDisabled}
                // onKeyUpCapture={e => {
                //   e.preventDefault()
                //   e.stopPropagation()
                // }}
                onKeyDown={e => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    console.log('Enter key pressed, submitting form')
                    form.handleSubmit(onSubmit)()
                    e.preventDefault()
                  }
                  // e.stopPropagation()
                }}
                onClick={e => e.stopPropagation()}
              />
            )}
          />
          {!task ? (
            <Button
              theme='premium'
              size='xs'
              type='submit'
              disabled={isDisabled}
              className={cn(
                'mt-2 hidden w-full text-center sm:mt-0 sm:w-auto',
                !!title && 'block'
              )}>
              Create{' '}
              <KeyboardShortcut
                keys={KEYBOARD_SHORTCUT.TASK_CREATE}
                size={size}
                theme='transparent'
              />
            </Button>
          ) : null}
          {!task && !title ? (
            <KeyboardShortcut
              className={cn(!!title && 'hidden')}
              keys={KEYBOARD_SHORTCUT.TASK_CREATE_FOCUS}
              size={size}
              onShortcut={() => form.setFocus('title')}
            />
          ) : null}
        </form>
      </Form>
    )
  }
)
