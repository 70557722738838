import { HAS_TOUCH_SCREEN, IS_APPLE } from '@/web/constants/device'
import type { KeyboardKey } from '@/web/constants/keyboard'
import { useKeyboardShortcut } from '@/web/hooks/useKeyboardShortcut'
import { cn } from '@/web/libs/utils'
import { cva, type VariantProps } from 'class-variance-authority'
import React from 'react'

const variants = cva(
  'rounded-xs bg-white/25 px-1.5 py-1 text-sm uppercase [line-height:normal] text-white/75 font-mono inline-flex gap-1 shrink-0',
  {
    variants: {
      size: {
        default: 'text-base',
        sm: 'text-2xs',
        lg: 'text-sm',
        xl: 'text-lg',
        none: '',
      },
      theme: {
        default: 'bg-base-300 text-muted-foreground',
        transparent: 'bg-white/25 text-white/75',
      },
    },
    defaultVariants: {
      size: 'default',
      theme: 'default',
    },
  }
)

export const KeyboardShortcut = ({
  size,
  theme,
  keys,
  onShortcut,
  className,
}: VariantProps<typeof variants> & {
  onShortcut?: () => void
  keys: KeyboardKey[]
  className?: string
}) => {
  useKeyboardShortcut(keys, onShortcut, !HAS_TOUCH_SCREEN)

  if (HAS_TOUCH_SCREEN) return null

  const keyStrings = keys.map(key => {
    if (key === 'metaKey') return IS_APPLE ? '⌘' : '⌃'
    if (key === 'shiftKey') return '⇧'
    if (key === 'ctrlKey') return '⌃'
    return key.toUpperCase()
  })

  return (
    <span className={cn(variants({ size, theme }), className)}>
      {keyStrings.map((key, index) => (
        <React.Fragment key={key}>
          <kbd>{key}</kbd>
          {index < keyStrings.length - 1 && (
            <span className='opacity-50'>+</span>
          )}
        </React.Fragment>
      ))}
    </span>
  )
}
