import { SCORE_THEME, SCORE_TITLE } from '@/shared/constants/score'
import {
  autoUpdatePriorityFields,
  getUrgency,
} from '@/shared/utils/task-priority'
import { DatePicker } from '@/web/components/DatePicker'
import { EstimatedByPulseTooltip } from '@/web/components/EstimatedByPulseTooltip'
import { PopoverButtonWithTooltip } from '@/web/components/PopoverButtonWithTooltip'
import { ScoreTitle } from '@/web/components/ScoreTitle'
import { Button } from '@/web/components/ui/button'
import { Label } from '@/web/components/ui/label'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/web/components/ui/tooltip'
import { UrgencyIcon } from '@/web/components/UrgencyIcon'
import { useUpdateTaskMutation } from '@/web/hooks/useUpdateTaskMutation'
import { cn } from '@/web/libs/utils'
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type Score, type Task } from '@prisma/client'
import { type ComponentProps } from 'react'

export const TaskUrgencyButton = ({ task }: { task: Task }) => {
  const updateTaskMutation = useUpdateTaskMutation()
  const updateTaskUrgency: typeof updateTaskMutation.mutate = (
    variables,
    options
  ) => {
    variables = autoUpdatePriorityFields(task, variables)

    updateTaskMutation.mutate(variables, options)
  }

  const urgency = getUrgency(task)

  return (
    <PopoverButtonWithTooltip
      tooltipContentChildren={
        urgency ? (
          <>
            Urgency: <ScoreTitle score={urgency} />
          </>
        ) : (
          'Set urgency'
        )
      }
      getButtonProps={({ isOpen }) => ({
        variant: 'ghost',
        size: 'sm',
        state: isOpen ? 'active' : 'selected',
        theme: urgency
          ? (SCORE_THEME[urgency] as ComponentProps<typeof Button>['theme'])
          : 'secondary',
        children: <UrgencyIcon urgency={urgency} />,
      })}
      popoverContentChildren={
        <>
          <Label className='flex flex-row items-center gap-2'>
            Urgency
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger className='text-muted-foreground flex items-center gap-2 text-xs'>
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </TooltipTrigger>
                <TooltipContent>
                  The urgency of your task is calculated based on the due date,
                  if available. Otherwise, it’s estimated based on whether or
                  not the task requires immediate action, demands attention, and
                  has clear consequences for not doing so.
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </Label>
          <div className='flex gap-2'>
            {(Object.keys(SCORE_THEME) as Array<Score | null>)
              .concat(null)
              .map((urgencyOption, index) => (
                <Button
                  key={index}
                  variant='ghost'
                  size='sm'
                  theme={
                    urgencyOption
                      ? (SCORE_THEME[urgencyOption] as ComponentProps<
                          typeof Button
                        >['theme'])
                      : 'secondary'
                  }
                  title={`${
                    urgencyOption ? SCORE_TITLE[urgencyOption] : 'No'
                  } urgency`}
                  onClick={() => {
                    if (!task) {
                      return
                    }
                    updateTaskUrgency({
                      id: task.id,
                      urgency: urgencyOption,
                    })
                  }}
                  state={urgencyOption === urgency ? 'active' : 'selected'}>
                  <UrgencyIcon urgency={urgencyOption} />
                </Button>
              ))}
          </div>
          <div className='grid grid-cols-[repeat(3,_auto)] items-center gap-x-2 gap-y-2'>
            <Label size='sm' htmlFor='scheduledDate' className='text-right'>
              Scheduled:
            </Label>
            <DatePicker
              id='scheduledDate'
              value={task?.scheduledDate}
              isShowingTime
              onChange={scheduledDate => {
                task && updateTaskUrgency({ id: task?.id, scheduledDate })
              }}
              buttonProps={{ size: 'sm' }}
            />
            <Button
              onClick={e => {
                e.stopPropagation()
                task &&
                  updateTaskUrgency({
                    id: task?.id,
                    scheduledDate: null,
                  })
              }}
              variant='ghost'
              size='sm'
              theme='destructive'
              title='Clear scheduled date'
              className={cn(!task?.scheduledDate && 'invisible')}>
              <FontAwesomeIcon icon={faTimes} />
            </Button>
            <Label size='sm' htmlFor='dueDate' className='text-right'>
              Due:
            </Label>
            <DatePicker
              id='dueDate'
              value={task?.dueDate}
              onChange={dueDate => {
                task && updateTaskUrgency({ id: task?.id, dueDate })
              }}
              buttonProps={{ size: 'sm' }}
            />
            <Button
              onClick={e => {
                e.stopPropagation()
                task && updateTaskUrgency({ id: task?.id, dueDate: null })
              }}
              variant='ghost'
              size='sm'
              theme='destructive'
              title='Clear due date'
              className={cn(!task?.dueDate && 'invisible')}>
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </div>
          {urgency &&
          !task?.manualFields.includes('urgency') &&
          task?.urgencyReason ? (
            <EstimatedByPulseTooltip>
              <Label size='lg' className='text-center'>
                Urgency: <ScoreTitle score={urgency} />
              </Label>
              {task.urgencyReason && (
                <div className='text-secondary-foreground'>
                  {task.urgencyReason}
                </div>
              )}
            </EstimatedByPulseTooltip>
          ) : null}
        </>
      }
    />
  )
}
