'use client'

import { cn } from '@/web/libs/utils'
import * as CollapsiblePrimitive from '@radix-ui/react-collapsible'
import { cva, type VariantProps } from 'class-variance-authority'
import React from 'react'

const Collapsible = React.forwardRef<
  React.ElementRef<typeof CollapsiblePrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CollapsiblePrimitive.Root>
>((props, ref) => <CollapsiblePrimitive.Root ref={ref} {...props} />)
Collapsible.displayName = CollapsiblePrimitive.Root.displayName

const triggerVariants = cva('focus-visible:ring-1 ring-offset-1 ring-primary', {
  variants: {
    variant: {
      default:
        '[&_>_*:not(button)]:hover:cursor-pointer hover:text-primary [&_>_*:not(button,_:has(button))]:hover:text-primary',
    },
    size: {
      sm: 'text-xs font-bold',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})

const CollapsibleTrigger = React.forwardRef<
  React.ElementRef<typeof CollapsiblePrimitive.CollapsibleTrigger>,
  React.ComponentPropsWithoutRef<
    typeof CollapsiblePrimitive.CollapsibleTrigger
  > &
    VariantProps<typeof triggerVariants>
>(({ className, variant, size, ...props }, ref) => (
  <CollapsiblePrimitive.CollapsibleTrigger
    ref={ref}
    className={cn(triggerVariants({ variant, size }), className)}
    {...props}
  />
))
CollapsibleTrigger.displayName =
  CollapsiblePrimitive.CollapsibleTrigger.displayName

const CollapsibleContent = React.forwardRef<
  React.ElementRef<typeof CollapsiblePrimitive.CollapsibleContent>,
  React.ComponentPropsWithoutRef<typeof CollapsiblePrimitive.CollapsibleContent>
>(({ className, ...props }, ref) => (
  <CollapsiblePrimitive.CollapsibleContent
    ref={ref}
    {...props}
    className={cn('CollapsibleContent', className)}
  />
))
CollapsibleContent.displayName =
  CollapsiblePrimitive.CollapsibleContent.displayName

export { Collapsible, CollapsibleContent, CollapsibleTrigger }
