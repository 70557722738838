import { SCORE_TITLE } from '@/shared/constants/score'
import { ScoreText } from '@/web/components/ScoreText'
import { type Score } from '@prisma/client'

export const ScoreTitle = ({
  className,
  score,
}: {
  className?: string
  score?: Score | null
}) => {
  return (
    <ScoreText className={className} score={score}>
      {score ? SCORE_TITLE[score] : 'Unknown'}
    </ScoreText>
  )
}
