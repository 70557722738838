import { Checkbox } from '@/web/components/ui/checkbox'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/web/components/ui/tooltip'
import {
  TASK_STATUS_ICON,
  TASK_STATUS_THEME,
  TASK_STATUS_TITLE,
} from '@/web/constants/task'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TaskStatus } from '@prisma/client'
import { type ComponentProps } from 'react'

export function TaskStatusCheckbox({
  status,
  isChecked,
  onCheckedChange,
  isAlwaysIndicating,
  size = 'lg',
  ...checkboxProps
}: Partial<ComponentProps<typeof Checkbox>> & {
  status: TaskStatus
  isChecked: boolean
  onCheckedChange: (checked: boolean) => void
  isAlwaysIndicating?: boolean
}) {
  const icon = TASK_STATUS_ICON[status]
  return (
    <TooltipProvider>
      <Tooltip disableHoverableContent>
        <TooltipTrigger asChild>
          <Checkbox
            key={status}
            {...checkboxProps}
            size={size}
            theme={TASK_STATUS_THEME[status] ?? 'default'}
            state={
              isChecked
                ? 'checked'
                : isAlwaysIndicating
                ? 'indeterminate'
                : 'unchecked'
            }
            checked={
              isChecked ? true : isAlwaysIndicating ? 'indeterminate' : false
            }
            onCheckedChange={onCheckedChange}
            onClick={e => {
              e.stopPropagation()
            }}>
            {icon && status !== TaskStatus.TODO ? (
              <FontAwesomeIcon
                icon={icon}
                className={
                  size === 'xs'
                    ? 'size-1.5'
                    : size === 'sm'
                    ? 'w-2'
                    : size === 'md'
                    ? 'w-3'
                    : 'w-4'
                }
              />
            ) : null}
          </Checkbox>
        </TooltipTrigger>
        <TooltipContent
          className={`text-${TASK_STATUS_THEME[status]}`}
          side='left'>
          {TASK_STATUS_TITLE[status]}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
