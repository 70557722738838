import { cn } from '@/web/libs/utils'
import { ChevronDownIcon, ChevronUpIcon } from 'lucide-react'

export const CollapsibleCaret = ({
  isOpen,
  className,
}: {
  isOpen: boolean
  className?: string
}) => {
  const Icon = isOpen ? ChevronUpIcon : ChevronDownIcon
  return <Icon className={cn('opacity-75', className)} />
}
