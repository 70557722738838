import { SCORE_THEME } from '@/shared/constants/score'
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons'
import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type Score } from '@prisma/client'

export const ImportanceIcon = ({
  importance,
}: {
  importance: Score | null
}) => (
  <FontAwesomeIcon
    icon={importance ? faStarSolid : faStarRegular}
    className={
      importance ? `text-${SCORE_THEME[importance]}` : 'text-muted-foreground'
    }
    fixedWidth
  />
)
