import { type IconProps } from '@/web/constants/icon'
import { type RouterOutputs } from '@/web/utils/api'
import { faCircle } from '@fortawesome/free-regular-svg-icons'
import {
  faCalendarCheck,
  faCancel,
  faCheck,
  faEllipsis,
  faEye,
  faLock,
} from '@fortawesome/free-solid-svg-icons'
import { type FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { TaskStatus } from '@prisma/client'

/* Status */

export const TASK_STATUS_TITLE: Record<TaskStatus, string> = {
  [TaskStatus.TODO]: 'To-Do',
  [TaskStatus.IN_PROGRESS]: 'In Progress',
  [TaskStatus.COMPLETED]: 'Completed',
  [TaskStatus.CANCELLED]: 'Cancelled',
  [TaskStatus.BLOCKED]: 'Blocked',
  [TaskStatus.IN_REVIEW]: 'In Review',
  [TaskStatus.SCHEDULED]: 'Scheduled',
}

export const TASK_STATUS_OPTIONS: readonly TaskStatus[] = [
  TaskStatus.IN_PROGRESS,
  TaskStatus.IN_REVIEW,
  TaskStatus.SCHEDULED,
  TaskStatus.COMPLETED,
  TaskStatus.BLOCKED,
  TaskStatus.CANCELLED,
] as const

// export const TASK_TYPE_ICON: Record<TaskType, FontAwesomeIconProps['icon']> = {
//   [TaskType.UPDATE]: faRefresh,
//   [TaskType.QUESTION]: faQuestion,
//   [TaskType.REMINDER]: faClock,
//   [TaskType.TODO]: faCircle,
// }

export const TASK_STATUS_ICON: Record<
  TaskStatus,
  FontAwesomeIconProps['icon']
> = {
  [TaskStatus.IN_PROGRESS]: faEllipsis,
  [TaskStatus.TODO]: faCircle,
  [TaskStatus.COMPLETED]: faCheck,
  [TaskStatus.CANCELLED]: faCancel,
  [TaskStatus.IN_REVIEW]: faEye,
  [TaskStatus.BLOCKED]: faLock,
  [TaskStatus.SCHEDULED]: faCalendarCheck,
}

// NOTE: List all used Tailwind classes here
// text-success text-warning text-destructive
export const TASK_STATUS_THEME: Partial<
  Record<TaskStatus, 'success' | 'warning' | 'destructive'>
> = {
  [TaskStatus.TODO]: 'success',
  [TaskStatus.SCHEDULED]: 'warning',
  [TaskStatus.IN_PROGRESS]: 'warning',
  [TaskStatus.IN_REVIEW]: 'warning',
  [TaskStatus.COMPLETED]: 'success',
  [TaskStatus.CANCELLED]: 'destructive',
  [TaskStatus.BLOCKED]: 'destructive',
}

export type TaskFromQuery = Exclude<
  | RouterOutputs['tasks']['orderedList']['items'][number]
  | RouterOutputs['task']['get'],
  null
>

/* Context */
export const CONTEXT_ICON_PROPS = {
  width: 16,
  height: 16,
  isColored: true,
} satisfies IconProps
