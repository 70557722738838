import { SCORE_THEME } from '@/shared/constants/score'
import { cn } from '@/web/libs/utils'
import { type Score } from '@prisma/client'

export const ScoreText = ({
  className,
  score,
  children,
}: {
  className?: string
  score?: Score | null
  children?: React.ReactNode
}) => {
  return (
    <span
      className={cn(
        className,
        'text-secondary-foreground',
        score && `text-${SCORE_THEME[score]}`
      )}>
      {children}
    </span>
  )
}
