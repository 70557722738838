import { sanitizeHtml } from '@/shared/utils/html'
import { cn } from '@/web/libs/utils'

export function RawHtml({
  html,
  className,
}: {
  html: string
  className?: string
}) {
  return (
    <p
      className={cn('raw-html overflow-x-auto overflow-y-clip', className)}
      dangerouslySetInnerHTML={{
        __html: sanitizeHtml(html, { isAllowingImages: true }),
      }}
    />
  )
}
