import { NotionPageCardContent } from '@/web/components/NotionPageCardContent'
import { TaskIntegrationContext } from '@/web/components/TaskIntegrationContext'
import { Card } from '@/web/components/ui/card'
import { IntegrationName } from '@prisma/client'
import pluralize from 'pluralize'

export const NotionTaskContext = () => {
  return (
    <TaskIntegrationContext
      integrationName={IntegrationName.NOTION}
      getTriggerText={({ count }) =>
        `${count} related ${pluralize('page', count)}`
      }>
      {data => (
        <Card variant='secondary' className='gap-20' size='sm-short'>
          {data.pages.map(page => (
            <NotionPageCardContent key={page.id} page={page} />
          ))}
        </Card>
      )}
    </TaskIntegrationContext>
  )
}
