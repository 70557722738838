import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogTrigger,
} from '@/web/components/ui/alert-dialog'
import { Button, type ButtonProps } from '@/web/components/ui/button'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/web/components/ui/tooltip'
import { useState } from 'react'

export const AlertDialogButtonWithTooltip = ({
  getButtonProps,
  tooltipContentChildren,
  alertDialogChildren,
}: {
  getButtonProps: ({ isOpen }: { isOpen: boolean }) => Partial<ButtonProps>
  tooltipContentChildren: React.ReactNode
  alertDialogChildren: React.ReactNode
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
      <TooltipProvider>
        <Tooltip disableHoverableContent>
          <TooltipTrigger asChild>
            <AlertDialogTrigger onClick={e => e.stopPropagation()} asChild>
              <Button {...getButtonProps({ isOpen })} />
            </AlertDialogTrigger>
          </TooltipTrigger>
          <TooltipContent>{tooltipContentChildren}</TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <AlertDialogContent>{alertDialogChildren}</AlertDialogContent>
    </AlertDialog>
  )
}
