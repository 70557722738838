import { SCORE_THEME, SCORE_TITLE } from '@/shared/constants/score'
import {
  getImportance,
  getPriority,
  getUrgency,
} from '@/shared/utils/task-priority'
import { EstimatedByPulseTooltip } from '@/web/components/EstimatedByPulseTooltip'
import { PopoverButtonWithTooltip } from '@/web/components/PopoverButtonWithTooltip'
import { PriorityIcon } from '@/web/components/PriorityIcon'
import { ScoreTitle } from '@/web/components/ScoreTitle'
import { Button } from '@/web/components/ui/button'
import { Label } from '@/web/components/ui/label'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/web/components/ui/tooltip'
import { useUpdateTaskMutation } from '@/web/hooks/useUpdateTaskMutation'
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type Score, type Task } from '@prisma/client'
import { type ComponentProps } from 'react'

export const TaskPriorityButton = ({ task }: { task: Task }) => {
  const updateTaskMutation = useUpdateTaskMutation()

  // Automatically calculate the priority if it's not set
  const priority = getPriority(task)

  return (
    <PopoverButtonWithTooltip
      tooltipContentChildren={
        priority ? (
          <>
            Priority: <ScoreTitle score={priority} />
          </>
        ) : (
          'Set priority'
        )
      }
      getButtonProps={({ isOpen }) => ({
        variant: 'ghost',
        size: 'sm',
        state: isOpen ? 'active' : 'selected',
        theme: priority
          ? (SCORE_THEME[priority] as ComponentProps<typeof Button>['theme'])
          : 'secondary',
        children: <PriorityIcon priority={priority} />,
      })}
      popoverContentChildren={
        <>
          <Label className='flex flex-row items-center gap-2'>
            Priority
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger className='text-muted-foreground flex items-center gap-2 text-xs'>
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </TooltipTrigger>
                <TooltipContent>
                  If left unset, the priority of your task is calculated using a
                  combination of the importance and urgency.
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </Label>
          <div className='flex gap-2'>
            {(Object.keys(SCORE_THEME) as Array<Score | null>)
              .concat(null)
              .map((priorityOption, index) => (
                <Button
                  key={index}
                  variant='ghost'
                  size='sm'
                  theme={
                    priorityOption
                      ? (SCORE_THEME[priorityOption] as ComponentProps<
                          typeof Button
                        >['theme'])
                      : 'secondary'
                  }
                  title={`${
                    priorityOption ? SCORE_TITLE[priorityOption] : 'No'
                  } priority`}
                  onClick={() => {
                    updateTaskMutation.mutate({
                      id: task.id,
                      priority: priorityOption,
                    })
                  }}
                  state={priorityOption === priority ? 'active' : 'selected'}>
                  <PriorityIcon priority={priorityOption} />
                </Button>
              ))}
          </div>
          {priority &&
          !task?.manualFields.includes('priority') &&
          (getImportance(task) || getUrgency(task)) ? (
            <EstimatedByPulseTooltip>
              <Label size='lg' className='text-center'>
                Priority: <ScoreTitle score={priority} />
              </Label>
              <div>
                This is averaged from the{' '}
                <ScoreTitle score={getImportance(task)} /> importance and the{' '}
                <ScoreTitle score={getUrgency(task)} /> urgency.
              </div>
            </EstimatedByPulseTooltip>
          ) : null}
        </>
      }
    />
  )
}
