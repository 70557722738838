'use client'

import {
  type ActionParametersType,
  type ReplyToMessageInputSchema,
  type SendMessageInputSchema,
} from '@/shared/constants/action'
import { type JIRA_CREATE_TASK_PARAMETERS_SCHEMA } from '@/shared/constants/jira'
import * as actionUtil from '@/shared/utils/action'
import { getValidationErrorMessage, isValid } from '@/shared/utils/action'
import {
  getMatchingEmailAddressInEmailRecipientFields,
  parseEmailString,
} from '@/shared/utils/email'
import { isGmailData } from '@/shared/utils/gmail'
import { getCreateEventUrl } from '@/shared/utils/google-calendar'
import { createNewIssueUrl } from '@/shared/utils/jira'
import { isSlackData } from '@/shared/utils/slack'
import { isTaskWithContextItemsAndActions } from '@/shared/utils/task'
import { ArchiveEmailsForm } from '@/web/components/ArchiveEmailsForm'
import { SendEmailForm } from '@/web/components/SendEmailForm'
import { SendMessageForm } from '@/web/components/SendMessageForm'
import { SpamEmailsForm } from '@/web/components/SpamEmailsForm'
import { DemoContext } from '@/web/contexts/demo'
import { TaskContext } from '@/web/contexts/task'
import { useAllContextData } from '@/web/hooks/use-all-context-data'
import { useIntegrationNames } from '@/web/hooks/use-integration-names'
import { api } from '@/web/utils/api'
import { ActionType, type Action } from '@prisma/client'
import { useContext, useEffect, useState } from 'react'
import { toast } from 'sonner'
import { type z } from 'zod'

export const TaskActionEditCard = ({ action }: { action: Action }) => {
  const [isHandled, setIsHandled] = useState<boolean | null>(null)
  const [hasElement, setHasElement] = useState<boolean | null>(null)

  const isCompleted = !!action.completedDate

  const integrationNames = useIntegrationNames()

  const completeActionMutationResult = api.action.complete.useMutation()
  const sendSlackMessageMutationResult = api.action.sendMessage.useMutation()
  const replyToSlackMessageMutationResult =
    api.action.replyToMessage.useMutation()
  const sendEmailGmailMutationResult = api.gmail.sendEmail.useMutation()
  const replyToEmailGmailMutationResult = api.gmail.replyToEmail.useMutation()
  const archiveEmailGmailMutationResult = api.gmail.archiveEmail.useMutation()
  const reportSpamEmailGmailMutationResult =
    api.gmail.reportSpamEmail.useMutation()

  const fromEmailsQueryResult = api.gmail.fromEmails.useQuery()

  const task = useContext(TaskContext)
  const isDemo = useContext(DemoContext)

  if (!task) {
    throw new Error('Task not found')
  }

  const { data: allContextData, isLoading } = useAllContextData()

  useEffect(() => {
    if (
      !isDemo &&
      task &&
      !isLoading &&
      !!allContextData &&
      isTaskWithContextItemsAndActions(task) &&
      !isValid(action, {
        integrationNames,
        task,
        allContextData,
      })
    ) {
      toast.error('Invalid action', {
        description: getValidationErrorMessage(action, {
          integrationNames,
          task,
          allContextData,
        }),
      })
      return
    }
  }, [!!action])

  useEffect(() => {
    if (isHandled !== false || hasElement !== false) {
      return
    }

    toast.error('Action not supported', {
      description: `Action type ${action.type} for ${action.integrationName} is not supported`,
    })
  }, [isHandled, hasElement])

  useEffect(() => {
    if (isDemo) {
      console.log('Skipping action in demo mode', action)
      return
    }

    if (isCompleted) {
      console.log('Already completed action', action)
      return
    }

    if (isHandled !== null) {
      console.log('Already handled action', action)
      return
    }

    if (action.type === ActionType.CREATE_EVENT) {
      const url = getCreateEventUrl(
        params as ActionParametersType<typeof action.type>
      )
      console.log('Opening url to create event', url)
      window.open(url, '_blank')
      completeActionMutationResult.mutate(action.id)
    } else if (action.type === ActionType.CREATE_TASK) {
      const taskUrl = createNewIssueUrl(
        params as unknown as z.infer<typeof JIRA_CREATE_TASK_PARAMETERS_SCHEMA>
      )

      console.log('Opening url to create task', taskUrl)
      window.open(taskUrl, '_blank')
      completeActionMutationResult.mutate(action.id)
    } else if (action.type === ActionType.FOLLOW_LINK) {
      const url = (params as ActionParametersType<typeof action.type>).url
      console.log('Opening url to follow link', url)
      window.open(url, '_blank')
      completeActionMutationResult.mutate(action.id)
    } else if (action.type === ActionType.ARCHIVE_EMAIL) {
      archiveEmailGmailMutationResult.mutate({
        actionId: action.id,
        emailId: (params as ActionParametersType<typeof action.type>).emailId,
      })
    } else if (action.type === ActionType.REPORT_SPAM_EMAIL) {
      reportSpamEmailGmailMutationResult.mutate({
        actionId: action.id,
        emailId: (params as ActionParametersType<typeof action.type>).emailId,
      })
    } else {
      setIsHandled(false)
      return
    }

    setIsHandled(true)
  }, [isHandled])

  let element: JSX.Element | null = null

  const params = actionUtil.getActionParameters(action)
  switch (action.type) {
    case ActionType.SEND_MESSAGE:
      const sendMessageActionParameters = actionUtil.getActionParameters(
        action
      ) as ActionParametersType<typeof action.type>
      element = (
        <SendMessageForm
          to={sendMessageActionParameters.conversationName}
          text={sendMessageActionParameters.messageOptions[0]?.message}
          options={sendMessageActionParameters.messageOptions}
          onSend={text =>
            isDemo
              ? console.log('Skipping send message in demo mode', text)
              : sendSlackMessageMutationResult.mutate({
                  integrationName: action.integrationName,
                  actionId: action.id,
                  channelId: sendMessageActionParameters.conversationId,
                  message: text,
                } as z.infer<typeof SendMessageInputSchema>)
          }
          isDemo={isDemo}
        />
      )
      break
    case ActionType.REPLY_TO_MESSAGE:
      const replyToMessageActionParameters = actionUtil.getActionParameters(
        action
      ) as ActionParametersType<typeof action.type>
      const replyToMessageTaskData = isSlackData(allContextData)
        ? allContextData
        : null
      element = (
        <SendMessageForm
          originalMessage={replyToMessageTaskData?.conversations
            ?.find(
              conversation =>
                conversation.id ===
                replyToMessageActionParameters.conversationId
            )
            ?.messages?.find(
              message =>
                message.id === replyToMessageActionParameters.originalMessageId
            )}
          to={replyToMessageActionParameters.conversationName}
          text={replyToMessageActionParameters.messageOptions[0]?.message}
          options={replyToMessageActionParameters.messageOptions}
          onSend={text =>
            isDemo
              ? console.log('Skipping reply to message in demo mode', text)
              : replyToSlackMessageMutationResult.mutate({
                  integrationName: action.integrationName,
                  actionId: action.id,
                  channelId: replyToMessageActionParameters.conversationId,
                  message: text,
                  messageId: replyToMessageActionParameters.originalMessageId,
                } as z.infer<typeof ReplyToMessageInputSchema>)
          }
          isDemo={isDemo}
        />
      )
      break
    case ActionType.SEND_EMAIL:
      const sendEmailActionParameters = actionUtil.getActionParameters(
        action
      ) as ActionParametersType<typeof action.type>
      element = (
        <SendEmailForm
          from={sendEmailActionParameters.from}
          fromOptions={fromEmailsQueryResult.data}
          to={sendEmailActionParameters.to}
          subject={sendEmailActionParameters.subject}
          text={sendEmailActionParameters.message}
          onSend={email =>
            isDemo
              ? console.log('Skipping send email in demo mode', email)
              : sendEmailGmailMutationResult.mutate({
                  actionId: action.id,
                  ...email,
                })
          }
          error={sendEmailGmailMutationResult.error?.message}
        />
      )
      break
    case ActionType.REPLY_TO_EMAIL:
      const replyToEmailActionParameters = actionUtil.getActionParameters(
        action
      ) as ActionParametersType<typeof action.type>
      const replyToEmailTaskData = isGmailData(allContextData)
        ? allContextData
        : null
      const email = replyToEmailTaskData?.emails?.find(
        email => email.id === replyToEmailActionParameters.emailId
      )
      element = (
        <SendEmailForm
          originalEmail={email}
          from={
            replyToEmailActionParameters.from ??
            getMatchingEmailAddressInEmailRecipientFields({
              emailAddresses:
                (fromEmailsQueryResult.data
                  ?.map(emailString => parseEmailString(emailString).email)
                  .filter(Boolean) as string[]) ?? undefined,
              email,
            })
          }
          fromOptions={fromEmailsQueryResult.data}
          to={
            replyToEmailActionParameters.to ?? (email?.from ? [email.from] : [])
          }
          text={replyToEmailActionParameters.message}
          onSend={(email, { isArchiving }) =>
            isDemo
              ? console.log('Skipping reply to email in demo mode', email)
              : replyToEmailGmailMutationResult.mutate({
                  ...email,
                  actionId: action.id,
                  emailId: replyToEmailActionParameters.emailId,
                  isArchiving,
                })
          }
          error={replyToEmailGmailMutationResult.error?.message}
        />
      )
      break
    case ActionType.ARCHIVE_EMAILS:
      const archiveEmailsActionParameters = actionUtil.getActionParameters(
        action
      ) as ActionParametersType<typeof action.type>
      element = (
        <ArchiveEmailsForm
          actionId={action.id}
          taskId={task.id}
          emailIds={archiveEmailsActionParameters.emailIds}
        />
      )
      break
    case ActionType.REPORT_SPAM_EMAILS:
      const reportSpamEmailsActionParameters = actionUtil.getActionParameters(
        action
      ) as ActionParametersType<typeof action.type>
      element = (
        <SpamEmailsForm
          actionId={action.id}
          taskId={task.id}
          emailIds={reportSpamEmailsActionParameters.emailIds}
        />
      )
      break
  }

  useEffect(() => {
    if (hasElement !== null) {
      return
    }

    setHasElement(!!element)
  }, [!!element])

  if (
    !isDemo &&
    allContextData &&
    isTaskWithContextItemsAndActions(task) &&
    !isValid(action, { integrationNames, task, allContextData })
  ) {
    return null
  }

  return element
}
