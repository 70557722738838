import { type JiraCreateTaskParametersType } from '@/shared/constants/jira'
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/web/components/ui/card'
import { faAlignLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const IssueDetails = ({
  issue,
}: {
  issue: JiraCreateTaskParametersType
}) => {
  return (
    <Card
      variant='secondary'
      size='small'
      className='grid grid-flow-row gap-y-2 [grid-template-columns:min-content_1fr]'>
      <CardHeader className='grid items-start gap-x-4 [grid-column:span_2] [grid-template-columns:subgrid]'>
        <div className='[grid-column:2]'>
          <CardTitle>{issue.summary}</CardTitle>
        </div>
      </CardHeader>
      <CardContent className='grid grid-flow-row items-start gap-x-4 leading-6 [grid-column:span_2] [grid-template-columns:subgrid]'>
        {/* {event.location ? (
          <>
            <FontAwesomeIcon
              icon={faLocationDot}
              className='text-muted-foreground justify-self-center pt-1'
            />
            <p>{event.location}</p>
          </>
        ) : null} */}
        {issue.description ? (
          <>
            <FontAwesomeIcon
              icon={faAlignLeft}
              className='text-muted-foreground justify-self-center pt-1'
            />
            <p>{issue.description}</p>
          </>
        ) : null}
        {/* {event.attendees.length ? (
          <>
            <FontAwesomeIcon
              icon={faPeopleGroup}
              className='text-muted-foreground justify-self-center pt-1'
            />
            <p>{event.attendees.join(', ')}</p>
          </>
        ) : null} */}
      </CardContent>
    </Card>
  )
}
