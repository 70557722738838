import { type ContextMetadataType } from '@/shared/constants/context'
import { type SummaryType } from '@/shared/constants/llm'
import {
  hasSummaryAdditionalData,
  hasSummaryContacts,
  hasSummaryDates,
  hasSummaryLinks,
  hasSummaryMetadata,
} from '@/shared/utils/context'
import { convertMarkdownToHtml } from '@/shared/utils/html'
import { Avatar, AvatarImage } from '@/web/components/ui/avatar'
import { Button } from '@/web/components/ui/button'
import { Label } from '@/web/components/ui/label'
import { cn } from '@/web/libs/utils'
import {
  faBullseye,
  faSearch,
  faWandMagicSparkles,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'
import { type ComponentProps } from 'react'

export const ContextContent = ({
  summary,
  contextMetadata,
  className,
  size,
}: {
  summary?: Partial<SummaryType>
  contextMetadata?: ContextMetadataType
  className?: string
  size?: ComponentProps<typeof Label>['size']
}) => {
  if (!summary && !contextMetadata) return null

  return (
    <div className={cn('flex flex-col gap-3', className)}>
      {summary?.summary ? (
        <div className='grid gap-x-2 gap-y-1 [grid-template-columns:auto_1fr]'>
          <Label
            className='grid items-center gap-2 [grid-column:span_2] [grid-template-columns:subgrid]'
            size={size}>
            <FontAwesomeIcon
              fixedWidth
              icon={faWandMagicSparkles}
              className='text-muted-foreground/50'
            />
            Summary
          </Label>
          <p
            className='text-secondary-foreground [&_a]:text-primary grid text-sm [grid-column:2] [&_ol]:list-inside [&_ol]:list-decimal [&_p]:text-sm'
            dangerouslySetInnerHTML={{
              __html: convertMarkdownToHtml(summary.summary),
            }}
          />
        </div>
      ) : null}

      {contextMetadata?.searchQuery ? (
        <div className='grid gap-x-2 gap-y-1 [grid-template-columns:auto_1fr]'>
          <Label
            className='grid items-center gap-2 [grid-column:span_2] [grid-template-columns:subgrid]'
            size={size}>
            <FontAwesomeIcon
              fixedWidth
              icon={faSearch}
              className='text-muted-foreground/50'
            />
            Matching search query
          </Label>
          <Label
            className='text-secondary-foreground grid [grid-column:2]'
            size={size}>
            {contextMetadata.searchQuery}
          </Label>
        </div>
      ) : null}

      {contextMetadata?.reason ? (
        <div className='grid items-start gap-x-2 gap-y-1 [grid-template-columns:auto_1fr]'>
          <Label
            className='grid items-center gap-2 [grid-column:span_2] [grid-template-columns:subgrid]'
            size={size}>
            <FontAwesomeIcon
              fixedWidth
              icon={faBullseye}
              className='text-muted-foreground/50'
            />
            Reason it’s relevant
          </Label>
          <Label
            className='text-secondary-foreground grid [grid-column:2] [grid-template-columns:subgrid]'
            size={size}>
            {contextMetadata.reason}
          </Label>
        </div>
      ) : null}

      {hasSummaryAdditionalData(summary) ? (
        <small className='text-muted-foreground flex flex-row flex-wrap gap-4'>
          {hasSummaryContacts(summary)
            ? summary.contacts.map((contact, index) => (
                <span
                  key={index}
                  className='flex flex-row flex-wrap gap-x-2 gap-y-1'>
                  {contact.photoUrl ? (
                    <Avatar size={'xs'}>
                      <AvatarImage src={contact.photoUrl} />
                    </Avatar>
                  ) : null}
                  {contact.name ? (
                    <span className='font-bold'>{contact.name}</span>
                  ) : null}
                  {contact.email ? (
                    <Link href={`mailto:${contact.email}`}>
                      <Button variant='link' theme='secondary' size='none'>
                        {contact.email}
                      </Button>
                    </Link>
                  ) : null}
                  {contact.phone ? (
                    <Link href={`tel:${contact.phone}`}>
                      <Button variant='link' theme='secondary' size='none'>
                        {contact.phone}
                      </Button>
                    </Link>
                  ) : null}
                  {contact.address ? (
                    <Link
                      href={`https://maps.google.com/?q=${contact.address}`}>
                      <Button variant='link' theme='secondary' size='none'>
                        {contact.address}
                      </Button>
                    </Link>
                  ) : null}
                </span>
              ))
            : null}
          {hasSummaryDates(summary)
            ? summary.dates.map(({ date, title }) => (
                <span key={date?.toString()}>
                  {title ? `${title}: ` : ''}
                  {new Intl.DateTimeFormat('en-US', {
                    dateStyle: 'short',
                    timeStyle: 'short',
                  }).format(new Date(date))}
                </span>
              ))
            : null}
          {hasSummaryLinks(summary)
            ? summary.links.map((link, index) => (
                <Link key={index} href={link.url}>
                  <Button variant='link' theme='secondary' size='none'>
                    {link.title || link.url}
                  </Button>
                </Link>
              ))
            : null}
          {hasSummaryMetadata(summary) ? (
            <div className='flex flex-col gap-1'>
              {Object.entries(summary.metadata).map(([key, value]) => (
                <span key={key}>
                  {key}: {value}
                </span>
              ))}
            </div>
          ) : null}
        </small>
      ) : null}
    </div>
  )
}
