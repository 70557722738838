import dayjs from '@/shared/singletons/dayjs'
import { hasMissingGeneratedContext } from '@/shared/utils/context'
import { getTaskUpdateChannel } from '@/shared/utils/pusher'
import { isComplete, isTaskWithContextItems } from '@/shared/utils/task'
import { shouldEstimatePriority } from '@/shared/utils/task-priority'
import { NotificationDot } from '@/web/components/NotificationDot'
import { SuggestedForYouIcon } from '@/web/components/SuggestedForYouIcon'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/web/components/ui/tooltip'
import { DemoContext } from '@/web/contexts/demo'
import { TaskContext } from '@/web/contexts/task'
import { usePrevious } from '@/web/hooks/use-previous'
import { usePusherData } from '@/web/hooks/use-pusher-data'
import { useIntegrationListQueryResult } from '@/web/hooks/useIntegrationListQueryResult'
import { cn } from '@/web/libs/utils'
import { api } from '@/web/utils/api'
import _ from 'lodash'
import { useContext, useEffect, useMemo } from 'react'
import { toast } from 'sonner'

export const TaskStatusText = () => {
  const task = useContext(TaskContext)
  const isDemo = useContext(DemoContext)

  const channel = getTaskUpdateChannel({ taskId: task?.id ?? '-1' })
  const data = usePusherData(channel)

  const generateTaskDetailsMutationResult =
    api.task.generateDetails.useMutation()

  const integrationListQueryResult = useIntegrationListQueryResult()

  const needsContext = useMemo(
    () =>
      !!task &&
      isTaskWithContextItems(task) &&
      hasMissingGeneratedContext({
        task,
        contexts: task.contexts,
        integrationNames:
          integrationListQueryResult.data?.map(i => i.name) || [],
      }),
    [task, integrationListQueryResult.data]
  )

  const needsDetails =
    !!task &&
    !isDemo &&
    integrationListQueryResult.isSuccess &&
    (needsContext ||
      !task.actionsUpdatedDate ||
      shouldEstimatePriority(task)) &&
    generateTaskDetailsMutationResult.isIdle &&
    !isComplete(task)
  useEffect(() => {
    if (!needsDetails) {
      console.debug('No details needed for task %o: %O', task?.title, {
        needsDetails,
        task,
      })
      return
    }

    console.log('Generating details for task %o', task.title, task)
    generateTaskDetailsMutationResult.mutate(task.id)
  }, [needsDetails])

  const utils = api.useUtils()
  const previousData = usePrevious(data)
  useEffect(() => {
    // Prevent the same toast from being shown multiple times
    if (!data || data.isInitial || _.isEqual(previousData, data)) {
      return
    }

    if (data.status === 'success') {
      toast.success(`Updated ${task ? `“${task?.title}”` : 'task'}`, {
        description:
          data.message +
          (data.duration
            ? ` [${dayjs.duration(data.duration).asSeconds()}s]`
            : ''),
      })
      console.log('Finished updating task via job — refetching: %O', data)
      utils.task.get.refetch(task?.id)
      utils.tasks.orderedList.invalidate()
      utils.tasks.count.invalidate()
      utils.taskHistory.get.invalidate(task?.id ?? '-1')
      return
    }

    if (data.status === 'error') {
      toast.error(`Failed to update ${task ? `“${task?.title}”` : 'task'}`, {
        description: data.message,
      })
      console.error('Error updating task: %O', data)
      return
    }
  }, [data])

  const isUpdating =
    generateTaskDetailsMutationResult.isLoading || data?.status === 'running'

  return (
    <small
      className={cn(
        'text-muted-foreground/75 flex flex-row items-center gap-1',
        isUpdating && 'text-gradient',
        !isUpdating && !!task?.authorId && 'invisible'
      )}>
      {isUpdating || !!task?.authorId ? (
        <>
          <NotificationDot size='sm' className='w-[11.25px] self-center' />
          <small className='line-clamp-1 flex-1'>
            {data?.message ?? 'Updating task…'}
          </small>
        </>
      ) : (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger className='flex items-baseline gap-1 font-normal'>
              <SuggestedForYouIcon isIncludingText />
            </TooltipTrigger>
            {task?.reason ? (
              <TooltipContent>{task.reason}</TooltipContent>
            ) : null}
          </Tooltip>
        </TooltipProvider>
      )}
    </small>
  )
}
