import { type JIRA_ACTION_TYPE_PARAMETERS_SCHEMA } from '@/shared/constants/jira'
import { type ActionType } from '@prisma/client'
import { type z } from 'zod'

// FIXME: Include `reporter` in the query
export function createNewIssueUrl(
  options: z.infer<
    (typeof JIRA_ACTION_TYPE_PARAMETERS_SCHEMA)[typeof ActionType.CREATE_TASK]
  >
): string {
  const baseUrl = `https://${options.cloudId}.atlassian.net/secure/CreateIssueDetails!init.jspa`
  const queryParams = new URLSearchParams({
    pid: options.projectId,
    issuetype: options.issueTypeId,
    summary: options.summary || '',
    description: options.description || '',
    ...(options.additionalProperties ?? {}),
  })

  return `${baseUrl}?${queryParams.toString()}`
}
