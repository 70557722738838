import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons'
import {
  FontAwesomeIcon,
  type FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome'

export const SuggestedForYouIcon = ({
  isIncludingText,
  ...props
}: Partial<FontAwesomeIconProps> & {
  isIncludingText?: boolean
}) => (
  <>
    <FontAwesomeIcon
      fixedWidth
      icon={faWandMagicSparkles}
      size='xs'
      {...props}
    />
    {isIncludingText ? ' Suggested for you' : ''}
  </>
)
