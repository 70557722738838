import { SCORE_THEME } from '@/shared/constants/score'
import { faCalendar as faCalendarRegular } from '@fortawesome/free-regular-svg-icons'
import { faCalendar as faCalendarSolid } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type Score } from '@prisma/client'

export const UrgencyIcon = ({ urgency }: { urgency: Score | null }) => (
  <FontAwesomeIcon
    icon={urgency ? faCalendarSolid : faCalendarRegular}
    className={
      urgency ? `text-${SCORE_THEME[urgency]}` : 'text-muted-foreground'
    }
    fixedWidth
  />
)
